<template>

<div class="is-size-7 has-text-left" style="line-height:1">
    <template v-for="(duty,kduty) in lastday">
        <template v-if="duty.hdep && duty.hdes && $luxon.fromSQL(duty.hdep).toFormat('y-LL-dd')!=$luxon.fromSQL(duty.hdes).toFormat('y-LL-dd')">
        <div v-if="duty.type=='L'" :key="kduty+'last'"  @click="showFltInfo({al:duty.al,fnum:duty.fnum,dep:duty.dep,des:duty.des,hdep:duty.hdep})">
            ......-<xx_horaire :heure="duty.hdes" /><span class="pl-1 pr-1 has-text-info has-text-weight-bold">{{duty.dep}}-{{duty.des}}</span>
        </div>
        <div v-else-if="duty.type=='F'" :key="kduty+'last'"  @click="showFltInfo({al:duty.al,fnum:duty.fnum,dep:duty.dep,des:duty.des,hdep:duty.hdep})">
            ......-<xx_horaire :heure="duty.hdes" /><span class="pl-1 is-size-8 has-text-weight-bold has-text-grey">DH</span><span class="pr-1 has-text-info">{{duty.dep}}-{{duty.des}}</span>
        </div>
        <div v-else-if="duty.type=='G'" :key="kduty+'last'">
            ......-<xx_horaire :heure="duty.hdes" /><span class="pl-1 is-size-8 has-text-weight-bold has-text-grey">DH</span><span class="pr-1 has-text-success">{{duty.dep}}-{{duty.des}}</span>
        </div>
        <div v-else-if="duty.type=='S'" :key="kduty+'last'">
            <div>
            ......-<xx_horaire :heure="duty.hdes" /><span class="px-1 is-size-8 has-text-weight-bold has-text-success">SIMU</span><span class="pr-1 has-text-success">{{duty.label}}</span>
            </div>
            <div class="has-text-right"><span class="pl-1 is-size-8 has-text-weight-bold has-text-success">{{duty.ac}}</span><span class="pl-1 is-size-8 has-text-weight-bold has-text-grey">{{duty.dep}}</span></div>
        </div>
        <div v-else-if="$luxon.fromSQL(duty.hdep).toFormat('HHmm')!='00' && $luxon.fromSQL(duty.hdes).toFormat('HHmm')!='2359' && $luxon.fromSQL(duty.hdes).toFormat('HHmm')!='0000'" :key="kduty+'last'">
            <div>
            ......-<xx_horaire :heure="duty.hdes" /><span class="pl-1 pr-1 has-text-success has-text-weight-bold">{{duty.label}}</span>
            </div>
            <div class="has-text-right"><span class="pl-1 is-size-8 has-text-weight-bold has-text-grey">{{duty.dep}}</span></div>
        </div>
        <div v-else :key="kduty+'last'">
            <div>
            <span class="pl-1 pr-1 has-text-success has-text-centered is-size-6 pt-2 has-text-grey-light">{{duty.label}}</span>
            </div>
            <div class="has-text-right"><span class="pl-1 is-size-8 has-text-weight-bold has-text-grey">{{duty.dep}}</span></div>
        </div>
        </template>
    </template>

    <template v-for="(duty,kduty) in day">
        <div v-if="duty.type=='L'" :key="kduty" @click="showFltInfo({al:duty.al,fnum:duty.fnum,dep:duty.dep,des:duty.des,hdep:duty.hdep})">
            <xx_horaire :heure="duty.hdep" />-<xx_horaire :heure="duty.hdes" /><span class="pl-1 pr-1 has-text-info has-text-weight-bold">{{duty.dep}}-{{duty.des}}</span>
        </div>
        <div v-else-if="duty.type=='F'" :key="kduty"  @click="showDutyInfo({al:duty.al,fnum:duty.fnum,dep:duty.dep,des:duty.des,hdep:duty.hdep})">
            <xx_horaire :heure="duty.hdep" />-<xx_horaire :heure="duty.hdes" /><span class="pl-1 is-size-8 has-text-weight-bold has-text-grey">DH</span><span class="pr-1 has-text-info">{{duty.dep}}-{{duty.des}}</span>
        </div>
        <div v-else-if="duty.type=='G'" :key="kduty" @click="showDutyInfo(duty)">
            <xx_horaire :heure="duty.hdep" />-<xx_horaire :heure="duty.hdes" /><span class="pl-1 is-size-8 has-text-weight-bold has-text-grey">DH</span><span class="pr-1 has-text-success">{{duty.dep}}-{{duty.des}}</span>
        </div>
        <div v-else-if="duty.type=='S'" :key="kduty" @click="showDutyInfo(duty)">
            <div>
            <xx_horaire :heure="duty.hdep" />-<xx_horaire :heure="duty.hdes" /><span class="px-1 is-size-8 has-text-weight-bold has-text-success">SIMU</span><span class="pr-1 has-text-success">{{duty.label}}</span>
            </div>
            <div class="has-text-right"><span class="pl-1 is-size-8 has-text-weight-bold has-text-success">{{duty.ac}}</span><span class="pl-1 is-size-8 has-text-weight-bold has-text-grey">{{duty.dep}}</span></div>
        </div>
        <div v-else-if="$luxon.fromSQL(duty.hdep).toFormat('HHmm')!='00' && $luxon.fromSQL(duty.hdes).toFormat('HHmm')!='2359' && $luxon.fromSQL(duty.hdes).toFormat('HHmm')!='0000'" :key="kduty"  @click="showDutyInfo(duty)">
            <div>
            <xx_horaire :heure="duty.hdep" />-<xx_horaire :heure="duty.hdes" /><span class="pl-1 pr-1 has-text-success has-text-weight-bold">{{duty.label}}</span>
            </div>
            <div class="has-text-right"><span class="pl-1 is-size-8 has-text-weight-bold has-text-grey">{{duty.dep}}</span></div>
        </div>
        <div v-else :key="kduty"  @click="showDutyInfo(duty)">
            <div>
            <span class="pl-1 pr-1 has-text-success has-text-centered is-size-6 pt-2 has-text-grey-light">{{duty.label}}</span>
            </div>
            <div class="has-text-right"><span class="pl-1 is-size-8 has-text-weight-bold has-text-grey">{{duty.dep}}</span></div>
        </div>

    </template>
</div>

</template>
<script>
export default {
  data() {
    return {

    }
  },
props:{
    day:{default:[]},
    lastday:{dafault:[]}
    }
}
</script>