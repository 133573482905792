<template>
  <div>
    <xx_loading></xx_loading>

<template v-if="loaded">
<template v-if="!$store.token">
  <xx_login></xx_login>
</template>

<template v-else>

    <xx_header></xx_header>
    <div class="pt-2">
      <transition name="moveInUp">
        <router-view></router-view>
      </transition>
    </div>

    <xx_fltinfo v-if="$helper.modals.fltinfo.show" :flt="$helper.modals.fltinfo.flt"></xx_fltinfo>
    <xx_dutyinfo v-if="$helper.modals.dutyinfo.show" :duty="$helper.modals.dutyinfo.duty" :class="{'is-hidden':$helper.modals.dutyinfo.show && $helper.modals.fltinfo.show}"></xx_dutyinfo>
</template>
</template>    
  </div>
</template>
<script>


export default {
  created() {
    let me = this;
    setInterval(function () {me.$helper.now = me.$luxon.utc();}, 10000);
  },
  mounted() {
    let me=this
    me.$misc.init_eventListener()
    me.$misc.loadStore()


    
  },
  watch: {
    loaded(xnew,xold){
      let me=this
      if (xnew) {
        me.$apis.load_abilities();me.$helper.mytimer["abilities"]=setInterval(function () {if (me.loaded && me.$store.token) {me.$apis.load_abilities()};}, 15*60000);
        me.$apis.load_ops_data({},me.opsLoaded);me.$helper.mytimer["ops"]=setInterval(function () {me.$apis.load_ops_data({},me.opsLoaded)}, 5*60000);
        me.$apis.load_crew_data({});me.$helper.mytimer["crew"]=setInterval(function () {me.$apis.load_crew_data({}) }, 15*60000);
        me.$apis.load_qualif_data({});me.$helper.mytimer["qualif"]=setInterval(function () {me.$apis.load_qualif_data({}) }, 60*60000);
      } else {
        clearInterval(me.$helper.mytimer["abilities"])
        clearInterval(me.$helper.mytimer["ops"])
        clearInterval(me.$helper.mytimer["crew"])
        clearInterval(me.$helper.mytimer["qualif"])

      }
    }
  },
  data() {
    return {
    };
  },
  computed: {
    loaded() {
      let me=this
      return me.$helper.loading.reduce(function(r,x){return (x.type=="init")?r+1:r },0) ==0
    }
  },
};
</script>

<style>

.moveInUp-enter-active {
  opacity: 0;
  transition: opacity 1s ease-in;
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
.moveInUp-leave-active {
  animation: moveInUp 0.3s ease-in;
}
@keyframes moveInUp {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-400px);
  }
}
</style>