import helper from './helper'
import store from './store'
import apis from './apis'
import _ from 'lodash'



import page_docpn from "./components/docpn/page_docpn.vue";
import page_paie from "./components/paie/page_paie.vue";
import page_activite from "./components/paie/page_activite.vue";
import page_Rosters from "./components/Rosters/page_Rosters.vue";
import page_LidoOfpList from "./components/LidoList/page_LidoOfpList.vue";
import page_CrewlinkMonth from "./components/CrewlinkMonth/page_CrewlinkMonth.vue";
import page_CrewlinkFlts from "./components/CrewlinkFlts/page_CrewlinkFlts.vue";
import page_OpsFlts from "./components/OpsFlts/page_OpsFlts.vue";
import page_home from "./components/Home/page_home.vue";

import {DateTime} from "luxon";
import misc from './misc';

export default [
    {
        path: '/',
        component: page_home,
        name: 'home'
    }, 
    {
        path: '/ofp',
        component: page_LidoOfpList,
        name: 'lidoOfp',
        beforeEnter: (to, from, next) => {
            if (misc.user_can('lido_flights')) next()
            else next({name:'home'})
        }

    }, 
    {
        path: '/crew',
        component: page_CrewlinkMonth,
        name: 'crewlinkRoster',
        beforeEnter: (to, from, next) => {
            if (misc.user_can('crewlist_crewlink')) next()
            else next({name:'home'})
        }
    }, 
    {
        path: '/flts',
        component: page_CrewlinkFlts,
        name: 'crewlinkFlts',
        props: route => ({datebegin: route.query.datebegin || DateTime.utc().toFormat("y-LL-dd"),dateend:DateTime.utc().toFormat("y-LL-dd")}),
        beforeEnter: (to, from, next) => {
            if (misc.user_can('roster_crewlink')) next()
            else next({name:'home'})
        }
    }, 
    {
        path: '/ops',
        component: page_OpsFlts,
        name: 'opsFlts',
        beforeEnter: (to, from, next) => {
            if (misc.user_can('info_ops')) next()
            else next({name:'home'})
        }
    }, 
    {
        path: '/rosters',
        component: page_Rosters,
        name: 'rosters',
        beforeEnter: (to, from, next) => {
            if (misc.user_can('rosters')) next()
            else next({name:'home'})
        }
    }, 
    {
        path: '/paie',
        component: page_paie,
        name: 'paie',
        beforeEnter: (to, from, next) => {
            if (misc.user_can('paie')) next()
            else next({name:'home'})
        }
    }, 
    {
        path: '/activite',
        component: page_activite,
        name: 'activite',
        beforeEnter: (to, from, next) => {
            if (misc.user_can('paie')) next()
            else next({name:'home'})
        }
    }, 
    {
        path: '/docpn',
        component: page_docpn,
        name: 'docpn',
        beforeEnter: (to, from, next) => {
            if (misc.user_can('docs')) next()
            else next({name:'home'})
        }
    },
    //otherwise redirect to /
    { path: "*", redirect: '/' }

    ]