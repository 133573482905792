<template>
  <div v-if="duty.type == 'flt'"  @click="showFltInfo(duty.data)">
    <div class="" style="margin: 0">
      <span class="mr-1">
        <span class="is-family-monospace has-text-weight-bold is-size-7">{{
          last ? "----" : $luxon.fromSQL(duty.data.hdep).toFormat('HHmm')
        }}</span>
        <span class="pl-0 ml-0 is-family-monospace is-size-7">{{
          $luxon.fromSQL(duty.data.hdes).toFormat('HHmm')
        }}</span>
      </span>
      <span class="mr-1">
        <span
          class="is-size-7 is-info px-1 has-text-weight-semibold has-text-info"
          >{{ duty.data.dep }}-{{ duty.data.des }}</span>
        <b-tooltip
          type="is-info"
          v-if="duty.data.label"
          :label="duty.data.label"
          position="is-left"
        >
          <span
            ><b-icon icon="information-outline" size="is-small"> </b-icon
          ></span>
        </b-tooltip>
      </span>
    </div>
  </div>

  <div v-else-if="duty.type == 'dh'"   @click="showFltInfo(duty.data)">
    <div class="" style="margin: 0">
      <span class="mr-1">
        <span class="is-family-monospace has-text-weight-bold is-size-7">{{
          last ? "----" : $luxon.fromSQL(duty.data.hdep).toFormat('HHmm')
        }}</span>
        <span class="pl-0 ml-0 is-family-monospace is-size-7">{{
          $luxon.fromSQL(duty.data.hdes).toFormat('HHmm')
        }}</span>
      </span>
      <span class="mr-1">
        <span class="has-text-success has-text-weight-light is-size-7">DH</span>
        <span
          class="is-size-7 is-info px-1 has-text-weight-semibold has-text-primary"
          >{{ duty.data.dep }}-{{ duty.data.des }}</span>
      </span>
      <b-tooltip
        type="is-info"
        v-if="duty.data.label"
        :label="duty.data.label"
        position="is-left"
      >
        <span
          ><b-icon icon="information-outline" size="is-small"> </b-icon
        ></span>
      </b-tooltip>
    </div>
  </div>

  <div v-else-if="duty.type == 'dhlimo'" @click="showDutyInfo({dep:duty.data.dep,des:duty.data.des,hdep:duty.data.hdep,hdes:duty.data.hdes})">
    <div class="" style="margin: 0">
      <span class="is-narrow mr-1">
        <span class="is-family-monospace has-text-weight-bold is-size-7">{{
          last ? "----" : $luxon.fromSQL(duty.data.hdep).toFormat('HHmm')
        }}</span>
        <span class="pl-0 ml-0 is-family-monospace is-size-7">{{
          $luxon.fromSQL(duty.data.hdes).toFormat('HHmm')
        }}</span>
      </span>
      <span class="mr-1">
        <span class="has-text-success has-text-weight-light is-size-7">limo</span>
        <span
          class="is-size-7 is-info px-1 has-text-weight-semibold has-text-primary"
          >{{ duty.data.dep }}-{{ duty.data.des }}</span>
        <b-tooltip
          type="is-info"
          v-if="duty.data.label"
          :label="duty.data.label"
          position="is-left"
        >
          <span
            ><b-icon icon="information-outline" size="is-small"> </b-icon
          ></span>
        </b-tooltip>
      </span>
    </div>
  </div>

  <div v-else-if="duty.type == 'sim'" @click="showDutyInfo({dep:duty.data.place,hdep:duty.data.start,hdes:duty.data.end,label:duty.data.label})">
    <div class="" style="margin: 0">
      <span class="mr-1">
        <span class="is-family-monospace has-text-weight-bold is-size-7">{{
          $luxon.fromSQL(duty.data.start).toFormat('HHmm')
        }}</span>
        <span class="is-family-monospace is-size-7">{{ $luxon.fromSQL(duty.data.end).toFormat('HHmm') }}</span>
      </span>
      <span class="mr-1">
        <span
          class="has-text-success is-light px-1 is-size-6 has-text-weight-semibold"
          >SIMU</span>
      </span>
      <b-tooltip
        type="is-info"
        v-if="duty.data.label"
        :label="duty.data.label"
        position="is-left"
      >
        <span
          ><b-icon icon="information-outline" size="is-small"> </b-icon
        ></span>
      </b-tooltip>
    </div>
    <div class="has-text-right mr-1">
      <span class="has-text-weight-light is-size-7 is-light px-1">{{
        duty.data.ac
      }}</span>
      <span class="px-1 is-size-7">{{ duty.data.place }}</span>
    </div>
  </div>


  <div v-else-if="(duty.type == 'wholeday') || ((duty.data) && (duty.data.label) && (duty.data.label=='CA'))">
    <div class="has-text-centered" style="margin: 0">
      <span class="mr-1">
        <div
        :class="{'has-text-grey-light':duty.data.label=='OFF','has-text-grey':duty.data.label!='OFF'}"
          class="is-size-4 px-1 has-text-weight-semibold is-family-secondary"
        >
          <span>{{ duty.data.label }}</span>

          <b-tooltip
            type="is-info"
            size="is-small"
            v-if="duty.data.desc"
            :label="duty.data.desc"
            position="is-left"
          >
            <span class="is-size-6"
              ><b-icon icon="information-outline" size="is-small"> </b-icon
            ></span>
          </b-tooltip>
        </div>
        <div class="has-text-grey-light is-size-6 px-1 has-text-weight-light">
          {{ duty.data.place }}
        </div>
      </span>
    </div>
  </div>

  <div v-else-if="duty.type == 'ground'" @click="showDutyInfo({dep:duty.data.place,hdep:duty.data.start,hdes:duty.data.end,label:duty.data.label,tlc:$store.crewlink.mle})">
    <div class="" style="margin: 0">
      <span class="mr-1">
        <span class="is-family-monospace has-text-weight-bold is-size-7">{{
          $luxon.fromSQL(duty.data.start).toFormat('HHmm')
        }}</span>
        <span class="is-family-monospace is-size-7">{{ $luxon.fromSQL(duty.data.end).toFormat('HHmm') }}</span>
      </span>
      <span class="mr-1">
        <span
          class="has-text-success is-light px-1 is-size-6 has-text-weight-semibold"
          >{{ duty.data.label }}</span>
      </span>
      <b-tooltip
        type="is-info"
        v-if="duty.data.desc"
        :label="duty.data.desc"
        position="is-left"
      >
        <span
          ><b-icon icon="information-outline" size="is-small"> </b-icon
        ></span>
      </b-tooltip>
    </div>
    <div class="has-text-right is-narrow mr-1">
      <span class="px-1 is-size-7">{{ duty.data.place }}</span>
    </div>
  </div>

  <div class="" v-else-if="duty.type == 'notification'" style="margin: 0">
    <span class="mr-1 pb-2 mx-3 buttons">
      <b-button
        expanded
        type="is-danger"
        size="medium"
        icon-right="file-pdf"
        tag="a"
        :href="'api/crew/roster/changed?'+'&token='+encodeURI($store.token)"
        target="_blank"
        @click="showNotif"
      >
        CHANGED
      </b-button>
      <b-button
        v-if="confirmVisible"
        expanded
        size="small"
        icon-right="comment-question-outline"
        @click="confirmChanges()"
        >Confirm roster change?</b-button
      >
      <b-loading :is-full-page="true" :can-cancel="false" :active="wip" />
    </span>
  </div>

  <div class="" v-else style="margin: 0">
    <span class="has-text-centred">
      <div class="is-size-7 trucate">
        <small>{{ duty.UNKNOWN }}</small>
      </div>
    </span>
  </div>
</template>
<script>

export default {
  data: () => ({
    wip: false,
    confirmVisible: false,
  }),
  props: {
    duty: { default: {} },
    last: { default: false },
  },
  methods: {
    showNotif() {
      let me = this;
      me.confirmVisible = true;
      setTimeout(() => {
        me.confirmVisible = false;
      }, 120000);
    },
    confirmChanges() {
      let me = this;
      this.$buefy.dialog.confirm({
        title: "Roster has changed",
        message:
          "Are you sure you want to <b>CONFIRM</b> the notification? This action cannot be undone.",
        confirmText: "Approve and confirm",
        type: "is-danger",
        hasIcon: true,
        onCancel() {
          me.confirmVisible = false;
        },
        onConfirm() {
          //me.wip = true;
          me.$apis.roster_confirm({},function(resp){
              me.confirmVisible = false;
              me.$buefy.toast.open({
                message: "Roster changes were approved!",
                position: "is-bottom",
                type: "is-success",
              });
              
          })
          /*
          me.$axios
            .post("/api/crew/roster/changed/confirm")
            .then(function (response) {
              me.confirmVisible = false;
              me.$buefy.toast.open({
                message: "Roster changes was approved!",
                position: "is-bottom",
                type: "is-success",
              });
            })
            .catch(function (error) {
              me.$buefy.toast.open({
                message: "An error has occured!",
                position: "is-bottom",
                type: "is-danger",
              });
            })
            .then(function () {
              me.wip = false;
            });
            */
        },
      });
    },
  },
};
</script>
<style scoped>

</style>