<template>
  <div class="column pl-0 pr-1 pt-0 box pb-1">
    <template v-if="lastday">
      <div
        class="pl-2 pb-1 pr-1 pt-1"
        v-for="(lduty, klduty) in lastday.all"
        :key="'last' + klduty"
      >
        <xx_duty
          :duty="lduty"
          :last="true"
          v-if="
            lduty &&
            lduty.data &&
            lduty.data.hdep &&
            $luxon.fromSQL(lduty.data.hdep).day != $luxon.fromSQL(lduty.data.hdes).day
          "
        ></xx_duty>
      </div>
    </template>
    <template v-if="day">
      <div
        class="pl-2 pb-1 pr-1 pt-1"
        v-for="(duty, kduty) in day.all"
        :key="kduty"
      >
        <xx_duty :duty="duty"></xx_duty>
      </div>
    </template>
  </div>
</template>

<script>

export default {
  props: {
    day: { default: null },
    lastday: { default: null },
  },

};
</script>

<style>
</style>