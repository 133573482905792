<template>
  <div>

    <template v-if="actDay || _.size(crewflts)>0">
      <nav class="level is-mobile container">
        <div class="level-item has-text-centered">
          <b-switch v-model="options.cockpit" type="is-success" size="is-small">
            Cockpit
          </b-switch>
          <b-switch v-model="options.cabin" type="is-success" size="is-small">
            Cabin
          </b-switch>
          <b-switch v-model="options.dh" type="is-success" size="is-small">
            DH
          </b-switch>
        </div>
      </nav>
      <nav class="level is-mobile container" style="max-width: 380px" v-sticky="{ zIndex: 1, stickyTop: 60, disabled: false }">
        <div class="level-item has-text-centered" v-for="(day, kday) in [-2, -1, 0, 1, 2]" :key="kday">
          <div @click="loadDay($luxon.fromSQL(actDay).plus({ day: day }).toFormat('y-LL-dd'))">
            <xx_calendarIcon
              :color="
                now.toFormat('y-LL-dd') ==
                $luxon.fromSQL(actDay).plus({ day: day }).toFormat('y-LL-dd')
                  ? 'has-background-info-dark'
                  : _.has(
                      fltsByDay,
                      $luxon
                        .fromSQL(actDay)
                        .plus({ day: day })
                        .toFormat('y-LL-dd')
                    )
                  ? 'has-background-info'
                  : 'has-background-grey-light'
              "
              :class="{
                flou: day != 0,
                'animated infinite pulse':
                  now.toFormat('y-LL-dd') ==
                  $luxon.fromSQL(actDay).plus({ day: day }).toFormat('y-LL-dd'),
              }"
              :date="
                $luxon.fromSQL(actDay).plus({ day: day }).toFormat('y-LL-dd')
              "
              :full="day == 0"/>
          </div>
        </div>
        <div class="has-text-info pr-1">
          <diV @click="pickDay = true">
            <b-icon icon="calendar-month"></b-icon>
          </diV>
          <div @click="$apis.load_crew_flts({ datebegin: actDay, dateend: actDay },fltsLoaded)">
            <b-icon icon="reload"></b-icon>
          </div>
        </div>
      </nav>

      <div class="table-container">
        <table
          class="table is-size-7 is-size-6-tablet is-size-5-desktop is-narrow is-striped"
          style="margin-left: auto; margin-right: auto"
          v-if="fltsByDay[actDay]"
        >
          <tr class="sticktop">
            <th>Flight</th>
            <th v-if="options.cockpit">Cockpit</th>
            <th v-if="options.cabin">Cabin</th>
            <th v-if="options.dh">DH</th>
          </tr>
          <tr v-for="(flt, kflt) in fltsByDay[actDay]" :key="kflt">
            <td class="nowrap" @click="showFltInfo(flt)">
              <div class="is-family-monospace">{{ flt.al }}{{ flt.fnum }}</div>
              <div class="has-text-weight-semibold has-text-info">
                {{ flt.dep }}-{{ flt.des }}
              </div>

              <div>
                <span class="has-text-weight-semibold mr-2">{{flt.hdep | luxon({ clientFormat: "HHmm", serverFormat: "sql" })}}</span
                ><span>{{flt.hdes | luxon({ clientFormat: "HHmm", serverFormat: "sql" })}}</span>
              </div>
              <div v-for="(ops,kops) in _.uniqBy(acleg(flt),'AC_REGISTRATION')" :key="kops">
                <span class="has-text-weight-semibold has-text-success-dark pl-2 is-family-monospace">[{{actype(ops.AC_SUBTYPE)}}]</span>
                <span class="has-text-weight-light has-text-success pl-1 is-family-monospace" style="font-size:0.6rem;">{{ops.AC_REGISTRATION}}</span>
               </div>
            </td>
            <td class="nowrap" v-if="options.cockpit">
              <div v-for="(name, kname) in flt.pnt" :key="kname">
                <b-tooltip :label="name" type="is-dark" position="is-bottom">
                  <div class="nowrap truncate has-text-weight-medium">
                    {{ _.capitalize(name) }}
                  </div>
                </b-tooltip>
              </div>
            </td>

            <td class="nowrap" v-if="options.cabin">
              <div v-for="(name, kname) in flt.pnc" :key="kname">
                <b-tooltip :label="name" type="is-dark" position="is-bottom">
                  <div class="nowrap truncate has-text-weight-normal">
                    {{ _.capitalize(name) }}
                  </div>
                </b-tooltip>
              </div>
            </td>
            <td class="nowrap" v-if="options.dh">
              <div v-for="(name, kname) in flt.dh" :key="kname">
                <b-tooltip :label="name" type="is-dark" position="is-bottom">
                  <div class="nowrap truncate has-text-weight-light">
                    {{ _.capitalize(name) }}
                  </div>
                </b-tooltip>
              </div>
            </td>
          </tr>
        </table>
      </div>
    </template>

    <section class="hero is-medium is-primary is-bold" v-else>
      <div class="hero-body">
        <div class="container">
          <h1 class="title">Crewlink</h1>
          <h2 class="subtitle">
            No Flights found...<b-button
              type="is-warning"
              @click="$apis.load_crew_flts({},fltsLoaded)"
              >Reload</b-button
            >
          </h2>
        </div>
      </div>
    </section>


    


    <b-modal v-model="pickDay">
      <form action="" class="container has-text-centered">
        <b-field label="Select a date">
          <b-datepicker inline trap-focus @input="(e) => {
                pickDay = false;
                loadDay($luxon.fromJSDate(e).toFormat('y-LL-dd'));
              }">
          </b-datepicker>
        </b-field>
      </form>
    </b-modal>
  </div>
</template>


<script>


export default {
  
  created() {
    //this.flts.params = { datebegin: this.datebegin, dateend: this.dateend };
        
    this.loadDay(this.datebegin);
  },
  data() {
    return {
      flts: {
        params: {},
        update: false,
        resp: null,
      },
      options: {
        cockpit: true,
        cabin: true,
        dh: true,
      },
      pickDay: false,
    };
  },
  methods: {

    loadDay(date) {
      let me = this;
      if (_.find(me.crewflts, ["date", date])) {
        me.$store.flts.actDay = date;
      } else {
        if (me.$helper.online) {
          me.$apis.load_crew_flts({ datebegin: date, dateend: date },me.fltsLoaded)
          
        } else {
          this.$buefy.snackbar.open(
            `You're OffLine. Please try later, when you'll be OnLine.`
          );
        }
      }
    },
    fltsLoaded(resp) {
      let me = this;

        //remove same date
        me.$store.flts.legs = _.reject(me.$store.flts.legs, (leg) =>
          /*me.$luxon
            .fromSQL(resp.data.dates.datebegin)
            .startOf("day")
            .until(me.$luxon.fromSQL(resp.data.dates.dateend).endOf("day"))
            .contains(me.$luxon.fromSQL(leg.hdep))
            */
          me.$luxon
            .fromSQL(resp.dates.datebegin)
            .startOf("day")
            .until(me.$luxon.fromSQL(resp.dates.dateend).endOf("day"))
            .contains(me.$luxon.fromSQL(leg.date))
        );
        //add new ones
        me.$store.flts.legs = _.orderBy(
          _.union(
            me.$store.flts.legs,
            _.map(resp.flts, (leg) =>
              _.assign(leg, { date: resp.dates.datebegin })
            )
          ),
          "hdep"
        );
        //update time
        _.set(
          me.$store.flts.update,
          resp.dates.datebegin,
          me.now.toFormat("y-LL-dd")
        );

        me.$store.flts.actDay = resp.dates.datebegin;
      
    },
  },
  computed: {
    actDay(){
      return this.$store.flts.actDay;
    },
    now() {
      return this.$helper.now;
    },
    crewflts() {
      return this.$store.flts.legs;
    },
    fltsByDay() {
      let me = this;
      return _.groupBy(me.crewflts, (leg) =>
        me.$luxon.fromSQL(leg.hdep).toFormat("y-LL-dd")
      );
    },
  },
  props: {
    datebegin: {},
    dateend: {},
  },
//  components: { xx_calendarIcon },
};
</script>
