<template>

    

      <section v-if="list && list.flts">
        
        <h3 class="subtitle" v-if="list && list.flts && _.size(list.flts)>1">found {{_.size(list.flts)}} Flight(s)</h3>
        <b-collapse class="card" animation="slide" v-for="(flt, index) of list.flts" :key="index" :open="list.isOpen == index" @open="list.isOpen = index">
          <div slot="trigger" slot-scope="props" class="card-header" role="button">
            <div class="columns mt-0 mb-0 pt-0 pb-0 pl-3 pr-0 is-mobile card-header-title">
              <div class="column pl-3">
                
              </div>

              <div class="column is-narrow has-text-right is-family-monospace">
                  <span class="has-text-grey px-1 is-size-7">{{ flt.flt.date }}</span>
                  <span class="px-1 is-dark has-text-weight-semibold">{{ flt.flt.hdep }}-{{ flt.flt.hdes }}</span>
              </div>
            </div>
            <a class="card-header-icon" v-if="list && list.flts && _.size(list.flts)>1">
              <b-icon :icon="props.open ? 'menu-down' : 'menu-up'"> </b-icon>
            </a>
          </div>
          <div class="card-content p-0 m-0">
            <div class="content">
              <b-table :mobile-cards="false" :data="flt.crew" narrowed class="px-0 mx-0" :striped="true" :bordered="false" :scrollable="false">
                <b-table-column label="Rank" v-slot="props"><b-tag type="is-light" class="has-text-weight-bold" size="is-small">{{props.row.Rank}}</b-tag></b-table-column>
                <b-table-column label="Name, First Name" v-slot="props">{{_.startCase(_.toLower(props.row["Last Name, First Name"]))}}</b-table-column>
                <b-table-column label="Crew Code" v-slot="props" cell-class="has-text-right" style="vertical-align: middle;"><span class="has-text-grey is-size-7 is-family-monospace">{{props.row["Crew Code"]}}</span></b-table-column>
                <template slot="footer">
                  <div class="columns pt-2 pb-0 mt-0 mb-0 has-text-right is-mobile" >
                    <span class="column"></span>
                    <span v-for="(college, kcollege) in ['pnt', 'pnc', 'dh']" :key="kcollege" class="column is-narrow">
                      <span class="is-size-4 has-text-weight-semibold has-text-grey-dark">{{flt.pn[college]}}</span>
                      <span class="is-size-6 is-family-monospace is-uppercase has-text-grey">{{ college }}</span>
                      </span>
                  </div>
                </template>
              </b-table>
            </div>
          </div>

        </b-collapse>

      </section>


   
</template>

<script>
export default {
  created() {
    let me = this;
    
  },
  mounted(){
    let me=this
    
    me.$apis.load_qualif_data()
    
    me.$apis.load_crew_data()

    me.look={
      datebegin: me.flt.date || me.$luxon.fromSQL(me.flt.hdep).toFormat("y-LL-dd"),
      dateend: me.flt.date || me.$luxon.fromSQL(me.flt.hdes).toFormat("y-LL-dd"),
      al: me.flt.al,
      fnum: me.flt.fnum,
      dep: me.flt.dep,
      des: me.flt.des,
    }
    
  },
  data() {
    return {
        look:{},
        /*
      list: {
        isOpen: 0,
        flts: [],
      },
      */
    };
  },
  computed:{
      list(){
        let me=this
          let out={isOpen:0,flts:[]}
            out.flts=me.$store.crew.dataraw.filter(function (leg){
                let res=(me.$luxon.fromSQL(leg.hdep).toFormat("y-LL-dd")==me.look.datebegin)
                        && (leg.al==me.look.al)
                        && (leg.fnum==me.look.fnum)
                        && (leg.dep==me.look.dep)
                        && (leg.des==me.look.des)
                return res
            })
            out.flts=Object.values(_.groupBy(out.flts,(leg)=>(leg.al+leg.fnum+leg.dep+leg.des+me.$luxon.fromSQL(leg.hdep).toFormat("y-LL-dd HHmm"))))
              .map(function (legs) {
               return {
                flt:{
                  "al": legs[0].al,
                  "fnum": legs[0].fnum,
                  "date": me.$luxon.fromSQL(legs[0].hdep).toFormat("y-LL-dd"),
                  "dep": legs[0].dep,
                  "hdep": me.$luxon.fromSQL(legs[0].hdep).toFormat("HHmm"),
                  "hdes": me.$luxon.fromSQL(legs[0].hdep).toFormat("HHmm"),
                  "des": legs[0].des
                },
                crew: legs.map((pn)=>
                  ({
                    "Crew Code": pn.tlc,
                    "Rank": ((pn.type || '*') =='F')?'DH':(me.$store.qualif.data[pn.tlc].qualif.filter((q)=>(q.ac==me.actype(pn.actype)))[0] || {college:'JU'}).college,
                    "Seniority": pn.tlc,
                    "Homebase": "---",
                    "Contract": "",
                    "Last Name, First Name": _.capitalize(me.$store.qualif.data[pn.tlc].lname)+", "+_.capitalize(me.$store.qualif.data[pn.tlc].fname),
                  })).sort(function(a,b){let ord={'CP':1,'FO':2,'PU':3,'SE':4,'ST':5,'JU':6,'DH':7}; return (ord[a.Rank] || 99)-(ord[b.Rank] || 99);})
                ,
                pn:{
                  pnt:0,
                  pnc:0,
                  dh:0
                }

              } })
            out.flts=out.flts.map((leg)=>(Object.assign(leg,{pn:{
              pnt:leg.crew.filter((x)=>['CP','FO'].includes(x.Rank)).length,
              pnc:leg.crew.filter((x)=>['PU','SE','ST'].includes(x.Rank)).length,
              dh:leg.crew.filter((x)=>['DH'].includes(x.Rank)).length
              }})))


          return out
      },
  },
  props: {
    flt: {},
  },

  methods: {

  },
};
</script>