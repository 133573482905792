<template>
  <div>

      <nav
        class="level is-mobile container"
        style="max-width: 380px"
        v-sticky="{ zIndex: 1, stickyTop: 60, disabled: false }"
      >
        <div
          class="level-item has-text-centered"
          v-for="(day, kday) in [-1, 0, 1]"
          :key="kday"
        >
          <div @click="$apis.load_ofp_list({ day: now.plus({ day: day }).day },ofpsLoaded)">
            <xx_calendarIcon
              :color="
                ( $luxon.fromSQL(loadedDate).day || now.day) ==
                now.plus({ day: day }).day
                  ? 'has-background-info-dark'
                  : 'has-background-grey-light'
              "
              :date="now.plus({ day: day }).toFormat('y-LL-dd')"
              :full="true"
            />
          </div>
        </div>
        <div class="has-text-info pr-1">
          <div @click="$apis.load_ofp_list({},ofpsLoaded)">
            <b-icon icon="reload"></b-icon>
          </div>
        </div>
      </nav>

      <template v-if="ofps.length>0">
        <div class="columns is-multiline is-tablet" style="margin: 0">
          <div class="column" v-for="(flt, num) in ofps" :key="num">
            <div class="card" style="width:300px">
              <div class="card-content">
                <div class="level is-mobile">
                  <div class="tags has-addons level-left mb-0 mt-2">
                    <span class="tag has-text-weight-semibold">{{flt.ddep}}</span>
                    <span class="tag is-primary">{{ flt.hdep }}</span>
                  </div>
                  <div class="tags has-addons level-right mt-0">
                    <span class="tag is-dark">{{ flt.al + flt.fnum }}</span>
                    <span class="tag is-info">{{ flt.dep }}-{{ flt.des }}</span>
                  </div>
                </div>

                <div class="content">
                  <nav class="level is-mobile">
                    <div class="level-item has-text-centered">
                      <div>
                        <p class="heading">Regist.</p>
                        <p class="title">{{ flt.regist }}</p>
                      </div>
                    </div>
                    <div class="level-item has-text-centered">
                      <div>
                        <p class="heading">Captain: {{ flt.captain }}</p>
                        <p class="heading">at {{ flt.final }}</p>
                      </div>
                    </div>
                  </nav>
                </div>
              </div>
              <footer class="card-footer">
                <a href="#" @click="showFltInfo({al:flt.al,fnum:flt.fnum,dep:flt.dep,des:flt.des,hdep:flt.hdep})" class="card-footer-item">
                  <b-icon icon="information-outline" /> info</a>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  :href="
                    'api/ofp/file?al=' +
                    flt.al +
                    '&fnum=' +
                    flt.fnum +
                    '&day=' +
                    flt.origin +
                    /*'&date=' +$luxon.fromSQL(flt.ddep).toFormat('y-LL-dd') +*/
                    '&dep=' +
                    flt.dep +
                    '&des=' +
                    flt.des +
                    '&token=' +
                    encodeURI($store.token)
                  "
                  class="card-footer-item"
                  ><b-icon icon="file-pdf" /> PDF</a
                >
              </footer>
            </div>
          </div>
        </div>
      </template>

      <section class="hero is-medium is-primary is-bold" v-else>
        <div class="hero-body">
          <div class="container">
            <h1 class="title">Lido Flight Planning</h1>
            <h2 class="subtitle">
              No OFP found...<b-button
                type="is-warning"
                @click="$apis.load_ofp_list({},ofpsLoaded)"
                >Reload</b-button>
            </h2>
          </div>
        </div>
      </section>
      
  </div>
</template>

<script>


export default {

  mounted() {
    let me=this
    me.$apis.load_ofp_list({},me.ofpsLoaded)
  },
  data() {
    return {
        loadedDate:null,
        ofps: [],
    };
  },
  methods: {
    ofpsLoaded(resp){
      let me=this
      me.ofps=resp.flts
      me.loadedDate=resp.date
    }
  },
  computed: {
    now() {
      return this.$helper.now;
    },

  },

};
</script>