<template>
<div  class="container is-max-desktop ">

	<div id="clouds" style="position:fixed;top:0; width:100vw;height:100vh">
	<div class="cloud x1"></div>
	<div class="cloud x2"></div>
	<div class="cloud x3"></div>
	<div class="cloud x4"></div>
	<div class="cloud x5"></div>
	</div>

	<div class="columns is-centered is-vcentered is-mobile m-0 p-2" style="height:90vh">
		<div class="column " style="width:80vw;max-width:500px;">
<div style="z-index:10;position:relative">
	<p class="effecttext is-1 has-text-centered has-text-weight-bold" @click="mode=(mode+1) % modes.length">{{modes[mode]}}</p>
	<p class="subtitle is-3 has-text-right has-text-grey has-text-weight-bold">v4</p>
</div>

		<template v-if="mode==0">

			<div class="block p-2 mt-6" v-if="tar_crewlink">
				<b-field grouped class="ml-0">
					<b-field label="CrewLink Username" label-position="on-border" style="width:50%">
						<b-input v-model="crewlink.user"></b-input>
					</b-field>
					<b-field label="Crewlink Password" label-position="on-border"  style="width:50%">
						<b-input v-model="crewlink.pass" type="password" password-reveal ></b-input>
					</b-field>
				</b-field>
			</div>

			<div class="block pl-1 pr-2" v-if="true || tar_paie">
				<b-field grouped>
					<b-field label="Paie Username" label-position="on-border" style="width:50%">
						<b-input v-model="paie.user"></b-input>
					</b-field>
					<b-field label="Paie Password" label-position="on-border"  style="width:50%">
						<b-input v-model="paie.pass" type="password" password-reveal ></b-input>
					</b-field>
				</b-field>
			</div>

			<div class="block pl-1 pr-2" v-if="true || tar_docpn">
				<b-field grouped>
					<b-field label="ACD Username" label-position="on-border" style="width:50%">
						<b-input v-model="doc.user"></b-input>
					</b-field>
					<b-field label="ACD Password" label-position="on-border"  style="width:50%">
						<b-input v-model="doc.pass" type="password" password-reveal ></b-input>
					</b-field>
				</b-field>
			</div>

		</template>

		<template v-else-if="mode==1">

			<div class="block p-2 mt-6">
				<b-field grouped class="ml-0">
					<b-field label="Mail" label-position="on-border" style="width:50%">
						<b-input v-model="alt.user"></b-input>
					</b-field>
					<b-field label="Pass" label-position="on-border"  style="width:50%">
						<b-input v-model="alt.pass" type="password" password-reveal ></b-input>
					</b-field>
				</b-field>
			</div>

		</template>

		<div class="buttons">
            <b-button type="is-info" expanded @click="login()">Login</b-button>
        </div>
        
        </div>
    </div>

</div>
</template>
<script>
	export default {
		data(){
			return {
				mode:0,
				modes:['TAR Pilot','TAR Ops'],
				crewlink:{
					user:null,
					pass:null,
				},
				paie:{
					user:null,
					pass:null,
				},
				doc:{
					user:null,
					pass:null,
				},
				alt:{
					user:null,
					pass:null,
				},
			}
		},
		methods: {
			login(){
				let me=this
				me.$apis.login(
					{
						cred:
						{
							crewlink_user:me.crewlink.user,
							crewlink_pass:me.crewlink.pass,
							paie_user:me.paie.user,
							paie_pass:me.paie.pass,
							doc_user:me.doc.user,
							doc_pass:me.doc.pass,
							alt_user:me.alt.user,
							alt_pass:me.alt.pass,
						},
						mode: me.modes[me.mode],
					},
					function(resp){
					}
				)
			},
		}
	}
</script>
<style scoped>
.effecttext {
    	color: #fff;
	text-shadow: 0px 1px 0px #999, 0px 2px 0px #888, 0px 3px 0px #777, 0px 4px 0px #666, 0px 5px 0px #555, 0px 6px 0px #444, 0px 7px 0px #333, 0px 8px 7px #001135;
	font: 60px 'ChunkFiveRegular';
}
</style>