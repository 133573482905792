<template>
  <b-modal
    v-model="$helper.modals.fltinfo.show"
    has-modal-card
    full-screen
    :can-cancel="false"
  >

      <div class="modal-card" style="width: auto">
        <header class="modal-card-head px-1">
          <div class="modal-card-title">
            <div class="columns is-mobile">
              <div class="column pl-2 pr-2">Flight info</div>
              <div class="column pr-1 is-narrow">
                <b-taglist attached>
                  <b-tag type="is-info is-light px-1">{{ flt.al }}{{ flt.fnum }}</b-tag>
                  <b-tag type="is-info px-1 has-text-weight-semibold">{{ flt.dep }}-{{ flt.des }}</b-tag>
                </b-taglist>
              </div>

              <div class="column pr-5 is-narrow has-text-right is-family-monospace is-narrow">
                <span class="has-text-grey px-1 is-size-7">{{$luxon.fromSQL(flt.date || flt.hdep).toFormat("ccc ddLLLyy")}}</span>
              </div>
            </div>
          </div>
          <div>
          <button type="button" class="delete"  style="position:absolute;right:5px; top: 5px" @click="$helper.modals.fltinfo.show=false" />
          </div>
        </header>
        <section class="modal-card-body px-2">

            
              <template v-if="$helper.modals.fltinfo.activeTab==0">
                <xx_CrewList :flt="flt"></xx_CrewList>
              </template>
            
              <template v-if="$helper.modals.fltinfo.activeTab==5">
                <xx_crewextract :flt="flt"></xx_crewextract>
              </template>
            
              <template v-if="$helper.modals.fltinfo.activeTab==1">
                <xx_OpsData :flt="flt"></xx_OpsData>
              </template>
                
              <template v-if="$helper.modals.fltinfo.activeTab==2">
                <xx_OfpData :flt="flt"></xx_OfpData>
              </template>
                
              <template v-if="$helper.modals.fltinfo.activeTab==3">
                  <xx_Weather :flt="flt"></xx_Weather>
              </template>
                
            
              <template v-if="$helper.modals.fltinfo.activeTab==4">
               
                <xx_FltInfoMisc :flt="flt"></xx_FltInfoMisc>
              </template>
               

        </section>
        <footer class="modal-card-foot px-1 py-1">
          <div class="buttons">
            <b-button  v-if="tar_crewlink && $misc.user_can('info_crew')" :class="{'is-dark':$helper.modals.fltinfo.activeTab==0,'is-outlined':$helper.modals.fltinfo.activeTab!=0}" size="is-small" @click="$helper.modals.fltinfo.activeTab=0" icon-left="account-group">CREW {{$helper.online?'':' *alt*'}}</b-button>
            <b-button  v-if="tar_extract && !tar_crewlink && $misc.user_can('info_crew')" :class="{'is-dark':$helper.modals.fltinfo.activeTab==5,'is-outlined':$helper.modals.fltinfo.activeTab!=5}" size="is-small" @click="$helper.modals.fltinfo.activeTab=5" icon-left="account-group">CREW Alt</b-button>
            <b-button  v-if="tar_extract && $misc.user_can('info_ops')" :class="{'is-dark':$helper.modals.fltinfo.activeTab==1,'is-outlined':$helper.modals.fltinfo.activeTab!=1}" size="is-small" @click="$helper.modals.fltinfo.activeTab=1" icon-left="clipboard-check">OPS</b-button>
            <b-button  v-if="tar_lido && $misc.user_can('info_ofp')" :class="{'is-dark':$helper.modals.fltinfo.activeTab==2,'is-outlined':$helper.modals.fltinfo.activeTab!=2}" size="is-small" @click="$helper.modals.fltinfo.activeTab=2" icon-left="fuel">OFP</b-button>
            <b-button :class="{'is-dark':$helper.modals.fltinfo.activeTab==3,'is-outlined':$helper.modals.fltinfo.activeTab!=3}" size="is-small" @click="$helper.modals.fltinfo.activeTab=3" icon-left="weather-partly-cloudy">Wx</b-button>
            <b-button :class="{'is-dark':$helper.modals.fltinfo.activeTab==4,'is-outlined':$helper.modals.fltinfo.activeTab!=4}" size="is-small" @click="$helper.modals.fltinfo.activeTab=4" icon-left="folder-information">Misc</b-button>
          </div>
        </footer>
      </div>

  </b-modal>
</template>

<script>
export default {
  created() {
    let me=this
    if (!me.flt.date) {
      me.$set(me.flt,"date",me.$luxon.fromSQL(me.flt.hdep).toFormat("y-LL-dd"))
    }
  },
  data() {
    return {
    };
  },
  props: { flt: {} },
  methods: {},
};
</script>
<style>
.modal-card-body .tab-content {
  padding: 2px;
}
</style>>