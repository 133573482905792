<template>

    <section>

      
    <b-table :height="$helper.viewport.height-250" class="is-size-7" :data="listreserve" is-stripped is-narrowed :mobile-cards="false" sticky-header>
            <b-table-column field="reserve" label="Type" sortable v-slot="props">
                    {{ props.row.reserve }}
            </b-table-column>
            <b-table-column label="Last Name" sortable v-slot="props">
                    {{ _.capitalize($store.qualif.data[props.row.tlc].lname) }}
            </b-table-column>
            <b-table-column label="First Name" sortable v-slot="props">
                    {{ _.capitalize($store.qualif.data[props.row.tlc].fname) }}
            </b-table-column>
            <b-table-column field="tlc" label="Mle" sortable v-slot="props">
                    <span class="is-size-8 is-family-monospace px-1 has-background-grey has-text-white">{{ props.row.tlc }}</span>
            </b-table-column>
            <b-table-column field="base" label="Base" sortable v-slot="props">
                    <span class="has-text-info-dark is-size-8">{{ $store.qualif.data[props.row.tlc].base }}</span>
            </b-table-column>
            <b-table-column field="qualif" label="qualif" sortable v-slot="props">
                    <b-tooltip :label="qualifarray(props.row.tlc).join()" size="is-small" position="is-left" multilined>
                        <b-icon icon="information-outline" size="is-small"></b-icon>
                    </b-tooltip>
            </b-table-column>
    </b-table>
    

        <b-field>
            <b-select placeholder="Small" size="is-small" expanded v-model="selecttypereserve">
                <option :value="reserve" v-for="(reserve,kreserve) in _.orderBy(touttypereserve,(x)=>(x.college+x.ac+x.base))" :key="kreserve">{{ reserve.college}} {{ reserve.ac || null}} / {{ reserve.base}}</option>
            </b-select>
        </b-field>






    </section>


    
    
</template>

<script>
export default {
  created() {
    let me = this;

    //console.log(me.typereserve(me.duty.tlc))
  },
  mounted(){
      let me=this
      me.$apis.load_qualif_data({},function(){me.selecttypereserve=me.typereserve(me.duty.tlc)[0] || null})
    
      me.$apis.load_crew_data({})
    
      //console.log(me.$store.crew.dataraw.filter((leg)=>((leg.label==me.duty.label) && (leg.hdep==me.duty.hdep))).map((leg)=>(me.$store.qualif.data[leg.tlc])))
  },
  data() {
    return {

      selecttypereserve:null,

    };
  },
  props: {
    duty: {},
  },
    computed:{
        dutyqualifarray(){
            let me=this
            return me.qualifarray(me.duty.tlc)
        },
        listreserve() {
            let me=this
            return me.$store.crew.dataraw.filter((leg)=>((me.$luxon.fromSQL(leg.hdep).toFormat('y-LL-dd')==me.duty.date) && me.isReserve(leg.label) && me.assurememereserve(leg.tlc,me.selecttypereserve)   )).map((leg)=>( {tlc: leg.tlc,reserve:leg.label} )).sort(me.reservesort)
        },
        touttypereserve() {
            let me=this
            return me.$store.crew.dataraw.filter((leg)=>((me.$luxon.fromSQL(leg.hdep).toFormat('y-LL-dd')==me.duty.date) && me.isReserve(leg.label)  )).reduce(function(r,leg){
                me.typereserve(leg.tlc).forEach(function(res){
                    if (!_.some(r,res)) { r=r.concat(res) }
                })
                return r
            },[]).sort(function(a,b){
                let va=a.base+a.college+(a.ac||"")
                let vb=b.base+b.college+(b.ac||"")
                return va<vb?-1:(va==vb?0:1)
            })
            }
        },
  methods: {
    typereserve(tlc){
        let me =this
        let out= me.$store.qualif.data[tlc].qualif.reduce(function(r,q){
            let out={}
            if (['CP','FO'].includes(q.college)) {
                out={base:me.$store.qualif.data[tlc].base,college:q.college,ac:q.ac}
            } else if (['SE','PU'].includes(q.college)) {
                out={base:me.$store.qualif.data[tlc].base,college:'SE'}
            } else if (['ST'].includes(q.college)) {
                out={base:me.$store.qualif.data[tlc].base,college:'ST'}
            }
            return _.some(r,out)?r:r.concat(out)
        },[])
        return out
    },

    assurememereserve(tlc,typereserve) {
        let me=this
        return _.isNull(typereserve) || _.some(me.typereserve(tlc),typereserve)
    },
    college(tlc){
              let me = this;
       let t={CP:1,FO:2,FE:3,PU:4,SE:5,ST:6,JU:7,XX:99}
       if (_.isNull(me.$store.qualif.data[tlc] || null) ) {return 99}
       let college= me.$store.qualif.data[tlc].qualif.reduce((r,q)=>(r=((t[q.college] || 99)<(t[r] || 99))?(q.college || 'XX'):r),'XX')
       return {college:college,val:t[college] || 99}
    },
    collegesort(tlca,tlcb){
              let me = this;
        return ((me.college(tlca.tlc).val-me.college(tlcb.tlc).val)<0)?-1:(((me.college(tlca.tlc).val-me.college(tlcb.tlc).val)==0)?(   me.comparenames(tlca.tlc,tlcb.tlc)     ):1)
    },
    comparenames(tlca,tlcb) {
        let me=this
        let qualifa=me.$store.qualif.data[tlca] 
        let qualifb=me.$store.qualif.data[tlcb] 
        return ((qualifa.lname)<(qualifb.lname))?-1:(((qualifa.lname)==(qualifb.lname))?0:1)
    },
    reservesort(a,b){
        let me = this;
        const res= (r)=>({R0:1,R1:2,R2:3,R3:4,R4:5,R5:6,R6:7,R7:8,R8:9,R9:10,RD:11,RS:12,RT:13}[r.reserve] || 99)
        return res(a)<res(b)?-1:(res(a)==res(b)?0:1)
    },
  },
};
</script>