import Vue from 'vue';

const state=Vue.observable({
  token: null,
  abilities:[],
  default_filters:{
    ops_stations:[],
  },
  
  ofps: [],
  crewlink: {
    roster: [],
    mle:null,
    name:null,
    actMonth:null,
    minmax: {mindate:null,maxdate:null},
    update: {},
  },
  flts: {
    legs : [],
    actDay:null,
    update:{},
  },
  ops: {
    data:[],
    ver:null
  },
  crew: {
    dates:{min:null,max:null},
    data:{},
    dataraw:[],
    tlc_active: [],
    actypes:[],
    ver:null
  },
  qualif: {
    data:{},
    ver:null
  },
  paie: {
    data:{},
    update:null
  },
  act: {
    data:{},
    update:null
  },

  docs: {
    list:[],
    details:[]
  },

});
export default state;
