<template>
  <section>
    <b-field label="MET Stations CODE (ICAO OR IATA)"
      ><b-taginput
        v-model="stations"
        placeholder="Add a station"
        type="is-info"
        :before-adding="function (tag) { return ((tag.length === 3) || (tag.length === 4) )} "
      ></b-taginput
    ></b-field>

    <div v-for="(msg, kmsg) in _.reverse(msgs)" :key="kmsg" class="">
      <b-message :title="msg.station" size="is-small" :closable="false">
        <b-loading
          :is-full-page="false"
          v-model="msg.loading"
          :can-cancel="false"
        ></b-loading>
        <div
          class="is-size-7 is-family-monospace"
          v-if="msg.taf"
          style="overflow: auto; width: 100%; white-space: nowrap"
        >
        <div class="has-text-weight-bold pb-2">TAF</div>
          <p v-html="nltobr(msg.taf)"></p>
        <div class="has-text-weight-bold">METAR</div>
          <p v-html="nltobr(msg.metar)"></p>
        </div>
      </b-message>
    </div>
  </section>
</template>
<script>
export default {
  created() {
    let me = this;
    me.stations = [me.flt.dep, me.flt.des];
    _.each(me.stations, function (v, k) {
      me.$set(me.msgs, v, {
        station: v,
        taf: "NIL",
        metar: "NIL",
        loading: false,
      });
    });
  },
  mounted() {},
  data() {
    return {
      msgs: [],
      stations: [],
    };
  },
  watch: {
    stations(st) {
      let me = this;
      _.each(me.stations, function (v, k) {
          me.stations[k]=_.upperCase(v)
          v=_.upperCase(v)
        if (!_.find(me.msgs, ["station", v])) {
        me.$apis.load_wx_taf({code:v},function(resp){me.msgs.push({ station: v, taf: resp.taf || "NIL", metar: resp.metar || "NIL", loading: false })})

}



      });
      //nettoyage
      //console.log(_.difference(me.stations,_.map(me.msgs,'station')))
      
      me.msgs=_.filter(me.msgs,(msg)=>(_.includes(me.stations,msg.station)))
    },
  },
  props: {
    flt: {},
  },
  computed: {},
  methods: {
    nltobr(v) {
      return v.replace(/\\n/g, "<br />");
    },
  },
};
</script>