<template>

<div class="p-2">

<article class="message is-small" v-for="(fol,kfol) in $store.docs.list.filter((x)=>(x.code==actualfolder.join('\\')))" :key="kfol">
           
   <div class="message-body">
           <template v-for="(file,kfile) in getinfo(fol.link)">
              <div :key="kfile" v-if="file" class="p-1 m-0"> 
               <p class="title is-6 is-spaced">{{file.titre}}</p>
               <b-field grouped group-multiline>
                    <div class="control">   
                <b-taglist attached>
                    <b-tag type="is-dark">REV</b-tag>
                    <b-tag type="is-info">{{file.rev}}</b-tag>
                </b-taglist>
                    </div>
                    <div class="control">
                <b-taglist attached>
                    <b-tag type="is-dark">DATE</b-tag>
                    <b-tag type="is-info">{{file.date}}</b-tag>
                </b-taglist>
                    </div>
               </b-field>

            <div class="buttons">
                <b-button size="is-small" v-for="(dfile,kdfile) in file.files" expanded type="is-info" inverted :key="kdfile" 
                    @click="getfile(dfile.link)"
                 icon-left="file-check-outline">{{dfile.file}}</b-button>
            </div>

              </div>
           </template> 
  </div>
</article>



</div>
    
</template>
<script>
export default {
    data(){
        return {
            loading:{}
        }
    },
    mounted(){
        let me=this
    },
    methods:{
        getinfo(link){
            let me=this
            let x=_.find(me.$store.docs.details,{link:link})
            if (x) {
                return [x]
            } else {
                if (!me.loading[link]) {
                    me.loading[link]="loading"
                    me.$apis.load_docpn_docinfo({link:link},function(){delete me.loading[link]})
                }
                return []
            }
        },
        getfile(link){
            let me=this
            window.open('api/docpn/download?link='+encodeURI(link)+'&token='+encodeURI(me.$store.token),'_blank')
        },

    },
    computed:{
    },
    props:['actualfolder']
}
</script>