import Vue from 'vue';
import { DateTime } from "luxon";


export default Vue.observable({
    now: DateTime.utc(),
    online: window.navigator.onLine,
    modals: {
        fltinfo: { show: false, flt: {}, activeTab: 0 },
        dutyinfo: { show: false, duty: {}, activeTab: 0 }
    },

    viewport: {
        height: window.innerHeight,
        width: window.innerWidth
    },

    ip:null,
    mytimer:[],
    loading: [
        //{full:false,cancel:false,msg:'loading from crewlink',url:'api/dsfsd'}    
    ],


})