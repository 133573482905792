<template>
<section>
  <div style="position: fixed;bottom: 20px;right: 20px;z-index: 9999" class="blink is-family-monospace" v-if="$helper.loading.length>0"><b-tag class="has-text-grey" type="is-warning">Loading...</b-tag></div>
  <transition enter-active-class="animated fadeInDown" leave-active-class="animated fadeOutDown" >
            <b-loading 
              :is-full-page="$helper.loading.some((x)=>(x.full))" 
              :active="($helper.loading.length>0) && ($helper.loading.every((x)=>(!x.hidden)))" 
              :can-cancel="$helper.loading.every((x)=>(!x.cancel))" class="bgflou">
                <div class="has-text-centered">
                <div style="margin: 0 auto;padding: 10px;"><xx_loader></xx_loader></div>
                  <transition-group name="custom-classes-transition" tag="div" enter-active-class="animated bounceInleft" leave-active-class="animated bounceOutRight" >
                    <template v-for="(el,kel) in $helper.loading.filter((x)=>!x.hidden)">
                    <div class="has-text-grey-dark is-size-6 has-text-weight-bold" :key="kel">
                      {{el.msg}}<br>
                    </div>
                    </template>
                  </transition-group>
                </div>
            </b-loading>
  </transition>
</section>	
</template>
<script>
	export default {
		
	}
</script>