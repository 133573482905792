<template>
  <section>

<div class="buttons p-2">
<b-button outlined 
        expanded
        type="is-info"
        size="is-small"
        icon-right="airplane"
        tag="a"
        :href="'https://www.flightradar24.com/'+(({TU:'TAR',UG:'TUX'})[flt.al])+flt.fnum"
        target="_blank"
      >
        Track {{(({TU:'TAR',UG:'TUX'})[flt.al])+flt.fnum}} on FlightRadar24
      </b-button>

      <b-button outlined 
        v-for="(reg,kreg) in fltops" :key="kreg"
        expanded
        type="is-info"
        size="is-small"
        icon-right="airplane"
        tag="a"
        :href="'https://www.flightradar24.com/data/aircraft/'+reg.AC_REGISTRATION"
        target="_blank"
      >
        Track {{reg.AC_REGISTRATION}} on FlightRadar24
      </b-button>

<b-button outlined 
        expanded
        type="is-info"
        size="is-small"
        icon-right="airplane"
        tag="a"
        :href="'https://skyvector.com/api/airportSearch?query='+flt.dep"
        target="_blank"
      >
        {{flt.dep}} Airport informations
      </b-button>

<b-button outlined 
        expanded
        type="is-info"
        size="is-small"
        icon-right="airplane"
        tag="a"
        :href="'https://skyvector.com/api/airportSearch?query='+flt.des"
        target="_blank"
      >
        {{flt.des}} Airport informations
      </b-button>

<b-button outlined 
        expanded
        type="is-info"
        size="is-small"
        icon-right="airplane"
        tag="a"
        href="https://map.blitzortung.org/"
        target="_blank"
      >
        Lightening map
      </b-button>
<div class="has-text-centered">
        <b-dropdown aria-role="list" >
            <button class="button is-info is-outlined is-expanded" slot="trigger" slot-scope="{ active }">
                <span>Weather Sat image from EUMETSAT</span>
                <b-icon :icon="active ? 'menu-up' : 'menu-down'"></b-icon>
            </button>

            <b-dropdown-item aria-role="listitem" ><a href="https://eumetview.eumetsat.int/static-images/latestImages/EUMETSAT_MSG_H03B_LowResolution.png" target="_blank">Full Disc</a></b-dropdown-item>
            <b-dropdown-item aria-role="listitem" ><a href="https://eumetview.eumetsat.int/static-images/latestImages/EUMETSAT_MSG_H03B_WesternEurope.png" target="_blank">EUROPE West</a></b-dropdown-item>
            <b-dropdown-item aria-role="listitem" ><a href="https://eumetview.eumetsat.int/static-images/latestImages/EUMETSAT_MSG_H03B_CentralEurope.png" target="_blank">EUROPE Central</a></b-dropdown-item>
            <b-dropdown-item aria-role="listitem" ><a href="https://eumetview.eumetsat.int/static-images/latestImages/EUMETSAT_MSG_H03B_EasternEurope.png" target="_blank">EUROPRE East </a></b-dropdown-item>
            <b-dropdown-item aria-role="listitem" ><a href="https://eumetview.eumetsat.int/static-images/latestImages/EUMETSAT_MSG_H03B_WesternAfrica.png" target="_blank">AFRIC West</a></b-dropdown-item>
            <b-dropdown-item aria-role="listitem" ><a href="https://eumetview.eumetsat.int/static-images/latestImages/EUMETSAT_MSG_H03B_CentralAfrica.png" target="_blank">AFRIC Central</a></b-dropdown-item>
            <b-dropdown-item aria-role="listitem" ><a href="https://eumetview.eumetsat.int/static-images/latestImages/EUMETSAT_MSG_H03B_EasternAfrica.png" target="_blank">AFRIC EAST</a></b-dropdown-item>
        </b-dropdown>
</div>
</div>

  </section>
</template>
<script>
export default {
  created() {
    let me = this;
  },
  mounted() {},
  data() {
    return {
    };
  },
  props: {
    flt: {},
  },
  computed: {
    fltops() {
      let me = this;
      return _.filter(
        me.$store.ops.data,
        (leg) =>
          me.flt.al == leg.al &&
          me.flt.fnum == leg.fnum &&
          (me.flt.dep == leg.DEP_AP_ACTUAL || me.flt.dep == leg.DEP_AP_SCHED) &&
          (me.flt.des == leg.ARR_AP_ACTUAL || me.flt.des == leg.ARR_AP_SCHED) &&
          (me.$luxon.fromSQL(me.flt.hdep).toFormat("Y-LL-dd") ==
            me.$luxon.fromSQL(leg.hdep).toFormat("Y-LL-dd") ||
            me.$luxon.fromSQL(me.flt.hdep).toFormat("Y-LL-dd") ==
              me.$luxon.fromSQL(leg.DEP_SCHED_DT).toFormat("Y-LL-dd"))
      );
    },      
  },
  methods: {
      
  },
};
</script>      