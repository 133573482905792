<template>
    <section>



<nav class="breadcrumb has-succeeds-separator" aria-label="breadcrumbs">
  <ul>
    <li @click="actualFolder=[]">
        <a href="#" aria-current="page">ALL Docs</a>
    </li>
    <li v-for="(fol,kfol) in actualFolder" :key="kfol"  @click="actualFolder=_.take(actualFolder, kfol+1)">
        <a href="#" aria-current="page">{{fol}}</a>
    </li>
  </ul>
</nav>

<span class="box m-2 p-2 is-size-8"><small>No part of these documents should be reproduced or transmitted in any form or by any means, i.e. electronic, printed paper, without prior authorization from Tunisair.</small></span>
        <div class="box is-info">
            <div class="buttons">
                <b-button v-for="(fol,kfol) in folders(actualFolder.length,actualFolder[actualFolder.length-1] || null)" expanded type="is-info" outlined :key="kfol" @click="actualFolder.push(fol)" icon-left="folder">{{fol}}</b-button>
            </div>
        </div>

        <xx_docpnDoc :actualfolder="actualFolder"></xx_docpnDoc>
    </section>
</template>
<script>
export default {
    data(){
        return {
            actualFolder: [],
        }
    },
    mounted(){
        let me=this
        //console.log(me.folders(0))
        me.$apis.load_docpn_docs({},me.docsLoaded)
    },
    methods:{
        docsLoaded(resp){
            console.log(resp)
        },
        folders(level=0,sup=null){
            let me=this
            return _.uniq(
                me.$store.docs.list
                            .filter((x)=>((x.code.split('\\').length-1>=level)))
                            .filter((x)=>((!sup || (x.code.split('\\')[level-1]==sup) )))
                            .map((x)=>(x.code.split('\\')[level]))
                            )
        },


    },
    computed:{

    },
}        
</script>