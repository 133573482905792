var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.crewlink.roster.length>0)?[(_vm.calcListView)?_c('div',{staticClass:"container",staticStyle:{"max-width":"500px"}},[_vm._l((_vm.crewlink.roster),function(day,kday){return [(_vm.$luxon.fromSQL(day.date).toFormat('d') == 1 || kday == 0)?_c('div',{directives:[{name:"sticky",rawName:"v-sticky",value:({ zIndex: 1, stickyTop: 60, disabled: false }),expression:"{ zIndex: 1, stickyTop: 60, disabled: false }"}],key:kday,staticClass:"px-2 pb-4",attrs:{"id":'roster-' + _vm.$luxon.fromSQL(day.date).toFormat('y-LL')}},[_c('div',{staticClass:"box columns is-mobile month",staticStyle:{"background-color":"#00a3cc","padding":"0","margin":"0"}},[_c('div',{staticClass:"column"},[_c('span',{staticClass:"title has-text-white"},[_vm._v("\n                "+_vm._s(_vm._f("luxon:format")(day.date,"LLLL"))+"\n              ")]),_vm._v(" "),_c('span',{staticClass:"subtitle has-text-light"},[_vm._v("\n                "+_vm._s(_vm._f("luxon:format")(day.date,"y"))+"\n              ")]),_vm._v(" "),_c('div',[_c('span',{staticClass:"is-size-7 has-text-light"},[_vm._v("credit:")]),_c('span',{staticClass:"is-family-monospace has-text-success has-text-weight-bold"},[_vm._v("["+_vm._s(_vm.hours_credit(day.date))+"]")]),_vm._v(" "),_c('span',{staticClass:"ml-4 is-size-7 has-text-light"},[_vm._v("flt:")]),_c('span',{staticClass:"is-family-monospace has-text-warning has-text-weight-bold"},[_vm._v(_vm._s(_vm.hours_flight(day.date)))])])]),_vm._v(" "),_c('div',{staticClass:"column is-narrow has-text-white"},[_c('diV',{on:{"click":function($event){_vm.pickMonth = true}}},[_c('b-icon',{attrs:{"icon":"calendar-month"}})],1),_vm._v(" "),_c('div',{staticStyle:{"position":"absolute","bottom":"1.5em"},on:{"click":function($event){_vm.$apis.load_crew_month({
                    datedeb: _vm.$luxon
                      .fromSQL(day.date)
                      .startOf('month')
                      .toSQLDate(),
                    datefin: _vm.$luxon
                      .fromSQL(day.date)
                      .endOf('month')
                      .toSQLDate(),
                  },_vm.rosterLoaded)}}},[_c('b-icon',{attrs:{"icon":"reload"}})],1)],1),_vm._v(" "),_c('div',{staticClass:"column is-narrow has-text-white"},[_c('diV',{class:{
                  'is-invisible':
                    _vm.$luxon
                      .fromSQL(day.date)
                      .startOf('month')
                      .minus(1, 'day')
                      .diff(
                        _vm.$luxon.fromSQL(_vm.crewlink.minmax.mindate),
                        'seconds'
                      )
                      .toObject().seconds <= 0,
                },on:{"click":function($event){_vm.goMonth(_vm.$luxon.fromSQL(day.date).minus({ month: 1 }))}}},[_c('b-icon',{attrs:{"icon":"arrow-up-bold-box"}})],1),_vm._v(" "),_c('div',{class:{
                  'is-invisible':
                    _vm.$luxon
                      .fromSQL(day.date)
                      .endOf('month')
                      .plus(1, { days: 1 })
                      .diff(
                        _vm.$luxon.fromSQL(_vm.crewlink.minmax.maxdate),
                        'seconds'
                      )
                      .toObject().seconds >= 0,
                },staticStyle:{"position":"absolute","bottom":"1.5em"},on:{"click":function($event){_vm.goMonth(_vm.$luxon.fromSQL(day.date).plus({ month: 1 }))}}},[_c('b-icon',{attrs:{"icon":"arrow-down-bold-box"}})],1)],1)])]):_vm._e(),_vm._v(" "),_c('div',{key:day.date,staticClass:"columns is-mobile pt-2 pb-2 px-2",class:{
            'has-background-danger-light':
              _vm.now.toFormat('y-LL-dd') == day.date,
          },staticStyle:{"margin":"0","padding":"0"}},[_c('div',{staticClass:"column is-narrow pl-0 pr-1 pt-0",on:{"click":function($event){return _vm.$router.push({ name: 'crewlinkFlts', query: { datebegin: day.date,dateend: day.date} })}}},[_c('xx_calendarIcon',{class:{
                'animated infinite swing':
                  _vm.now.toFormat('y-LL-dd') == day.date,
              },attrs:{"color":_vm.now.toFormat('y-LL-dd') == day.date ? 'today' : 'day',"date":day.date,"id":'roster-' + day.date}}),_vm._v(" "),(day.credit)?_c('div',{staticClass:"has-text-centered has-text-success"},[_vm._v("\n              ["+_vm._s(day.credit)+"]\n            ")]):_vm._e()],1),_vm._v(" "),_c('xx_DayDuty',{attrs:{"day":day,"lastday":_vm.$store.crewlink.roster[kday - 1]}})],1),_vm._v(" "),(
            _vm.$luxon.fromSQL(day.date).toSeconds() <
              _vm.$luxon.fromSQL(_vm.$store.crewlink.minmax.maxdate).toSeconds() &&
            kday + 1 < _vm._.size(_vm.$store.crewlink.roster) &&
            _vm.$luxon.fromSQL(_vm.$store.crewlink.roster[kday + 1].date).month !=
              _vm.$luxon.fromSQL(day.date).month &&
            _vm.$luxon.fromSQL(day.date).day <
              _vm.$luxon.fromSQL(day.date).endOf('month').day
          )?_c('div',{key:'incomplete-' + day.date,staticClass:"mx-6 my-3"},[_c('b-message',{attrs:{"type":"is-warning","has-icon":""}},[_vm._v("\n            This month is partially loaded...\n\n            "),_c('b-button',{attrs:{"type":"is-warning","expanded":""},on:{"click":function($event){_vm.$apis.load_crew_month({
                  datedeb: _vm.$luxon
                    .fromSQL(day.date)
                    .startOf('month')
                    .toSQLDate(),
                  datefin: _vm.$luxon
                    .fromSQL(day.date)
                    .endOf('month')
                    .toSQLDate(),
                },_vm.rosterLoaded)}}},[_vm._v("Reload this month")])],1)],1):_vm._e(),_vm._v(" "),(
            _vm.$luxon.fromSQL(day.date).toSeconds() <
              _vm.$luxon.fromSQL(_vm.$store.crewlink.minmax.maxdate).toSeconds() &&
            kday + 1 < _vm._.size(_vm.$store.crewlink.roster) &&
            _vm.$luxon.fromSQL(_vm.$store.crewlink.roster[kday + 1].date).month !=
              _vm.$luxon.fromSQL(day.date).month &&
            _vm.$luxon
              .fromSQL(_vm.$store.crewlink.roster[kday + 1].date)
              .toFormat('y-LL') !=
              _vm.$luxon.fromSQL(day.date).plus({ month: 1 }).toFormat('y-LL')
          )?_c('div',{key:'loadmonth-' + day.date,staticClass:"mx-2 my-6"},[_c('b-message',{attrs:{"type":"is-info","has-icon":""}},[_vm._v("\n            Month discontinuity...\n          ")])],1):_vm._e()]})],2):[_c('div',{staticClass:"columns is-mobile is-centered is-vcentered",staticStyle:{"margin":"0","padding":"10px"}},[_c('div',{staticClass:"columns box month is-mobile is-centered is-vcentered",staticStyle:{"margin":"0","padding":"0","margin-bottom":"10px"}},[_c('diV',{staticClass:"column is-narrow has-text-white",class:{
              'is-invisible':
                _vm.actMonth
                  .startOf('month')
                  .minus(1, 'day')
                  .diff(_vm.$luxon.fromSQL(_vm.crewlink.minmax.mindate), 'seconds')
                  .toObject().seconds <= 0,
            },on:{"click":function($event){_vm.goMonth(_vm.actMonth.minus({ month: 1 }).startOf('month'))}}},[_c('div',{staticClass:"column is-narrow"},[_c('b-icon',{attrs:{"icon":"arrow-left-thick","size":"is-small"}})],1)]),_vm._v(" "),_c('div',{staticClass:"mx-4 has-text-centered"},[_c('span',{staticClass:"is-size-3-tablet is-size-5 has-text-white"},[_vm._v("\n              "+_vm._s(_vm.actMonth.toFormat("LLLL"))+"\n            ")]),_vm._v(" "),_c('span',{staticClass:"subtitle has-text-light"},[_vm._v("\n              "+_vm._s(_vm.actMonth.toFormat("y"))+"\n            ")]),_vm._v(" "),_c('div',[_c('span',{staticClass:"is-size-7 has-text-light"},[_vm._v("credit:")]),_c('span',{staticClass:"is-family-monospace has-text-success has-text-weight-bold"},[_vm._v("["+_vm._s(_vm.hours_credit(_vm.actMonth.toFormat("y-LL-dd")))+"]")]),_vm._v(" "),_c('span',{staticClass:"ml-4 is-size-7 has-text-light"},[_vm._v("flt:")]),_c('span',{staticClass:"is-family-monospace has-text-warning has-text-weight-bold"},[_vm._v(_vm._s(_vm.hours_flight(_vm.actMonth.toFormat("y-LL-dd"))))])])]),_vm._v(" "),_c('diV',{staticClass:"column is-narrow has-text-white",on:{"click":function($event){_vm.pickMonth = true}}},[_c('b-icon',{attrs:{"icon":"calendar-month","size":"is-small"}})],1),_vm._v(" "),_c('div',{staticClass:"column is-narrow has-text-white",on:{"click":function($event){_vm.$apis.load_crew_month({
                datedeb: _vm.actMonth.startOf('month').toSQLDate(),
                datefin: _vm.actMonth.endOf('month').toSQLDate(),
              },_vm.rosterLoaded)}}},[_c('b-icon',{attrs:{"icon":"reload","size":"is-small"}})],1),_vm._v(" "),_c('div',{staticClass:"column is-narrow has-text-white",class:{
              'is-invisible':
                _vm.actMonth
                  .endOf('month')
                  .plus(1, { days: 1 })
                  .diff(_vm.$luxon.fromSQL(_vm.crewlink.minmax.maxdate), 'seconds')
                  .toObject().seconds >= 0,
            },on:{"click":function($event){_vm.goMonth(_vm.actMonth.plus({ month: 1 }))}}},[_c('b-icon',{attrs:{"icon":"arrow-right-thick","size":"is-small"}})],1)],1)]),_vm._v(" "),_c('div',{staticClass:"vuescrollto table-container"},[_c('table',{staticClass:"mytable"},_vm._l((_vm.crewlink_monthTable[
              _vm.actMonth.toFormat('y-LL')
            ]),function(week,num){return _c('tr',{key:num},[_vm._l((_vm.weekdays),function(dw,kdw){return [(!(week[dw.field].day && week[dw.field].day.date))?_c('td',{key:kdw,staticClass:"is-invisible"}):_c('td',{key:kdw,staticClass:"bordered",class:{
                  'has-background-white-ter flou':
                    _vm.actMonth.toFormat('y-LL') !=
                    _vm.$luxon.fromSQL(week[dw.field].day.date).toFormat('y-LL'),
                  'has-background-danger-light':
                    _vm.now.toFormat('y-LL-dd') ==
                    _vm.$luxon
                      .fromSQL(week[dw.field].day.date)
                      .toFormat('y-LL-dd'),
                  tdcenter:
                    (week[dw.field].day && week[dw.field].day.all
                      ? _vm._.head(week[dw.field].day.all).type
                      : false) == 'wholeday',
                }},[_c('xx_DayDutyCal',{attrs:{"id":week[dw.field].day
                      ? 'roster-' +
                        _vm.$luxon
                          .fromSQL(week[dw.field].day.date)
                          .toFormat('y-LL-dd')
                      : kdw + num,"day":week[dw.field].day,"lastday":week[dw.field].last}})],1)]})],2)}),0)])]]:_c('section',{staticClass:"hero is-medium is-primary is-bold"},[_c('div',{staticClass:"hero-body"},[_c('div',{staticClass:"container"},[_c('h1',{staticClass:"title"},[_vm._v("Crewlink")]),_vm._v(" "),_c('h2',{staticClass:"subtitle"},[_vm._v("No duty found...")]),_vm._v(" "),_c('b-button',{attrs:{"type":"is-warning"},on:{"click":function($event){return _vm.$apis.load_crew_month({},_vm.rosterLoaded)}}},[_vm._v("Reload")])],1)])]),_vm._v(" "),_c('b-modal',{model:{value:(_vm.pickMonth),callback:function ($$v) {_vm.pickMonth=$$v},expression:"pickMonth"}},[_c('form',{staticClass:"container has-text-centered",attrs:{"action":""}},[_c('b-field',{attrs:{"label":"Select a date"}},[_c('b-datepicker',{attrs:{"type":"month","inline":"","min-date":new Date(_vm.crewlink.minmax.mindate),"max-date":new Date(_vm.crewlink.minmax.maxdate),"trap-focus":""},on:{"input":function (e) {
              _vm.pickMonth = false;
              _vm.goMonth(_vm.$luxon.fromJSDate(e).startOf('month'));
            }}})],1)],1)]),_vm._v(" "),_c('div',{staticClass:"buttons p-2 stickbottom"},[_c('b-button',{staticClass:"m-2 stickbottom",attrs:{"size":"is-small","icon-left":"format-rotate-90"},on:{"click":function($event){_vm.forcedView=true;_vm.listView = !_vm.listView}}},[_vm._v(_vm._s(_vm.listView ? "Month view" : "List view"))])],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }