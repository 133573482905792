<template>
  <div>

    <template v-if="_.size(crewdata)>0">


<div class="table-container mytablecontainer" :style="'height:'+($helper.viewport.height - 80)+'px'" >
  <table class="table myrosterstable stickyHead is-narrow is-bordered is-stripped" v-if="!filter.active" >
    <thead>
      <tr>
        <th class="stickylefttop">
          <div class="has-text-info-dark pr-1" >
              <diV @click="filter.active = true">
                <b-icon icon="filter" class=""></b-icon>
              </diV>
            </div>
        </th>  
        <th v-for="date in filter_dates" :key="'date'+date" class="px-2 py-1">
              <xx_calendarIcon
                width=""
                :date="date"
                :full="true"></xx_calendarIcon>
        </th>  
        <th v-if="loadmoredates" class="nowrap">
           <div class="has-text-info-dark pr-1" >
              <diV @click="filter.dates.end=$luxon.min($luxon.fromSQL(filter.dates.end).plus({day:7}),$luxon.fromSQL($store.crew.dates.max)).toFormat('y-LL-dd HH:mm:ss')">
                <b-icon icon="fast_forward" class=""></b-icon>Load more...
              </diV>
            </div>         
        </th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="(tlc,ktlc) in filter_tlcs" :key="'tlc'+ktlc">
        <th class="px-1 py-0 m-0">
          <b-tooltip
            :label="qualifs_all[tlc].qualif.map((x)=>(x.college+x.ac)).reduce((r,k)=>(r+' '+k),'')" multilined size="is-small" position="is-right">
          <div style="max-width:60px">
            <div class="has-text-weight-bold has-text-grey">{{ tlc }}</div>
            <div class="is-size-8" style="white-space: break-spaces;">{{ _.truncate(_.capitalize(qualifs_all[tlc].lname),{length: 11}).replace("Ben ", "B.") }}, {{  _.truncate(_.capitalize(qualifs_all[tlc].fname).replace("Mohamed", "Med"),{length: 11}) }}</div>
          </div>
          </b-tooltip>
        </th>
        <td v-for="date in filter_dates" :key="'prog'+tlc+date" class="nowrap">
          <xx_RostersDay 
            v-if="crewdata[tlc]" 
            :day="crewdata[tlc][date] || []" 
            :lastday="crewdata[tlc][$luxon.fromSQL(date).minus({ day: 1 }).toFormat('y-LL-dd')] || []">
          </xx_RostersDay>
        </td>
                <td v-if="loadmoredates" class="nowrap">
           <div class="has-text-info-dark pr-1" >
              <diV @click="filter.dates.end=$luxon.min($luxon.fromSQL(filter.dates.end).plus({day:7}),$luxon.fromSQL($store.crew.dates.max)).toFormat('y-LL-dd HH:mm:ss')">
                <b-icon icon="fast_forward" class=""></b-icon>Load more...
              </diV>
            </div>         
        </td>

      </tr>
    </tbody>
    <tfoot v-if="$store.crewlink.mle">
      <tr v-for="(tlc,ktlc) in [$store.crewlink.mle]" :key="'mytlc'+ktlc">
        <th class="px-1 py-0 m-0 stickyleftbottom">
          <b-tooltip
            :label="qualifs_all[tlc].qualif.map((x)=>(x.college+x.ac)).reduce((r,k)=>(r+' '+k),'')" multilined size="is-small" position="is-right">
          <div style="max-width:60px">
            <div class="has-text-weight-bold has-text-grey">{{ tlc }}</div>
            <div class="is-size-8" style="white-space: break-spaces;">{{ _.capitalize(qualifs_all[tlc].lname) }}, {{ _.capitalize(qualifs_all[tlc].fname) }}</div>
          </div>
          </b-tooltip>
        </th>
        <th v-for="date in filter_dates" :key="'prog'+tlc+date" class="nowrap">
          <xx_RostersDay 
            v-if="crewdata[tlc]" 
            :day="crewdata[tlc][date] || []" 
            :lastday="crewdata[tlc][$luxon.fromSQL(date).minus({ day: 1 }).toFormat('y-LL-dd')] || []">
          </xx_RostersDay>
        </th>
                <td v-if="loadmoredates" class="nowrap">
           <div class="has-text-info-dark pr-1" >
              <diV @click="filter.dates.end=$luxon.min($luxon.fromSQL(filter.dates.end).plus({day:7}),$luxon.fromSQL($store.crew.dates.max)).toFormat('y-LL-dd HH:mm:ss')">
                <b-icon icon="fast_forward" class=""></b-icon>Load more...
              </diV>
            </div>         
        </td>

      </tr>
    </tfoot>
  </table>
</div>

    </template>
    <section class="hero is-medium is-primary is-bold" v-else>
      <div class="hero-body">
        <div class="container">
          <h1 class="title">Netline OPS</h1>
          <h2 class="subtitle">
            No Flights found...<b-button
              type="is-warning"
              @click="$apis.load_crew_data({})"
              >Reload</b-button
            >
          </h2>
        </div>
      </div>
    </section>

    <b-modal v-model="filter.active">
      <div class="modal-card" style="width: auto">
        <header class="modal-card-head">
          <p class="modal-card-title">CREW Filter</p>
          <button type="button" class="delete" @click="filter.active = false;$apis.test()" />
        </header>
        <section class="modal-card-body">
          <template v-for="(filterdata,filtertype) in qualifs">
            {{filtertype}}: 
          <b-field v-for="(datas,kdatas) in chunk(7,filterdata)" :key="'chunk'+filtertype+kdatas">
            <b-checkbox-button
              v-model="filter[filtertype]"
              size="is-small"
              :native-value="data"
              v-for="(data, kdata) in datas"
              :key="'data'+filtertype+kdata"
              >{{ data }}</b-checkbox-button>
          </b-field>
          </template>
          <div class="block is-size-6 p-1">Found: <b>{{filter_tlcs.length}}</b></div>
          
        </section>
        <footer class="modal-card-foot">
          <button
            class="button"
            type="button"
            @click="filter=resetfilter;filter.active = false"
          >
            Reset Filter
          </button>
          <button class="button is-primary" @click="filter.dates.end=resetfilter.dates.end;filter.active = false">
            Activate Filter
          </button>
        </footer>
      </div>
    </b-modal>
  </div>
</template>


<script>
export default {
  created() {
    let me = this;

    me.resetfilter = {
        active: false,
        ac: ["320"],
        college: ["CP"],
        base: ["TUN"],
        dates: { 
          start: me.$luxon.utc().startOf('day').minus({day:1}).toFormat('y-LL-dd HH:mm:ss') ,
          end: me.$luxon.utc().endOf('day').plus({day:7}).toFormat('y-LL-dd HH:mm:ss') 
        },
      }

    me.filter = {
        active: false,
        ac: me.resetfilter.ac,
        college:me.resetfilter.college,
        base: me.resetfilter.base,
        dates: { 
          start: me.resetfilter.dates.start ,
          end: me.resetfilter.dates.end 
        },
      }


    //me.selHour=me.now.toFormat('Y-LL-dd HH:mm:ss')
  },
  async mounted() {
    let me = this;
    await me.$apis.load_qualif_data({})
    await me.$apis.load_crew_data({})
    //me.myinterval= setInterval(function(){ if (me.autoscroll && !me.elementInViewport('now')) { me.scrollleg(); me.window.console.log('scrolled')}; }, 5000);
  },
  beforeDestroy() {
    //clearInterval(this.myinterval)
  },
  data() {
    return {


      filter: {
        active: false,
        ac: [],
        college: [],
        base: [],
        dates: { start: "", end: "" },
      },
      resetfilter: {
        active: false,
        ac: [],
        college: [],
        base: [],
        dates: { start: "", end: "" },
      },
    };
  },
  methods: {
   chunk(size, xs) { 
    return xs.reduce(
      (segments, _, index) =>
        index % size === 0 
          ? [...segments, xs.slice(index, index + size)] 
          : segments, 
      []
    )},
    onScroll(event) {
      console.log(event);
    },


  },

  computed: {
    loadmoredates(){
      let me=this
      return me.$luxon.fromSQL(me.filter.dates.end)<me.$luxon.fromSQL(me.$store.crew.dates.max)
    },
    qualifs_all(){
      return this.$store.qualif.data
    },
    qualifs() {
      let me = this;
      let out={college:[],ac:[],base:[]}
      me.$store.crew.tlc_active.forEach(function(tlc){
        out.college=_.uniq(out.college.concat(me.qualifs_all[tlc].qualif.map((one)=>(one.college)))).sort()
        out.ac=_.uniq(out.ac.concat(me.qualifs_all[tlc].qualif.map((one)=>(one.ac)))).sort()
        out.base=_.uniq(out.base.concat(me.qualifs_all[tlc].base)).sort()
      })
      return {
        college: out.college,
        ac: out.ac,
        base: out.base,
      };
    },
    now() {
      return this.$helper.now;
    },
    crewdata() {
      return this.$store.crew.data;
    },

    filter_dates() {
      let me = this;
      let out=[me.$luxon.max(me.$luxon.fromSQL(me.filter.dates.start),me.$luxon.fromSQL(me.$store.crew.dates.min))]
      while (out[out.length-1].plus({day:1}) <= me.$luxon.min(me.$luxon.fromSQL(me.filter.dates.end).endOf('day'),me.$luxon.fromSQL(me.$store.crew.dates.max))) {
        out.push(out[out.length-1].plus({day:1}))
      }
      return out.map((lux)=>(lux.toFormat('y-LL-dd')))
    },

    filter_tlcs() {
      let me = this;
      let s = me.$luxon.utc();
      
      let tlcac=me.$store.crew.tlc_active.reduce((r,pn)=>(((me.filter.ac.length == 0) || ( me.qualifs_all[pn].qualif.some((x)=>(me.filter.ac.includes(x.ac)))  ))?(r.concat(pn)):r),[])
      let tlccollege=me.$store.crew.tlc_active.reduce((r,pn)=>(((me.filter.college.length == 0) || ( me.qualifs_all[pn].qualif.some((x)=>(me.filter.college.includes(x.college)))  ))?(r.concat(pn)):r),[])
      let tlcbase=me.$store.crew.tlc_active.reduce((r,pn)=>(((me.filter.base.length == 0) || (me.filter.base.includes(me.qualifs_all[pn].base)))?(r.concat(pn)):r),[])

      let out=_.orderBy(_.intersection(tlcac,tlccollege,tlcbase),(tlc)=>(me.qualifs_all[tlc].lname+me.qualifs_all[tlc].fname))
            console.log(
        "filter_tlcs",
        me.$luxonInterval
          .fromDateTimes(s, me.$luxon.utc())
          .toDuration()
          .toObject()
      );
      return out
    },
  
  },
  props: {},
};
</script>
<style scoped>
.nowrap {
  white-space: nowrap;
}
.cell-center {
  text-align: center;
  vertical-align: middle;
}
.setheight {
  height: 80px;
}


div.mytablecontainer {
    
    overflow: auto;
    position: relative;

}
/*
.myrosterstable {
  table-layout: fixed;
}


.myrosterstable.stickyHead tbody th {
  	position: -webkit-sticky;
	position: -moz-sticky;
	position: -o-sticky;
	position: -ms-sticky;  
    position: sticky;
    left: 0px;
    background: rgb(221, 237, 241)

}
.myrosterstable.stickyHead thead th {
  	position: -webkit-sticky;
	position: -moz-sticky;
	position: -o-sticky;
	position: -ms-sticky;  
    position: sticky;
    top: 0px;
    text-align: center;
    vertical-align: middle;
    background: rgb(221, 237, 241)
    }
.myrosterstable.stickyHead tfoot th {
	position: -webkit-sticky;
    bottom: 40px;
    text-align: center;
    vertical-align: middle;
    background: rgb(221, 237, 241)
    }
.myrosterstable.stickyHead tfoot th.stickyleftbottom {
  left:0;
  z-index: 2;
  background: rgb(221, 237, 241)
}
.myrosterstable.stickyHead thead th.stickylefttop {
  left:0;
  z-index: 2;
  background: rgb(221, 237, 241)
}
.myrosterstable.stickyHead td,
.myrosterstable.stickyHead th {
    padding: 2px 2px;
}
*/
*,
*:before,
*:after {
  box-sizing: inherit;
}
.mytablecontainer {
  position: relative;
  width:100%;
  z-index: 1;
  margin: auto;
  overflow: auto;
  /*height: 350px;*/
}
.mytablecontainer table {
  width: 100%;
  min-width: 1280px;
  margin: auto;
/*  border-collapse: separate;*/
  border-spacing: 0;
}
.table-wrap {
  position: relative;
}
.mytablecontainer th,
.mytablecontainer td {
  padding: 2px 4px;
  border: 1px solid rgb(236, 236, 236);
  background: #fff;
  vertical-align: top;
}
.mytablecontainer thead th {
  background: rgb(240, 253, 251);
  color: rgb(92, 92, 92);
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}
/* safari and ios need the tfoot itself to be position:sticky also */
.mytablecontainer tfoot,
.mytablecontainer tfoot th,
.mytablecontainer tfoot td {
  position: -webkit-sticky;
  position: sticky;
  bottom: 0;
  background: rgb(240, 253, 251);
  color: rgb(46, 46, 46);
  z-index:4;
}


th:first-child {
  position: -webkit-sticky;
  position: sticky;
  left: 0;
  z-index: 2;
  background: rgb(240, 253, 251);
}
thead th:first-child,
tfoot th:first-child {
  z-index: 5;
}



</style>
