<template>
  <div
    class="box has-text-centered has-text-white pr-0"
    :class="color"
    :style="width+'+margin: 0; padding: 0.25em; line-height: 1'"
  >
    <div class="is-size-7 has-text-yellow is-family-monospace">{{date | luxon:format("ccc")}}</div>
    <div class="is-size-5 has-text-weight-bold has-text-yellow">{{date | luxon:format("d")}}</div>
    <div v-if="full" class="is-size-7 has-text-weight-light has-text-warning is-uppercase">{{date | luxon:format("LLLyy")}}</div>
  </div>
</template>

<script>

export default {
    props: {
      date: String,
      color: {default:"has-background-info"},
      full: {default: false},
      width : {default: 'width: 50px;'}
    }
};
</script>