<template>
  <b-modal
    v-model="$helper.modals.dutyinfo.show"
    has-modal-card
    full-screen
    :can-cancel="false" v-if="tar_extract"
  >

      <div class="modal-card" style="width: auto" >
        <header class="modal-card-head px-1">
          <div class="modal-card-title">
            <div class="columns is-mobile">
              <div class="column pl-2 pr-2">Duty info</div>
              <div class="column pr-1 is-narrow">
                <b-taglist attached>
                  <b-tag type="is-info is-light px-1"><span v-html="{S:'SIMU ',G:'DH LIMO ',R:'STDBY '}[(isReserve(duty.label)?'R':null) || duty.type]" v-if="duty.type || (isReserve(duty.label))"></span>{{ duty.label }}</b-tag>
                  <b-tag type="is-info px-1 has-text-weight-semibold">{{ duty.dep }}<template v-if="duty.des">-{{ duty.des }}</template></b-tag>
                </b-taglist>
              </div>

              <div class="column pr-5 is-narrow has-text-right is-family-monospace is-narrow">
                <span class="has-text-grey px-1 is-size-7">{{$luxon.fromSQL(duty.date || duty.hdep).toFormat("ccc ddLLLyy")}}</span>
              </div>
            </div>
          </div>
          <div>
          <button type="button" class="delete" style="position:absolute;right:5px; top: 5px" @click="$helper.modals.dutyinfo.show=false" />
          </div>
        </header>

        <section class="modal-card-body px-2" v-if="!isReserve(duty.label)">
              <template v-if="$helper.modals.dutyinfo.activeTab==0">
                <xx_Attendee :duty="duty"></xx_Attendee>
              </template>
        </section>
        <section class="modal-card-body px-2" v-else>
              <template v-if="$helper.modals.dutyinfo.activeTab==0">
                <xx_Reserve :duty="duty"></xx_Reserve>
              </template>
            
              <template v-if="$helper.modals.dutyinfo.activeTab==1">
                <xx_OpenFlts :duty="duty"></xx_OpenFlts>
              </template>
        </section>

        <footer class="modal-card-foot px-1 py-1">
          <div class="buttons">
              <template  v-if="!isReserve(duty.label)">
            <b-button :class="{'is-dark':$helper.modals.dutyinfo.activeTab==0,'is-outlined':$helper.modals.dutyinfo.activeTab!=0}" size="is-small" @click="$helper.modals.dutyinfo.activeTab=0" icon-left="account-group">Attendee</b-button>
              </template>
              <template v-else>
            <b-button :class="{'is-dark':$helper.modals.dutyinfo.activeTab==0,'is-outlined':$helper.modals.dutyinfo.activeTab!=0}" size="is-small" @click="$helper.modals.dutyinfo.activeTab=0" icon-left="account-group">STDBY info</b-button>
            <b-button :class="{'is-dark':$helper.modals.dutyinfo.activeTab==1,'is-outlined':$helper.modals.dutyinfo.activeTab!=1}" size="is-small" @click="$helper.modals.dutyinfo.activeTab=1" icon-left="clipboard-check">Open Flights</b-button>
              </template>
          </div>
        </footer>
      </div>

  </b-modal>
</template>

<script>
export default {
  created() {
    let me=this
    me.$helper.modals.dutyinfo.activeTab=0
    if (!me.duty.date) {
      me.$set(me.duty,"date",me.$luxon.fromSQL(me.duty.hdep).toFormat("y-LL-dd"))
    }
  },
  data() {
    return {
    };
  },
  props: { duty: {} },
  methods: {},
};
</script>
<style>
.modal-card-body .tab-content {
  padding: 2px;
}
</style>>