<template>
    <section>

        <div class="box py-2 px-2">
          <b-menu>
            <b-menu-list label="Fiche de PAIE">
                <b-menu-item icon="square-outline" :expanded="kyear==0" v-for="(year,kyear) in Object.keys(paie).sort().reverse()" :key="year" @click="!ficheYearIsLoaded(year)?loadFicheYear(year):null">
                        <template #label> <span><b> {{year}} </b></span> 
                        <span v-if="!ficheYearIsLoaded(year)"></span>
                        <span class="is-pulled-right is-size-7 px-1 has-text-weight-light has-text-info" @click="loadWholeYear(year)" v-else-if="!(_.size(paie[year])>0 && Object.keys(paie[year]).every( (x)=> ( ficheMonthIsLoaded(year,x) ) ))">-Load whole year-</span>
                        <span v-else class="is-pulled-right is-size-7 has-background-grey px-1 has-text-weight-bold has-text-warning">{{total(Object.values(paie[year]).map((x)=>(x["recap"]?x["recap"]["Net à Payer : "] : "0") ))}}</span>

                        </template>
                        <b-menu-item  v-for="(fiche,month) in paie[year]" :key="month">
                            <template #label="props">
                                <div class="columns is-mobile">
                                <span class="column is-size-8" @click="!ficheMonthIsLoaded(year,month)?loadFiche(year,month):null">{{month}}</span>
                                <span class="column is-narrow is-size-7 has-text-weight-bold" v-if="ficheMonthIsLoaded(year,month)">{{paie[year][month]["recap"]["Net à Payer : "]}}</span>
                                </div>
                                <template v-if="props.active && ficheMonthIsLoaded(year,month)">
                                    <div class="is-size-8 columns">
                                        <b-table class="nowrap column" :mobile-cards="false" striped :data="[paie[year][month]['entete']]" :columns="Object.keys(paie[year][month]['entete']).map((x)=>({field: x,label: x,}))"></b-table>
                                    </div>
                                    <div class="is-size-8 columns">
                                        <b-table class="nowrap column" :mobile-cards="false" striped :data="paie[year][month]['fiche_plus']" :columns="Object.keys(paie[year][month]['fiche_plus'][0]).map((x)=>({field: x,label: x,})).slice(2)"></b-table>
                                        <b-table class="nowrap column" :mobile-cards="false" striped :data="paie[year][month]['fiche_moins']" :columns="Object.keys(paie[year][month]['fiche_moins'][0]).map((x)=>({field: x,label: x,})).slice(2)"></b-table>
                                    </div>
                                    <div class="is-size-8 columns">
                                        <b-table class="nowrap column" :mobile-cards="false" striped :data="[paie[year][month]['recap']]" :columns="Object.keys(paie[year][month]['recap']).map((x)=>({field: x,label: x,}))"></b-table>
                                    </div>
                                </template>
                            </template>
                        </b-menu-item>
                </b-menu-item>
            </b-menu-list>
          </b-menu>
        </div>
            

    </section>
</template>
<script>
export default {
    data(){
        return {
            paie:{},
            act:{},
            
        }
    },
    mounted(){
        let me=this
        me.paie=me.$store.paie.data
        me.$apis.load_fiche({},me.ficheCheck)
        //tri desc

        
    },
    computed:{
    },
    methods:{
        loadWholeYear(year){
            let me=this
            let n=-1
            let months=Object.keys(me.paie[year]).forEach( function(x,k)  { 
                
                if (!me.ficheMonthIsLoaded(year,x)) {
                    n=n+1
                setTimeout(function(){ 
                    me.loadFiche(year,x); 
                    //me.window.console.log(k,year,x)
                    }, (n*3000)); 
                } } )
        },

     total(x) {
            let me=this
            let tot=0
            
            tot=x.reduce(function(r,v){return r+parseFloat(v.split(' ').join('')) },0)
            
            return tot.toFixed(3)
        },
        saveToStore(){
            let me=this
            me.$store.paie.data=me.paie
            me.$store.paie.update=me.$misc.now
            me.$misc.save('store.paie',me.$store.paie)
        },
        ficheYearIsLoaded(year) {
            let me =this
            return me.paie && me.paie[year] && me.paie[year] && _.size(me.paie[year])>0
        },
        ficheMonthIsLoaded(year,month) {
            let me =this
            return me.paie && me.paie[year] && me.paie[year] && _.size(me.paie[year])>0 && _.size(me.paie[year][month]['recap'])>0
        },

        loadFicheYear(year){
            let me=this
            me.$apis.load_fiche({year:year},me.ficheCheck)
        },
        loadFiche(year,month){
            let me=this
            me.$apis.load_fiche({year:year,month:month},me.ficheLoaded)
        },
        ficheLoaded(resp){
            let me=this
            me.paie[resp.year][resp.month]=resp
            me.saveToStore()
        },
        ficheCheck(resp){
            let me = this
            resp.years.forEach(function(year){
                me.$set(me.paie,year,me.paie[year] || {})
            })
            resp.months.forEach(function(month){
                me.$set(me.paie[resp.year],month,me.paie[resp.year][month] || {})
            })
        me.saveToStore()
            
            
        },


    },
}        
</script>