<template>
  <div>
      <nav class="level is-mobile container" style="max-width: 380px" v-sticky="{ zIndex: 1, stickyTop: 60, disabled: false }">
        <div class="level-item has-text-centered" v-for="(day, kday) in [-2, -1, 0, 1, 2]" :key="kday" >
          <div @click="jumpDate(scrollto.plus({ day: day }).toFormat('y-LL-dd'))">
            <xx_calendarIcon
              :color="now.toFormat('y-LL-dd') == scrollto.plus({ day: day }).toFormat('y-LL-dd')? 'has-background-info-dark': _.find(opsdata,(leg)=>(scrollto.plus({ day: day }).toFormat('y-LL-dd')==$luxon.fromSQL(leg.hdep).toFormat('y-LL-dd')))?'has-background-info':'has-background-grey-light'"
              :class="{
                flou: day != 0,
                'animated infinite pulse':
                  now.toFormat('y-LL-dd') ==
                  scrollto.plus({ day: day }).toFormat('y-LL-dd'),
              }"
              :date="scrollto.plus({ day: day }).toFormat('y-LL-dd')"
              :full="day == 0"
            />
          </div>
        </div>
        <div class="has-text-info-dark pr-1">
          <diV @click="filter.active = true">
            <b-icon icon="filter" class=""></b-icon>
          </diV>
        </div>
        <div class="has-text-info pr-1">
          <diV @click="pickDay = true">
            <b-icon icon="calendar-month" class=""></b-icon>
          </diV>
          <div @click="$apis.load_ops_data({},opsLoaded)">
            <b-icon icon="reload" class=""></b-icon>
          </div>
        </div>
      </nav>

    <template v-if="(_.size(opsdata)>0) || (_.size(otherflts)>0)">
  <div class="table-container">
        <table style="line-height:1;  margin-left: auto;margin-right: auto;border-collapse: separate;">
            <template v-for="(leg,kleg) in opsdata">
                <div id="now" :class="{'selHour':(kleg!=0) && scrollto>$luxon.fromSQL(opsdata[kleg-1]['hdep']) && (scrollto<=$luxon.fromSQL(leg['hdep'])),}" :key="'sel'+kleg"
                 v-if="(kleg!=0) && scrollto>$luxon.fromSQL(opsdata[kleg-1]['hdep']) && (scrollto<=$luxon.fromSQL(leg['hdep']))"></div>
                <tr :key="kleg" :class="{
                    'activeday':scrollto.toFormat('yy-LL-dd')==$luxon.fromSQL(leg.hdep).toFormat('yy-LL-dd'),
                    }" @click="showFltInfo(leg)" v-if="_.size(filter.stations)==0 || (filter.deparr=='dep' && _.includes(filter.stations,leg.dep) ) || (filter.deparr=='arr' && _.includes(filter.stations,leg.des) ) || (filter.deparr=='deparr' && (_.includes(filter.stations,leg.dep) || _.includes(filter.stations,leg.des)) )">
                <td class="is-family-monospace is-size-7 p-1 verticalcenter"  >
                    <div style="font-size:0.60rem;" :class="{'has-text-info-dark has-text-weightbold':scrollto.toFormat('yy-LL-dd')==$luxon.fromSQL(leg.hdep).toFormat('yy-LL-dd')}">{{leg.hdep | luxon({ clientFormat: "ccc dd LLLyy", serverFormat: "sql" })}}</div>
                    <div class="has-text-weight-bold">
                        <span :class="{'has-text-danger-dark':!leg.departed && leg.eta_dep && ($luxon.fromSQL(leg.eta_dep).plus({minutes:escale_mini(leg)}) > $luxon.fromSQL(leg.hdep))}">{{leg.hdep | luxon({ clientFormat: "HHmm", serverFormat: "sql" })}}</span>-
                        <span :class="{'has-text-danger-dark':leg.state!='ARRIVED' && leg.eta_des && ($luxon.fromSQL(leg.eta_des) > $luxon.fromSQL(leg.hdes)) }">{{leg.hdes| luxon({ clientFormat: "HHmm", serverFormat: "sql" })}}</span>
                    </div>
                </td>
                <td class="verticalcenter is-size-7 has-text-weight-bold is-info p-1 has-text-centered is-family-code has-text-grey">
                    <div>{{leg.al}}{{leg.fnum}}</div>
                    <div  v-if="leg.LEG_TYPE!='J'"><span class="heading has-text-success mb-0" style="font-size:0.60rem">Non Rev</span></div>
                </td>
                <td class="verticalcenter is-size-7 has-text-weight-bold has-text-centered px-1 ">
                    <span class="has-text-weight-semibold">
                      <span v-if="leg.DEP_AP_SCHED!=leg.DEP_AP_ACTUAL" class="barre has-text-grey-light pl-1">{{leg.DEP_AP_SCHED}}</span>
                      <span>{{leg.DEP_AP_ACTUAL}}</span>
                      <span class="">-</span>
                      <span v-if="leg.ARR_AP_SCHED!=leg.ARR_AP_ACTUAL" class="barre has-text-grey-light">{{leg.ARR_AP_SCHED}}</span>
                      <span>{{leg.ARR_AP_ACTUAL}}</span>
                      </span>                    
                </td>
                <td class="verticalcenter px-1 has-text-centered">
                    <div class="is-size-7 has-text-success-dark  is-family-monospace">[{{leg.AC_REGISTRATION}}]</div>
                </td>
                <td class="verticalcenter px-1">
                    <div class="has-text-right">
                    <span class="has-text-weight-semibold px-1 has-text-white" style="font-size:0.60rem" :class="{'has-background-success':leg.state=='SCHED','has-background-success-dark':leg.state=='DELAYED','has-background-info':leg.state=='ARRIVED','has-background-danger':!_.includes(['SCHED','DELAYED','ARRIVED'],leg.state)}">{{leg.state}}</span>
                  </div>
                </td>
            </tr>
            </template>



            <template v-for="(leg,kleg) in otherflts">
                <tr :key="'others'+kleg" class="grise" :class="{
                    'activeday':scrollto.toFormat('yy-LL-dd')==$luxon.fromSQL(leg.hdep).toFormat('yy-LL-dd'),
                    }" @click="showFltInfo(leg)" v-if="_.size(filter.stations)==0 || (filter.deparr=='dep' && _.includes(filter.stations,leg.dep) ) || (filter.deparr=='arr' && _.includes(filter.stations,leg.des) ) || (filter.deparr=='deparr' && (_.includes(filter.stations,leg.dep) || _.includes(filter.stations,leg.des)) )">
                <td class="is-family-monospace is-size-7 p-1 verticalcenter"  >
                    <div style="font-size:0.60rem;" :class="{'has-text-info-dark has-text-weightbold':scrollto.toFormat('yy-LL-dd')==$luxon.fromSQL(leg.hdep).toFormat('yy-LL-dd')}">{{leg.hdep | luxon({ clientFormat: "ccc dd LLLyy", serverFormat: "sql" })}}</div>
                    <div class="has-text-weight-bold">
                        <span>{{leg.hdep | luxon({ clientFormat: "HHmm", serverFormat: "sql" })}}</span>-
                        <span>{{leg.hdes| luxon({ clientFormat: "HHmm", serverFormat: "sql" })}}</span>
                    </div>
                </td>
                <td class="verticalcenter is-size-7 has-text-weight-bold is-info p-1 has-text-centered is-family-code has-text-grey">
                    <div>{{leg.al}}{{leg.fnum}}</div>
                </td>
                <td class="verticalcenter is-size-7 has-text-weight-bold has-text-centered px-1 ">
                    <span class="has-text-weight-semibold">
                      <span>{{leg.dep}}</span>
                      <span class="">-</span>
                      <span>{{leg.des}}</span>
                      </span>                    
                </td>
                <td class="verticalcenter px-1 has-text-centered">
                    <div class="is-size-7 has-text-success-dark  is-family-monospace">[{{actype(leg.actype)}}]</div>
                </td>
                <td class="verticalcenter px-1">
                    <div class="has-text-right">
                    <span class="has-text-weight-semibold px-1 has-text-white has-background-success" style="font-size:0.60rem">SCHED</span>
                  </div>
                </td>
            </tr>
            </template>




        </table>
  </div>



        <b-modal v-model="filter.active">

                <div class="modal-card" style="width: auto">
                    <header class="modal-card-head"><p class="modal-card-title">OPS Flights Filter</p><button type="button" class="delete" @click="filter.active=false"/></header>
                    <section class="modal-card-body">
                        <b-field label="Station(s) (IATA code)">
                            <b-taginput
                                v-model="filter.stations"
                                ellipsis
                                icon="label"
                                placeholder="Add a station (eg: TUN)"
                                type="is-info"
                                :before-adding="function (tag) { return ((tag.length === 3)  )} "
                                :create-tag="(tagToAdd) => _.upperCase(tagToAdd)"
                                aria-close-label="Delete this station">
                            </b-taginput>
                        </b-field>

        <div class="field"><b-radio v-model="filter.deparr" size="is-small" native-value="deparr">DEP and ARR</b-radio></div>
        <div class="field"><b-radio v-model="filter.deparr" size="is-small" native-value="dep">Departure only</b-radio></div>
        <div class="field"><b-radio v-model="filter.deparr" size="is-small" native-value="arr">Arrival only</b-radio></div>

                    </section>
                    <footer class="modal-card-foot">
                        <button class="button" type="button" @click="filter.active=false;filter.stations=[];filter.deparr='deparr'">Clear Filter</button>
                        <button class="button is-primary" @click="filter.active=false">Activate Filter</button>
                    </footer>
                </div>
       

        </b-modal>    
    
    <div class="buttons p-2 stickbottom" v-if="_.size(filter.stations)>0">
      <b-button
        @click="filter.active=false;filter.stations=[];filter.deparr='deparr'"
        class="m-2 stickbottom is-danger-lite"
        size="is-small"
        icon-left="delete"
        >Clear filter: <b>{{ {dep:'DEP',arr:'ARR',deparr:'DEP & ARR'}[filter.deparr] }} - {{ filter.stations }}</b></b-button>
    </div>


    </template>

    <section class="hero is-medium is-primary is-bold" v-else>
      <div class="hero-body">
        <div class="container">
          <h1 class="title">Netline OPS</h1>
          <h2 class="subtitle">
            No Flights found...<b-button
              type="is-warning"
              @click="$apis.load_ops_data({},opsLoaded)"
              >Reload</b-button>
          </h2>
        </div>
      </div>
    </section>


    


    <b-modal v-model="pickDay">
      <form action="" class="container has-text-centered">
        <b-field label="Select a date">
          <b-datepicker
            inline
            trap-focus
            @input="(e) => {pickDay = false; jumpDate($luxon.fromJSDate(e).toFormat('y-LL-dd'));}">
          </b-datepicker>
        </b-field>
      </form>
    </b-modal>
  </div>
</template>


<script>


export default {
  
  created() {
    let me=this
    if (me.$store.default_filters && me.$store.default_filters.ops_stations) {me.filter.stations=me.$store.default_filters.ops_stations}
  //console.log(me.$store.default_filters)

    
    //me.selHour=me.now.toFormat('Y-LL-dd HH:mm:ss')
  },
  mounted() {
      let me=this
      me.$apis.load_ops_data({},me.opsLoaded)
      //me.myinterval= setInterval(function(){ if (me.autoscroll && !me.elementInViewport('now')) { me.scrollleg(); me.window.console.log('scrolled')}; }, 5000);
  },
  beforeDestroy() {
      //clearInterval(this.myinterval)
  },
  data() {
    return {
      filter: {
          active:false,
          stations:[],
          deparr:'deparr'
      },
      pickDay: false,
      selHour: null,
      
    };
  },
  methods: {

    jumpDate(date) {
      let me = this;
      //console.log(date)
      me.$apis.load_ops_data({flt:{date:me.$luxon.fromSQL(date).toFormat('y-LL-dd')}},function(){
        me.selHour=me.$luxon.fromSQL(date).toFormat('y-LL-dd')
        me.scrollleg()
        })
    },
    opsLoaded() {
      let me = this;
        setTimeout(() => {
         me.scrollleg(me.flt);
        }, 700);

    },
    scrollleg() {
      let me=this
      setTimeout(function()  {
        me.document.getElementsByClassName("selHour")[0].scrollIntoView({behavior: "smooth", block: "center", inline: "nearest"});

        /*me.$scrollTo(".selHour", 700, { 
            offset: -250,           
            container:
              window.document.getElementsByClassName("vuescrollto").length > 0
                ? ".vuescrollto"
                : "body",
          })*/
      },500)

    },
  },
  
  computed: {
    now(){
        return this.$helper.now
    },
    scrollto(){
        let me=this
        let tot=null
        if (this.selHour) {
            tot= this.$luxon.fromSQL(this.selHour) 
        } else { 
            tot= this.now
        }
        return tot
    },
    opsdata(){
        let me=this
        return me.$store.ops.data.filter((leg)=>( me.$luxon.fromSQL(leg.hdep)> me.$luxon.fromSQL(me.selHour || me.$luxon.utc().toFormat('y-LL-dd') ).minus({day:2}) ))
    },
    maxdate(){
      let me=this
      //me.window.console.log(me.opsdata)
       return _.last(_.sortBy(me.$store.ops.data,(function(x) {return x.hdep}))).hdep
    },
    otherflts(){
      let me=this
      return Object.values(_.groupBy(me.$store.crew.dataraw.filter((x)=>(x.type=='L')),function(flt){return flt.hdep+flt.al+flt.fnum+flt.dep+flt.des}))
                .map((flt)=>(flt[0]))
                .filter(function(f){return me.$luxon.fromSQL(f.hdep)>me.$luxon.fromSQL(me.maxdate)})
    }

  },
  props: {
  },
//  components: { xx_calendarIcon },
};
</script>
<style scoped>
.activeday {
    background-color: khaki;
}
.grise {
    background-color: rgb(232,232,232);
}
</style>
