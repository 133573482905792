<template>

    <section>

    <b-table class="is-size-7" :data="$store.crew.dataraw.filter((leg)=>((leg.label==duty.label) && (leg.hdep==duty.hdep))).map((leg)=>( {tlc:leg.tlc} )).sort(collegesort)" is-stripped is-narrowed :mobile-cards="false">
            <b-table-column label="Last Name" sortable v-slot="props">
                    {{ _.capitalize($store.qualif.data[props.row.tlc].lname) }}
            </b-table-column>
            <b-table-column label="First Name" sortable v-slot="props">
                    {{ _.capitalize($store.qualif.data[props.row.tlc].fname) }}
            </b-table-column>
            <b-table-column field="tlc" label="Mle" sortable v-slot="props">
                    <span class="is-size-8 is-family-monospace px-1 has-background-grey has-text-white">{{ props.row.tlc }}</span>
            </b-table-column>
            <b-table-column field="base" label="Base" sortable v-slot="props">
                    <span class="has-text-info-dark is-size-8">{{ $store.qualif.data[props.row.tlc].base }}</span>
            </b-table-column>
            <b-table-column field="qualif" label="qualif" sortable v-slot="props">
                    <b-tooltip :label="qualifarray(props.row.tlc).join()" size="is-small" position="is-left" multilined>
                        <b-icon icon="information-outline" size="is-small"></b-icon>
                    </b-tooltip>
            </b-table-column>
    </b-table>
 




    </section>


    
    
</template>

<script>
export default {
  created() {
    let me = this;


  },
  mounted(){
      let me=this
    
    me.$apis.load_qualif_data({})
    
    me.$apis.load_crew_data({})

  },
  data() {
    return {

    };
  },
  props: {
    duty: {},
  },

  methods: {

    college(tlc){
              let me = this;
       let t={CP:1,FO:2,FE:3,PU:4,SE:5,ST:6,JU:7,XX:99}
       if (_.isNull(me.$store.qualif.data[tlc] || null) ) {return 99}
       let college= me.$store.qualif.data[tlc].qualif.reduce((r,q)=>(r=((t[q.college] || 99)<(t[r] || 99))?(q.college || 'XX'):r),'XX')
       return {college:college,val:t[college] || 99}
    },
    collegesort(tlca,tlcb){
              let me = this;
        return ((me.college(tlca.tlc).val-me.college(tlcb.tlc).val)<0)?-1:(((me.college(tlca.tlc).val-me.college(tlcb.tlc).val)==0)?(   me.comparenames(tlca.tlc,tlcb.tlc)     ):1)
    },
    comparenames(tlca,tlcb) {
        let me=this
        let qualifa=me.$store.qualif.data[tlca] 
        let qualifb=me.$store.qualif.data[tlcb] 
        return ((qualifa.lname)<(qualifb.lname))?-1:(((qualifa.lname)==(qualifb.lname))?0:1)
    }
  },
};
</script>