import store from './store';
import helper from './helper';
import misc from './misc';
import axios from 'axios';
import { mapValues, groupBy,isEqual,uniq,orderBy,find } from 'lodash';
import { SnackbarProgrammatic as Snackbar } from 'buefy'
import { DialogProgrammatic as Dialog } from 'buefy'
import {DateTime} from "luxon";

const me= {

    snack(snackdata={}) {

        Snackbar.open({
            duration: snackdata.duration || 5000,
            message: snackdata.message || 'message',
            type: snackdata.type || 'is-info',
            position: snackdata.position || 'is-bottom',
            actionText: snackdata.actionText || 'OK',
            queue: !snackdata.queue,
            onAction: () => {
                if (typeof (snackdata.onAction || null) == "function") {snackdata.onAction()}
            }
        })
    },

    getip(callback){
        fetch('https://api.ipify.org?format=json')
        .then(response => response.json())
        .then(function(data) {if ((data && data.ip && typeof callback == "function")) {callback(data.ip)}})
        .catch(function (error) {
                console.log(error);
            })
    },

    __load_data(request={},traite,loadingdata={}){

        //let me=this
        //console.log(helper.loading)
        
        if (helper.online ) {
        let loading=Object.assign({},{full:true,cancel:false,msg: 'Loading...',url: request.url || 'unknownurl',id: (new Date()).getTime() },loadingdata)
        helper.loading.push(loading)
        axios(Object.assign({},request,{headers: {'Authorization': 'Bearer '+store.token,'ip':helper.ip}}))
            .then(function (response) {
                let resp=response.data
                if (resp.message) {me.snack({message: (resp.message || "Unknown network error...")})}
                if (( !request.url.endsWith('logout') && resp.type && resp.type == "unknown_token")) { console.log("UNKNOWN TOKEN!!!");me.logout({},function(){misc.clear();store.token=null}) }
                if ((resp.data && typeof traite == "function")) {traite(resp)}
            })
            .catch(function (error) {
                me.snack({type:'is-danger',message: (error.message || "Unknown network error...")})
                console.log(error);
            })
            .then(function () {
                let i= helper.loading.findIndex((x)=>(isEqual(x,loading)))
                if (i>=0) {
                    helper.loading.splice(i,1)
                } else {
                    console.log("Loading: loading item not found in array...")
                }
            });
        } else {
            //.snack({queue: false,position:'is-top',type:'is-info',message: ("You're OFFLINE, using cached data...")})
        }
    },


    load_abilities(params={},loaded=null) {
        //let me=this
        me.__load_data(
            {method: 'post',url:'api/user/abilities',data: Object.assign({ },params)},
            function (resp) {
                //console.log(resp);
                if (resp.success && resp.data) {
                    if (resp.data.abilities) {
                        store.abilities=Object.values(resp.data.abilities)
                        misc.save('store.abilities',store.abilities)
                    }
                    if (resp.data.defaults) {
                        store.default_filters=resp.data.defaults
                        misc.save('store.default_filters',store.default_filters)
                    }
                    if (typeof loaded == "function") {loaded(resp || null)}
                }
            },
            {msg:"Login (bis) in progress...",hidden:true}
            )

    },


    login(params={},loaded=null) {
        //let me=this
        me.__load_data(
            {method: 'post',url:'api/login',data: Object.assign({ },params)},
            function (resp) {
                //console.log(resp);
                if (resp.success && resp.data && resp.data.token) {
                    store.token=resp.data.token
                    store.abilities=Object.values(resp.data.abilities)
                    //save to ls
                    misc.save('store.token',store.token)
                    misc.save('store.abilities',store.abilities)
                    if (typeof loaded == "function") {loaded(resp || null)}
                } else if ((resp.type && resp.type == "max_tokens")) { 
                    Dialog.confirm({
                        title: 'Too many devices',
                        message: 'Too many devices are connected to the server. If you accept, this device will be approved and ALL other devices will be logged out. You will be prevented from logging in from any other devices for few days.',
                        confirmText: 'I understand, Let me in',
                        type: 'is-danger',
                        hasIcon: true,
                        onConfirm: function()  {
                            me.login( Object.assign(params,{'delete_old_tokens':true}),loaded)
                            me.snack({message: "All other devices were logged out...This device is approved..." })
                        }
                    })
                } else {
                    // me.snack({message: "Unable to login... Try again later..." })
                }

                
            },
            {msg:"Login in progress..."}
            )

    },
    logout(params={},loaded=null) {
        //let me=this
        me.__load_data(
            {method: 'post',url:'api/logout',data: Object.assign({ },params)},
            function (resp) {
                if (resp.success) {
                    store.token=null
                    misc.clear(/*'store.token'*/)
                } else {
                     //me.snack({message: "An error occured... Try again later..." })
                }

                if (typeof loaded == "function") {loaded(resp || null)}
                
            },
            {msg:"Logout in progress..."}
            )

    },


    load_qualif_data(params={},loaded=null) {
        //let me=this
        me.__load_data(
            {method: 'post',url:'api/qualif/data',data: Object.assign({ver: (store.qualif.ver || null) },params)},
            function (resp) {
                if (resp.data.licences) {
                store.qualif.ver= resp.data.ver
                store.qualif.data= mapValues(groupBy(resp.data.licences,(q)=>(q.tlc)),(pn)=>({tlc:pn[0].tlc,fname:pn[0].fname,mname:pn[0].mname,lname:pn[0].lname,base:pn[0].base,qualif:pn.map((line)=>({college:line.college,ac:line.ac}))}))
                misc.save('store.qualif',store.qualif)

                }
                if (typeof loaded == "function") {loaded(resp || null)}
            },
            {msg:"Importing CREW Qualifications...",hidden:true}
            )
    },
    
    load_crew_data(params={},loaded=null) {
        //let me=this
        me.__load_data(
            {method: 'post',url:'api/crew/data',data: Object.assign({ver: (store.crew.ver || null) },params)},
            function (resp) {
                if (resp.data.crewdata) {
                let datehold = DateTime.utc().startOf("day").minus({ days: 60 });
                resp.data.crewdata = orderBy(resp.data.crewdata,(leg)=>(leg.hdep));
         
                let dates={min:null,max:null}
                let actypes=[]
                let out=
                {
                  ver : resp.data.ver,
                  dataraw : resp.data.crewdata.filter((leg) => DateTime.fromSQL(leg.hdep) >= datehold),
                  data : resp.data.crewdata.reduce(function(r,leg){
                    let date=DateTime.fromSQL(leg.hdep)
                    dates.min=DateTime.min(date,(dates.min || date))
                    dates.max=DateTime.max(date || (dates.max || date))
                    if (leg.actype) { actypes.push(leg.actype);actypes=uniq(actypes)}
                    r[leg.tlc]=r[leg.tlc] || {}
                    if (date >= datehold) {
                      r[leg.tlc][date.toFormat('y-LL-dd')]=(r[leg.tlc][date.toFormat('y-LL-dd')] || []).concat(leg)
                    }
                    return r
                  },{}),
                  tlc_active : uniq(resp.data.crewdata.map((leg)=>leg.tlc))
                }
                out.dates={min:dates.min.toFormat('y-LL-dd'),max:dates.max.toFormat('y-LL-dd')}
                out.actypes=actypes
                store.crew=out
                misc.save('store.crew',store.crew)

            }
                if (typeof loaded == "function") {loaded(resp || null)}
                
            },
            {msg:"Importing CREW Rosters...",hidden:true}
            )
    },


    load_ops_data(params={},loaded=null) {
        //let me=this
        me.__load_data(
            {method: 'post',url:'api/ops/data',data: Object.assign({ver: (store.ops.ver || null) },params)},
            function (resp) {
                //console.log(resp)
                if (resp.data.opsdata) {
                let datehold = DateTime.utc().startOf("day").minus({ days: 60 });
                resp.data.opsdata = orderBy(resp.data.opsdata,(leg)=>(leg.hdep));
                store.ops.ver = resp.data.ver;
                store.ops.data = 
                  resp.data.opsdata.filter(
                  (leg) => DateTime.fromSQL(leg.hdep) >= datehold
                );
          
                //check destination station = departure station next leg
          
                store.ops.data.forEach(function(leg,k) {
                  let x=find(store.ops.dat,{AC_REGISTRATION:leg.AC_REGISTRATION},k+1)
                  let y=find(store.ops.data,{AC_REGISTRATION:leg.AC_REGISTRATION,dep: leg.des},k+1)
                  if (x && y && x.status!="ARRIVED" && x==y) {
                    let index=store.ops.data.findIndex(x,k+1)
                    let eta_dep=DateTime.fromSQL(leg.eta_des || leg.hdes)
                    let eta_des=DateTime.max(eta_dep.plus({minutes: me.escale_mini(x)}),DateTime.fromSQL(x.hdep)).plus({minutes: x.flttime})
                    if (!x.departed) {(me.$store.ops.data[index]["eta_dep"]=eta_dep.toFormat('y-LL-dd HH:mm:00'))}
                    (me.$store.ops.data[index]["eta_des"]=eta_des.toFormat('y-LL-dd HH:mm:00'))
                  }
                })
                misc.save('store.ops',store.ops)

            }
                if (typeof loaded == "function") {loaded(resp.data || null)}
            },
            {msg:"Importing OPS Data...",hidden:true}
            )
    },

    load_wx_taf(params={},loaded=null) {
        //let me=this
        me.__load_data(
            {method: 'post',url:'api/wx/taf',data: Object.assign({},params)},
            function (resp) {
                if (typeof loaded == "function") {loaded(resp.data || null)}
            },
            {msg:"Retrieving Taf & Metar of "+(params.code || '???')+" ..."}
            )
    },
    

    load_crew_month(params={},loaded=null) {
        //let me=this
        me.__load_data(
            {method: 'post',url:'api/crew/roster',data: Object.assign({},params)},
            function (resp) {
                if (resp.success && resp.data) {
                    if (typeof loaded == "function") {loaded(resp.data || null)}
                    misc.save('store.crewlink',store.crewlink)
                }
 
            },
            {msg:"Importing Roster from CrewLink...",hidden:store.crewlink.roster.length!=0}
            )
    },
 
    roster_confirm(params={},loaded=null) {
        //let me=this
        me.__load_data(
            {method: 'post',url:'/api/crew/roster/changed/confirm',data: Object.assign({},params)},
            function (resp) {
                
                    if (typeof loaded == "function") {loaded(resp.data || null)}
 
            },
            {msg:"confirming Roster changes..."}
            )
    },
    


    load_crew_flts(params={},loaded=null) {
        //let me=this
        me.__load_data(
            {method: 'post',url:'api/crew/flts',data: Object.assign({},params)},
            function (resp) {
                if (resp.success && resp.data && resp.data.flts) {
                    if (typeof loaded == "function") {loaded(resp.data || null)}
                }
                misc.save('store.flts',store.flts)
            },
            {msg:"Importing Flights from CrewLink..."}
            )
    },
 

    load_crew_list(params={},loaded=null) {
        //let me=this
        me.__load_data(
            {method: 'post',url:'api/crew/list',data: Object.assign({},params)},
            function (resp) {
                if (resp.success && resp.data) {
                    if (typeof loaded == "function") {loaded(resp.data || null)}
                }
                

            },
            {msg:"Retrieving Crew List from CrewLink...",hidden:true}
            )
    },

    
    load_ofp_list(params={},loaded=null) {
        //let me=this
        me.__load_data(
            {method: 'post',url:'api/ofp/list',data: Object.assign({},params)},
            
            function (resp) {
                if (resp.success && resp.data) {
                    if (typeof loaded == "function") {loaded(resp.data || null)}
                }
            },
            {msg:"Retrieving OFPs List from Lido..."}
            )
    },

    
    load_ofp_data(params={},loaded=null) {
        //let me=this
        me.__load_data(
            {method: 'post',url:'api/ofp/data',data: Object.assign({},params)},
            
            function (resp) {
                if (resp.success && resp.data) {
                    if (typeof loaded == "function") {loaded(resp.data || null)}
                }
            },
            {msg:"Retrieving OFPs List from Lido..."}
            )
    },

    load_fiche(params={},loaded=null) {
        //let me=this
        me.__load_data(
            {method: 'post',url:'api/paie/fiche',data: Object.assign({},params)},
            
            function (resp) {
                if (resp.success && resp.data) {
                    if (typeof loaded == "function") {loaded(resp.data || null)}
                }
            },
            {msg:"Retrieving FICHE DE PAIE..."}
            )
    },

    load_act(params={},loaded=null) {
        //let me=this
        me.__load_data(
            {method: 'post',url:'api/paie/act',data: Object.assign({},params)},
            
            function (resp) {
                if (resp.success && resp.data) {
                    if (typeof loaded == "function") {loaded(resp.data || null)}
                }
            },
            {msg:"Retrieving FICHE D'ACTIVITE'..."}
            )
    },

    load_docpn_docs(params={},loaded=null) {
        //let me=this
        me.__load_data(
            {method: 'post',url:'api/docpn/docs',data: Object.assign({},params)},
            
            function (resp) {
                if (resp.success && resp.data) {
                    if (resp.data.docs && resp.data.docs.length>0) {
                        store.docs.list=resp.data.docs
                        store.docs.details=store.docs.details.filter((x)=>(store.docs.list.map((y)=>(y.link)).includes(x.link || "")))
                        misc.save('store.docs',store.docs)
                    }
                    if (typeof loaded == "function") {loaded(resp.data || null)}
                }
            },
            {msg:"Retrieving List of Documents..."}
            )
    },

    load_docpn_docinfo(params={},loaded=null) {
        //let me=this
        me.__load_data(
            {method: 'post',url:'api/docpn/docinfo',data: Object.assign({},params)},
            
            function (resp) {
                if (resp.success && resp.data) {

                        store.docs.details=store.docs.details.filter((x)=>(x.link!=resp.data.link))
                        store.docs.details.push(resp.data)
                        misc.save('store.docs',store.docs)
                    if (typeof loaded == "function") {loaded(resp.data || null)}
                }
            },
            {msg:"Retrieving Documents infos..."}
            )
    },

}
export default me