<template>
  <div
    class="columns is-vcentered is-mobile is-gapless"
    v-if="duty.type == 'flt'"
    style="margin: 0"
    @click="showFltInfo(duty.data)"
  >
    <span class="column is-narrow mr-1">
      <span class="is-family-monospace has-text-weight-bold is-size-7">{{
        last ? "----" : $luxon.fromSQL(duty.data.hdep).toFormat("HHmm")
      }}</span>
      <span class="pl-0 ml-0 is-family-monospace is-size-7">{{
        $luxon.fromSQL(duty.data.hdes).toFormat("HHmm")
      }}</span>
    </span>
    <span class="column is-narrow mr-1">
      <b-taglist attached>
        <b-tag type="is-info is-light px-1"
          >{{ duty.data.al }}{{ duty.data.fnum }}
          <small
            class="has-text-weight-light has-text-link"
            v-if="duty.data.fltday"
          >
            /{{ duty.data.fltday }}</small
          ></b-tag
        >
        <b-tag type="is-info px-1 has-text-weight-semibold"
          >{{ duty.data.dep }}-{{ duty.data.des }}</b-tag
        >
      </b-taglist>
    </span>
    <span class="column is-narrow mr-1">
      <b-taglist
        ><b-tag class="px-1 has-text-weight-light is-size-7 is-light">{{
          duty.data.ac
        }}</b-tag></b-taglist
      >
    </span>

    <span class="column has-text-right">
      <div class="is-size-7">
        <small>{{ duty.data.label }}</small>
      </div>
    </span>
  </div>

  <div
    class="columns is-vcentered is-mobile is-gapless"
    v-else-if="duty.type == 'dh'"
    style="margin: 0"
    @click="showFltInfo(duty.data)"
  >
    <span class="column is-narrow mr-1">
      <span class="is-family-monospace has-text-weight-bold is-size-7">{{
        last ? "----" : $luxon.fromSQL(duty.data.hdep).toFormat("HHmm")
      }}</span>
      <span class="is-family-monospace is-size-7">{{
        $luxon.fromSQL(duty.data.hdes).toFormat("HHmm")
      }}</span>
    </span>
    <span class="column is-narrow mr-1">
      <b-taglist attached>
        <b-tag type="is-info is-light"
          >DH {{ duty.data.al }}{{ duty.data.fnum }}
          <small
            class="has-text-weight-light has-text-link px-1"
            v-if="duty.data.fltday"
          >
            /{{ duty.data.fltday }}</small
          ></b-tag
        >
        <b-tag type="is-primary px-1 has-text-weight-semibold"
          >{{ duty.data.dep }}-{{ duty.data.des }}</b-tag
        >
      </b-taglist>
    </span>

    <span class="column has-text-right">
      <div class="is-size-7">
        <small>{{ duty.data.label }}</small>
      </div>
    </span>
  </div>

  <div
    class="columns is-vcentered is-mobile is-gapless"
    v-else-if="duty.type == 'dhlimo'"
    style="margin: 0"
    @click="showDutyInfo({dep:duty.data.dep,des:duty.data.des,hdep:duty.data.hdep,hdes:duty.data.hdes})"
  >
    <span class="column is-narrow mr-1">
      <span class="is-family-monospace has-text-weight-bold is-size-7">{{
        last ? "----" : $luxon.fromSQL(duty.data.hdep).toFormat("HHmm")
      }}</span>
      <span class="is-family-monospace is-size-7">{{
        $luxon.fromSQL(duty.data.hdes).toFormat("HHmm")
      }}</span>
    </span>
    <span class="column is-narrow mr-1">
      <b-taglist attached>
        <b-tag type="is-info is-light px-1">DH limo</b-tag>
        <b-tag type="is-primary px-1 has-text-weight-semibold"
          >{{ duty.data.dep }}-{{ duty.data.des }}</b-tag
        >
      </b-taglist>
    </span>
    <span class="column has-text-right">
      <div class="is-size-7">
        <small>{{ duty.data.label }}</small>
      </div>
    </span>
  </div>

  <div
    class="columns is-vcentered is-mobile is-gapless"
    v-else-if="duty.type == 'sim'"
    style="margin: 0"
     @click="showDutyInfo({dep:duty.data.place,hdep:duty.data.start,hdes:duty.data.end,label:duty.data.label})"
  >
    <span class="column is-narrow mr-1">
      <span class="is-family-monospace has-text-weight-bold is-size-7">{{
        $luxon.fromSQL(duty.data.start).toFormat("HHmm")
      }}</span>
      <span class="is-family-monospace is-size-7">{{
        $luxon.fromSQL(duty.data.end).toFormat("HHmm")
      }}</span>
    </span>
    <span class="column is-narrow mr-1">
      <b-taglist attached>
        <b-tag type="is-success is-light px-1 is-size-6">SIMU</b-tag>
        <b-tag type="is-success px-1 has-text-weight-semibold">{{
          duty.data.place
        }}</b-tag>
      </b-taglist>
    </span>
    <span class="column is-narrow mr-1">
      <b-taglist
        ><b-tag type="has-text-weight-light is-size-7 is-light px-1">{{
          duty.data.ac
        }}</b-tag></b-taglist
      >
    </span>
    <span class="column has-text-right">
      <div class="is-size-7">
        <small>{{ duty.data.label }}</small>
      </div>
    </span>
  </div>

  <div
    class="columns is-vcentered is-mobile is-gapless"
    v-else-if="
      duty.type == 'wholeday' ||
      (duty.data && duty.data.label && duty.data.label == 'CA')
    "
    style="margin: 0"
  >
    <span class="column is-narrow mr-1">
      <span
        :class="{
          'has-text-grey-light': duty.data.label == 'OFF',
          'has-text-grey': duty.data.label != 'OFF',
        }"
        class="is-size-4 px-1 has-text-weight-semibold is-family-secondary"
        >{{ duty.data.label }}</span
      >
      <span class="has-text-grey-light is-size-6 px-1 has-text-weight-light">{{
        duty.data.place
      }}</span>
    </span>
    <span class="column has-text-right is-size-7">
      {{ duty.data.desc }}
    </span>
  </div>

  <div
    class="columns is-vcentered is-mobile is-gapless"
    v-else-if="duty.type == 'ground'"
    style="margin: 0"
     @click="showDutyInfo({dep:duty.data.place,hdep:duty.data.start,hdes:duty.data.end,label:duty.data.label,tlc:$store.crewlink.mle})"
  >
    <span class="column is-narrow mr-1">
      <span class="is-family-monospace has-text-weight-bold is-size-7">{{
        $luxon.fromSQL(duty.data.start).toFormat("HHmm")
      }}</span>
      <span class="is-family-monospace is-size-7">{{
        $luxon.fromSQL(duty.data.end).toFormat("HHmm")
      }}</span>
    </span>
    <span class="column is-narrow mr-1">
      <b-taglist attached>
        <b-tag type="is-success is-light px-1" size="is-medium">{{
          duty.data.label
        }}</b-tag>
        <b-tag type="is-success px-1 has-text-weight-semibold">{{
          duty.data.place
        }}</b-tag>
      </b-taglist>
    </span>
    <span class="column has-text-right">
      <div class="is-size-7">
        <small>{{ duty.data.desc }}</small>
      </div>
    </span>
  </div>

  <div
    class="columns is-vcentered is-mobile is-gapless"
    v-else-if="duty.type == 'notification'"
    style="margin: 0"
  >
    <span class="column mr-1 pb-2 mx-3 buttons">
      <b-button
        expanded
        type="is-danger"
        size="medium"
        icon-right="file-pdf"
        tag="a"
         :href="'api/crew/roster/changed?'+'&token='+encodeURI($store.token)"
        target="_blank"
        @click="showNotif"
      >
        CHANGED
      </b-button>
      <b-button
        v-if="confirmVisible"
        expanded
        size="small"
        icon-right="comment-question-outline"
        @click="confirmChanges()"
        >Confirm roster change?</b-button
      >
      <b-loading :is-full-page="true" :can-cancel="false" :active="wip" />
    </span>
  </div>

  <div
    class="columns is-vcentered is-mobile is-gapless"
    v-else
    style="margin: 0"
  >
    <span class="column has-text-centred">
      <div class="is-size-7">
        <small>{{ duty.UNKNOWN }}</small>
      </div>
    </span>
  </div>
</template>
<script>
export default {
  data: () => ({
    wip: false,
    confirmVisible: false,
  }),
  props: {
    duty: { default: {} },
    last: { default: false },
  },
  methods: {
    showNotif() {
      let me = this;
      me.confirmVisible = true;
      setTimeout(() => {
        me.confirmVisible = false;
      }, 120000);
    },
    confirmChanges() {
      let me = this;
      this.$buefy.dialog.confirm({
        title: "Roster has changed",
        message:
          "Are you sure you want to <b>CONFIRM</b> the notification? This action cannot be undone.",
        confirmText: "Approve and confirm",
        type: "is-danger",
        hasIcon: true,
        onCancel() {
          me.confirmVisible = false;
        },
        onConfirm() {
          //me.wip = true;
          me.$apis.roster_confirm({},function(resp){
              me.confirmVisible = false;
              me.$buefy.toast.open({
                message: "Roster changes were approved!",
                position: "is-bottom",
                type: "is-success",
              });

          })
          /*
          me.$axios
            .post("/api/crew/roster/changed/confirm")
            .then(function (response) {
              me.confirmVisible = false;
              me.$buefy.toast.open({
                message: "Roster changes was approved!",
                position: "is-bottom",
                type: "is-success",
              });
            })
            .catch(function (error) {
              me.$buefy.toast.open({
                message: "An error has occured!",
                position: "is-bottom",
                type: "is-danger",
              });
            })
            .finally(function () {
              me.wip = false;
            });
            */

        },
      });
    },
  },
};
</script>