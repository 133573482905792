<template>
  <section>

    
    <div class="is-size-7 has-text-grey-light has-text-right pb-3">UPDTATED: {{ $store.ops.ver }}</div>

    <h3 class="subtitle" v-if="_.size(fltops)>1">found {{ _.size(fltops) }} Flight(s)</h3>

    <template v-if="_.size(fltops) > 0">
      <b-collapse class="card" animation="slide" v-for="(flt, index) of fltops" :key="index" :open="isOpen == index" @open="isOpen = index" >
        <div slot="trigger" slot-scope="props" class="card-header" role="button" >
          <div class="columns mt-0 mb-0 pt-0 pb-0 pl-3 pr-0 is-mobile card-header-title" >
            <div class="column pl-3 has-text-weight-bold is-size-7"></div>
            <div class="column is-narrow has-text-left has-text-grey is-family-monospace" >
              {{flt.dep}}-{{flt.des}}
            </div>

            <div class="column is-narrow has-text-right has-text-grey" >
              SCHEDULE
            </div>
            <div class="column is-narrow has-text-right">
              <div class="has-text-grey px-1 is-size-7">
                {{ $luxon.fromSQL(flt.DEP_SCHED_DT).toFormat("ccc dd LLLyy") }}
              </div>
              <div class="px-1 is-dark has-text-weight-semibold">
                {{ $luxon.fromSQL(flt.DEP_SCHED_DT).toFormat("HHmm") }}-{{
                  $luxon.fromSQL(flt.ARR_SCHED_DT).toFormat("HHmm")
                }}
              </div>
            </div>
          </div>
          <a class="card-header-icon" v-if="_.size(fltops) > 1">
            <b-icon :icon="props.open ? 'menu-down' : 'menu-up'"> </b-icon>
          </a>
        </div>
        <div class="card-content p-0 m-0">
          <div class="content">
            <div class="box mt-2 pt-1">
           


              <nav class="level is-mobile mb-2">
                <div class="level-item has-text-centered">
                  <div class="has-background-light">
                    <span class="heading mb-0">Flight Num</span>
                    <span class="is-size-5 has-text-weight-semibold">{{flt.al}}{{flt.fnum}}</span>
                    <span class="heading has-text-success mb-0" v-if="flt.LEG_TYPE!='J'">NON REV. FLT</span>
                  </div>
                </div>
                 <div class="level-item has-text-centered">
                  <div>
                    <span class="heading mb-0">state</span>
                    <span class="heading is-size-7 has-text-weight-semibold px-2 tag is-success" :class="{'has-background-success':flt.state=='SCHED','has-background-success-dark':flt.state=='DELAYED','has-background-info':flt.state=='ARRIVED','has-background-danger':!_.includes(['SCHED','DELAYED','ARRIVED'],flt.state)}">{{flt.state}}</span>
                    <span class="heading is-size-7 has-text-weight-semibold px-2 tag is-danger animated infinite pulse" v-if="flt.slot">CTOT {{flt.slot}}</span>
                  </div>
                </div>
                <div class="level-item has-text-centered" v-if="flt.DEP_DT_EST && !flt.departed">
                  <div>
                    <span class="heading mb-1 px-1 animated infinite pulse has-background-success-dark has-text-white">DELAYED</span>
                    <span class="heading mb-1 px-1 pb-0 mb-0 is-size-7 has-text-weight-bold">{{flt.DEP_DT_EST | luxon({ clientFormat: "HHmm", serverFormat: "sql" })}}<template v-if="flt.ARR_DT_EST">-{{flt.ARR_DT_EST | luxon({ clientFormat: "HHmm", serverFormat: "sql" })}}</template></span>
                    <span  class="heading mb-1 px-1 is-family-monospace">{{flt.DEP_DT_EST | luxon({ clientFormat: "ccc ddLLLyy", serverFormat: "sql" })}}</span>
                  </div>
                </div>
              </nav>

              <nav class="level is-mobile mb-2">
                <div class="level-item has-text-centered has-background-light" v-for="(delay,kdelay) in flt.delay" :key="kdelay" @click="$buefy.dialog.alert({title: 'Delay code meaning',message:'<div><b>'+(delay.code || '')+':</b><br><span>'+(delay.codeexp || '')+'</span></div>'+'<div><b>'+(delay.subcode || '')+'</b><br><span>'+(delay.subcodeexp || '')+'</span></div>'})">
                  <div>
                    <span class="heading is-capitalized mb-0">Delay-{{kdelay+1}}:</span>
                    <span class="heading pl-1 mb-0 has-text-weight-bold" v-if="delay.code || delay.subcode">code <template v-if="delay.code">{{delay.code}}</template><template v-if="delay.subcode"> ({{delay.subcode}})</template></span>
                    <span class="heading pl-1 mb-0 has-text-weight-bold" v-if="delay.time">{{$luxonDuration.fromObject({minutes: delay.time}).toFormat('h:mm')}}</span>
                  </div>
                  <div>
                  </div>
                </div>
              </nav>


              <nav class="level is-mobile mb-2">
                <div class="level-item has-text-centered has-background-light" v-if="flt.EET">
                  <div>
                    <span class="heading mb-0">EET</span>
                    <span class="is-size-6 has-text-weight-semibold px-2 has-text-grey-light">{{$luxonDuration.fromObject({minutes: flt.EET}).toFormat('h:mm')}}</span>
                  </div>
                </div>
                <div class="level-item has-text-centered">
                  <div class="has-background-light">
                    <span class="heading mb-0">City pair</span>
                    <span class="is-size-5 has-text-weight-semibold px-2">
                      <span v-if="flt.DEP_AP_SCHED!=flt.DEP_AP_ACTUAL" class="barre has-text-grey-light pl-1">{{flt.DEP_AP_SCHED}}</span>
                      <span>{{flt.DEP_AP_ACTUAL}}</span>
                      <span class="px-1">-</span>
                      <span v-if="flt.ARR_AP_SCHED!=flt.ARR_AP_ACTUAL" class="barre has-text-grey-light">{{flt.ARR_AP_SCHED}}</span>
                      <span>{{flt.ARR_AP_ACTUAL}}</span>
                      </span>
                  </div>
                </div>
              </nav>

              <nav class="level is-mobile mb-2" v-if="flt.state!='ARRIVED'">
                <div class="level-item has-text-centered" v-show="flt.eta_dep">
                  <div class="has-background-light">
                  <span class="heading is-capitalized mb-0 pb-0">A/C arrival to {{flt.dep}}</span>
                  <span class="heading is-capitalized mb-0 pb-0 has-text-weight-bold" v-if="flt.eta_dep"><template v-if="$luxon.fromSQL(flt.eta_dep).toFormat('y-LL-dd')!=$luxon.fromSQL(flt.DEP_SCHED_DT).toFormat('y-LL-dd')">{{ flt.eta_dep | luxon({ clientFormat: "ccc ddLLL ", serverFormat: "sql" })}}</template>{{ flt.eta_dep | luxon({ clientFormat: "HHmm", serverFormat: "sql" })}}</span>
                  <span class="heading has-text-danger is-size-7 is-capitalized has-text-weight-bold" v-if="flt.eta_dep && ($luxon.fromSQL(flt.eta_dep).plus({minutes:escale_mini(flt)}) > $luxon.fromSQL(flt.hdep))">Short Turnaround {{ ($luxonInterval.fromDateTimes($luxon.fromSQL(flt.eta_dep), $luxon.fromSQL(flt.hdep)).toDuration()).toFormat('h:mm') }}</span>
                  </div>
                </div>
                <div class="level-item has-text-centered" v-show="flt.eta_des">
                  <div class="has-background-light">
                  <span class="heading is-capitalized mb-0 pb-0">Exp arrival to {{flt.des}}</span>
                  <span class="heading is-capitalized mb-0 pb-0 has-text-weight-bold" v-if="flt.eta_des"><template v-if="$luxon.fromSQL(flt.eta_des).toFormat('y-LL-dd')!=$luxon.fromSQL(flt.DEP_SCHED_DT).toFormat('y-LL-dd')">{{ flt.eta_des | luxon({ clientFormat: "ccc ddLLL ", serverFormat: "sql" })}}</template>{{ flt.eta_des | luxon({ clientFormat: "HHmm", serverFormat: "sql" })}}</span>
                  <span class="heading has-text-danger is-size-7 is-capitalized has-text-weight-bold" v-if="flt.eta_des && ($luxon.fromSQL(flt.eta_des) > $luxon.fromSQL(flt.DEP_AP_SCHED))">Late {{ ($luxonInterval.fromDateTimes($luxon.fromSQL(flt.DEP_AP_SCHED),$luxon.fromSQL(flt.eta_des)).toDuration(['hours',"minutes"])).toFormat('h:mm') }}</span>
                  </div>
                </div>
              </nav>

              <nav class="level is-mobile mb-2" v-if="flt.departed">
                <div class="level-item has-text-centered">
                  <div v-for="(block,kblock) in flt.blocks" :key="kblock"  class="has-background-light">
                    <b-tooltip type="is-dark" position="is-bottom">
                      <template v-slot:content><div class="is-size-7 has-text-right is-family-monospace" v-html="_.reduce(_.entries(_.mapValues({ACARS:'A',MVT:'M',FORCED:'F'},(source)=>flt[{OFF:'OFFBLOCK',AIR:'AIRBORNE',LDG:'LANDING',ON:'ONBLOCK'}[kblock]+'_DT_'+source])),(r,pair)=>(r+'<b>'+pair[0]+'</b>: '+(pair[1] || '....-..-.. ..:..:..')+'<BR>'),'')"></div></template>
                      <span class="is-size-7 has-text-weight-semibold px-4" :class="{'has-text-grey-light':!block,'has-text-black':block}">{{kblock}}</span>
                      <span class="heading has-text-weight-bold"  :class="{'has-text-grey-light':!block,'has-text-black':block}"><template v-if="block">{{block  | luxon({ clientFormat: "HHmm", serverFormat: "sql" })}}</template><template v-else>----</template></span>
                    </b-tooltip>
                  </div>
                </div>
              </nav>

              

              <nav class="level is-mobile mb-2">
                <div class="level-item has-text-centered">
                  <div class="has-background-light">
                    <span class="heading mb-0">Regist.</span>
                    <span class="is-size-5 has-text-weight-semibold">{{flt.AC_REGISTRATION}}</span>
                    <span class="heading has-text-grey mb-0is-size-6">{{flt.AC_SUBTYPE}}</span>
                  </div>
                </div>
                <div class="level-item has-text-right">
                  <div>
                    <span class="heading mb-0">Ver:</span>
                    <span class="heading mb-0">Booking:</span>
                  </div>
                  <div>
                    <span class="heading pl-1 mb-0 has-text-right has-text-grey-light">{{flt.AC_VERSION}}</span>
                    <span class="heading pl-1 mb-0 has-text-right has-text-weight-bold has-text-info"><template v-if="flt.PAX_BOOKED_C"><b>C</b>{{flt.PAX_BOOKED_C}} </template><template v-if="flt.PAX_BOOKED_Y"><b>Y</b>{{flt.PAX_BOOKED_Y}}</template></span>
                  </div>
                </div>
                <div class="level-item has-text-right">
                  <div class="has-background-light">
                    <span class="heading mb-0">A/C:</span>
                    <span class="heading mb-0">PNT:</span>
                    <span class="heading mb-0">PNC:</span>
                  </div>
                  <div class="has-background-light">
                    <span class="heading pl-1 mb-0 has-text-left has-text-weight-bold">{{flt.AC_OWNER}}</span>
                    <span class="heading pl-1 mb-0 has-text-left has-text-weight-bold">{{flt.EMPLOYER_COCKPIT}}</span>
                    <span class="heading pl-1 mb-0 has-text-left has-text-weight-bold">{{flt.EMPLOYER_CABIN}}</span>
                  </div>
                </div>
              </nav>


       <div class="box mt-2 pt-1 px-0">
        <span class="mb-3 has-text-weight-semibold is-size-7 px-3">Aircraft LogBook</span><span class="has-text-tight pl-5"  @click="scrollaclog(flt)"><b-icon size="is-small" icon="airplane"></b-icon></span>

<div class="table-container aclogvuescrollto" style="height:250px;overflow-y:scroll">        
  
<table class="mx-0 px-0 is-size-7 is-family-monospace is-narrow" style="line-height:1">
  <tbody>

  <tr 
    :key="kligne" v-for="(ligne,kligne) in  (_.groupBy($store.ops.data,'AC_REGISTRATION')[flt.AC_REGISTRATION]).filter((x)=>(
       ($luxon.fromFormat(flt.hdep,'y-LL-dd hh:mm:ss').minus({days:3})<=$luxon.fromFormat(x.hdep,'y-LL-dd hh:mm:ss')) && ($luxon.fromFormat(flt.hdep,'y-LL-dd hh:mm:ss').plus({days:3})>=$luxon.fromFormat(x.hdep,'y-LL-dd hh:mm:ss')) 
       ))" 
    :id="'aclog-'+legid(ligne)"
    :class="{'has-background-danger-light animated infinite pulse': ligne.al+ligne.fnum+ligne.dep+ligne.des + $luxon.fromSQL(ligne.hdep).toFormat('y-LL-dd')==flt.al+flt.fnum+flt.dep+flt.des + $luxon.fromSQL(flt.hdep).toFormat('y-LL-dd')}"
     @click="showFltInfo(ligne)">
            <td>
              <div class="m-0 p-0 has-text-weight-light" style="font-size: 0.6rem;">{{ ligne.hdep | luxon({ clientFormat: "ccc dd LLLyy", serverFormat: "sql" })}}</div>
              <div class="m-0 p-0 is-family-monospace">
                <span class="has-text-weight-bold" :class="{'has-text-danger':ligne.eta_dep && ($luxon.fromSQL(ligne.eta_dep).plus({minutes:escale_mini(ligne)}) > $luxon.fromSQL(ligne.hdep))}">{{ ligne.hdep | luxon({ clientFormat: "HHmm", serverFormat: "sql" })}}</span>-<span :class="{'has-text-danger':ligne.eta_des && ($luxon.fromSQL(ligne.eta_des) > $luxon.fromSQL(ligne.hdes))}">{{ ligne.hdes | luxon({ clientFormat: "HHmm", serverFormat: "sql" })}}</span>
              </div>
            </td>
            <td class="verticalcenter">
                <div class="m-0 p-0 has-text-weight-light" style="font-size: 0.6rem;">{{ ligne.al}}{{ligne.fnum }}</div>
                <div>
                  <span :class="{'has-text-danger':ligne.DEP_AP_ACTUAL!=ligne.DEP_AP_SCHED}">{{ ligne.dep }}</span>-<span :class="{'has-text-danger':ligne.ARR_AP_SCHED!=ligne.ARR_AP_ACTUAL}">{{ ligne.des }}</span>
                </div>
            </td>
            <td class="verticalcenter has-text-right">
                <span class="has-text-white px-1" :class="{'has-background-success':ligne.state=='SCHED','has-background-success-dark':ligne.state=='DELAYED','has-background-info':ligne.state=='ARRIVED','has-background-danger':!_.includes(['SCHED','DELAYED','ARRIVED'],ligne.state)}">{{ ligne.state }}</span>
            </td>
  </tr>
  </tbody>
</table>
</div>


       </div>



            </div>
          </div>
        </div>
      </b-collapse>
    </template>
    <section class="hero is-medium is-primary is-bold" v-else>
      <div class="hero-body">
        <div class="container">
          <h1 class="title">Netline OPS</h1>
          <h2 class="subtitle">No Flights data found...</h2>
          <b-button type="is-warning" @click="$apis.load_ops_data({},opsLoaded)"
            >Reload</b-button
          >
        </div>
      </div>
    </section>
  </section>
</template>


<script>
export default {
  created() {
    let me = this;
  },
  mounted(){
    let me=this
    me.$apis.load_ops_data({},me.opsLoaded)
  },
  data() {
    return {
      isOpen: 0,
    };
  },
  props: {
    flt: {},
  },
  methods: {
    opsLoaded() {
      let me = this;
        setTimeout(() => {
         me.scrollaclog(me.flt);
        }, 700);

    },
    legid(xflt) {
      let me=this
      return xflt.al+xflt.fnum+xflt.dep+xflt.des + me.$luxon.fromSQL(xflt.hdep).toFormat("y-LL-dd");
    },
    scrollaclog(leg) {
      let me=this
      //console.log("#aclog-"+me.legid(leg))
      
        me.$scrollTo("#aclog-"+me.legid(leg), 200, { 
            offset: -50,           
            container:
              window.document.getElementsByClassName("aclogvuescrollto").length > 0
                ? ".aclogvuescrollto"
                : "body",
          });
    },
  },
  filters: {},
  computed: {
    fltops() {
      let me = this;
      let out= me.acleg(me.flt,me.$store.ops.data)
      if (_.size(out)>1) {
        me.isOpen=-1
      } else {
        me.isOpen=0
      }
      return out;
    },
  },
};
</script>
<style scoped>
p {
  line-height: 1;
}
</style>