require('./bootstrap');

//window.axios = require('axios');
//window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

import axios from 'axios'
Vue.prototype.$axios = axios;


import Vue from 'vue'


Vue.config.productionTip = false


Vue.prototype.window = window;
Vue.prototype.document = document;

import _ from 'lodash'
Vue.prototype._ = _;



import Buefy from 'buefy'
import 'buefy/dist/buefy.css'
Vue.use(Buefy)


import {DateTime,Interval,Duration} from "luxon";
Vue.prototype.$luxon = DateTime;
Vue.prototype.$luxonInterval = Interval;
Vue.prototype.$luxonDuration = Duration;
import VueLuxon from "vue-luxon";
Vue.use(VueLuxon);


var VueScrollTo = require('vue-scrollto');
//import VueScrollTo from 'vue-scrollto';
Vue.use(VueScrollTo, {
    container: "body",
    duration: 750,
    easing: "ease",
    offset: -180,
    force: true,
    cancelable: true,
    onStart: false,
    onDone: false,
    onCancel: false,
    x: true,
    y: true
})


import store from "./store.js"
Vue.prototype.$store = store;

import helper from "./helper.js"
Vue.prototype.$helper = helper;

import misc from "./misc.js"
Vue.prototype.$misc = misc;

import apis from './apis.js'
Vue.prototype.$apis = apis;


import App from './components/App.vue';



const requireComponent = require.context('./components',true,
  // The regular expression used to match base component filenames
  /xx_\w+\.(vue|js)$/
)

requireComponent.keys().forEach(fileName => {
  const componentConfig = requireComponent(fileName)
     const componentName = 
        fileName
            .split('/')
            .pop()
            .replace(/\.\w+$/, '')

          Vue.component(
            componentName,
            componentConfig.default || componentConfig
    )
})

import VueRouter from 'vue-router'
Vue.use(VueRouter)
import routes from "./routes.js"
const router = new VueRouter({
    mode: 'history',
    routes
})



//import modal_fltinfo from "./components/part/fltinfo/modal_fltinfo";
import VueSticky from 'vue-sticky' // Es6 module
Vue.mixin({
    directives: { sticky: VueSticky },
    computed:  {
      /*
      __store(){
        return this.$store
      },
      __helper(){
        return this.$helper
      },
      */
      
    tar_extract(){
      return true
    },
    tar_paie(){
      return true
    },
    tar_docpn(){
      return true
    },
    tar_mail(){
      return false
    },
    tar_lido(){
      return true
    },
    tar_crewlink(){
      return true
    },
},
    methods:{
      actype(ac) {
        return {'32A':'320','32B':'320','332':'330','31A':'319','31B':'319'}[ac] || ac
      },

      acleg(flt) {
        let me=this
        return _.filter(
          me.$store.ops.data,
          (leg) =>
            flt.al == leg.al &&
            flt.fnum == leg.fnum &&
            (flt.dep == leg.DEP_AP_ACTUAL || flt.dep == leg.DEP_AP_SCHED) &&
            (flt.des == leg.ARR_AP_ACTUAL || flt.des == leg.ARR_AP_SCHED) &&
            (me.$luxon.fromSQL(flt.hdep).toFormat('Y-LL-dd') == me.$luxon.fromSQL(leg.hdep).toFormat('Y-LL-dd') ||
              me.$luxon.fromSQL(flt.hdep).toFormat('Y-LL-dd') == me.$luxon.fromSQL(leg.DEP_SCHED_DT).toFormat('Y-LL-dd')))
      },


    showFltInfo(flt){
      this.$helper.modals.fltinfo.show=true;
      this.$helper.modals.fltinfo.flt=flt;
      },
    showDutyInfo(duty){
        this.$helper.modals.dutyinfo.show=true;
        this.$helper.modals.dutyinfo.duty=duty;
      },
      isReserve(label) {
        return ['R0','R1','R2','R3','R4','R5','R6','R7','R8','R9','RD','RS','RT'].includes(label)
      },
      qualifarray(tlc) {
        return this.$store.qualif.data[tlc || (($store.qualif.data).keys()[0] || {qualif:[{ac:'320',college:'CP'}]})].qualif.reduce((r,q)=>(r.concat(q.college+q.ac)),[])
    },
    
      escale_mini(leg) {
      return 45;
      }
    },
  })
  


new Vue({
    router,
    render: h => h(App)
}).$mount('#app')
