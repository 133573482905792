<template>
  

    <section v-if="_.size(pv) > 0">

        <div class="buttons pt-2">
        <b-button size="is-small" icon-left="file-pdf" expanded @click="window.open(
                    'api/ofp/file?al=' +
                    flt.al +
                    '&fnum=' +
                    flt.fnum +
                    '&date=' +
                    flt.date +
                    '&dep=' +
                    flt.dep +
                    '&des=' +
                    flt.des +
                    '&token=' +
                    encodeURI($store.token)
                   , '_blank')">Download OFP</b-button>
                
      </div>
      <span class="heading has-text-right pb-3"
        >edition {{ pv.header.ofpedt }} calculated @ {{ pv.header.calculdate }}
        {{ pv.header.calcultime }}</span
      >

      <nav class="level is-mobile">
        <div class="level-item has-text-centered">
          <div>
            <p class="heading">{{ pv.header.cs }}{{ pv.header.fnum }}</p>
            <p class="heading">{{ pv.header.callsign }}</p>
          </div>
        </div>
        <div class="level-item has-text-centered">
          <div>
            <p class="heading">date</p>
            <p class="heading has-text-weight-semibold">{{ pv.header.date }}</p>
          </div>
        </div>
        <div class="level-item has-text-centered">
          <div>
            <p class="heading">
              {{ pv.header.depiata }}-{{ pv.header.arriata }}
            </p>
            <p class="heading has-text-info">
              {{ pv.header.depoaci }}-{{ pv.header.arroaci }}
            </p>
          </div>
        </div>
        <div class="level-item has-text-centered">
          <div>
            <p
              class="heading"
              :class="{ 'is-text-grey': pv.header.eobt == '.....' }"
            >
              <span class="has-text-weight-bold">{{ pv.header.sobt }}</span
              >-{{ pv.header.stot }}-{{ pv.header.sldg }}-{{ pv.header.sbon }}
            </p>
            <p class="heading has-text-info" v-if="pv.header.eobt != '.....'">
              {{ pv.header.eobt }}-{{ pv.header.etot }}-{{ pv.header.eldg }}-{{
                pv.header.ebon
              }}
            </p>
          </div>
        </div>
      </nav>

      <nav class="level is-mobile">
        <div class="level-item has-text-centered">
          <div>
            <p class="title">{{ pv.header.immat }}</p>
          </div>
        </div>
        <div class="level-item has-text-centered">
          <div>
            <p class="heading">{{ pv.header.ac }}</p>
            <p class="heading has-text-weight-semibold">
              {{ pv.header.acsubtype }}
            </p>
          </div>
        </div>
      </nav>

      <nav class="level is-mobile">
        <div class="level-item has-text-centered">
          <div>
            <p class="heading">Dist/{{ pv.header.dist }} Nm</p>
            <p class="heading has-text-weight-semibold">
              EET/{{ pv.fuel.eet }}
            </p>
          </div>
        </div>
        <div class="level-item has-text-centered">
          <div>
            <p class="heading has-text-weight-semibold">CI{{ pv.header.ci }}</p>
            <p class="heading">{{ pv.header.avgwind }}</p>
          </div>
        </div>
      </nav>

      <div class="box">
        <div class="columns is-mobile py-0 my-0">
          <span class="column has-text-weight-semibold is-size-7 mt-0 pt-1">ATC F/PLN:</span>
          <span class="column is-narrow mt-0 pt-1"><b-button
              id="jeppcopy"
              type="is-success"
              size="is-small"
              icon-left="clipboard-text-multiple-outline"
              @click="copy(pv.jeppview)">COPY route</b-button>
            </span>
        </div>
        <p class="is-size-7 is-family-monospace">{{ pv.atcfpl }}</p>
      </div>

      <div class="box mt-2 pt-1">
        <span class="mb-3 has-text-weight-semibold is-size-7">Weights</span>
        <nav class="level is-mobile">
          <div class="level-item has-text-centered">
            <div>
              <span class="heading mb-0">ZFW</span>
              <span class="is-size-5 has-text-weight-semibold">{{pv.header.zfw | kgtotons}}</span>
              <span class="heading has-text-grey mb-0"
                >MAX: {{ pv.header.maxzfw | kgtotons }}</span>
            </div>
          </div>
          <div class="level-item has-text-centered">
            <div>
              <span class="heading mb-0">TOW</span>
              <span class="is-size-5 has-text-weight-semibold">{{pv.header.tow | kgtotons}}</span>
              <span class="heading has-text-grey mb-0">
                MAX: {{ pv.header.maxto | kgtotons }}</span>
            </div>
          </div>
          <div class="level-item has-text-centered">
            <div>
              <span class="heading mb-0">LDW</span>
              <span class="is-size-5 has-text-weight-semibold">{{pv.header.ldg | kgtotons}}</span>
              <span class="heading has-text-grey mb-0"
                >MAX: {{ pv.header.maxldg | kgtotons }}</span>
            </div>
          </div>
        </nav>
        <nav class="level is-mobile">
          <div class="level-item has-text-centered">
            <div>
              <span class="heading mb-0">Payload</span>
              <span class="is-size-5 has-text-weight-semibold">{{pv.header.payload | kgtotons}}</span>
              <span class="heading has-text-grey mb-0">
                Underload: {{ pv.header.underload.slice(0, -1) | kgtotons }}</span>
            </div>
          </div>
          <div class="level-item has-text-centered">
            <div>
              <span class="heading mb-0">Fuel</span>
              <span class="is-size-5 has-text-weight-semibold">{{pv.fuel.plnbof | kgtotons}}</span>
              <span class="heading has-text-grey mb-0"
                >MAX Extra: {{ pv.fuel.extramax | kgtotons }}</span>
            </div>
          </div>

        </nav>
      </div>
            
            
            
       <div class="box mt-2 pt-1">
        <span class="mb-3 has-text-weight-semibold is-size-7">Fuel</span>
        <nav class="level is-mobile">
          <div class="level-item has-text-centered">
            <div>
              <span class="heading mb-0">Trip</span>
              <span class="is-size-6 has-text-weight-semibold">{{pv.fuel.trip | kgtotons}}</span>
              <span class="heading has-text-grey mb-0">{{ pv.fuel.eet }}</span>
            </div>
          </div>
          <div class="level-item has-text-centered">
            <div>
              <span class="heading mb-0">Cont.</span>
              <span class="is-size-6 has-text-weight-semibold">{{pv.fuel.cont | kgtotons}}</span>
              <span class="heading has-text-grey mb-0">{{ pv.fuel.conttype }}</span>
            </div>
          </div>
          <div class="level-item has-text-centered">
            <div>
              <span class="heading mb-0">Altn.</span>
              <span class="is-size-6 has-text-weight-semibold">{{pv.fuel.altn | kgtotons}}</span>
              <span class="heading has-text-grey mb-0">{{ pv.fuel.altnicao }}</span>
            </div>
          </div>
          <div class="level-item has-text-centered" v-if="pv.fuel.add>0 || pv.fuel.erops">
            <div>
              <span class="heading mb-0" v-if="pv.fuel.add>0">Addit.: <b>{{pv.fuel.add | kgtotons}}</b></span>
              <span class="heading mb-0" v-if="pv.fuel.erops">EROPS: <b>{{pv.fuel.erops | kgtotons}}</b></span>
            </div>
          </div>
          <div class="level-item has-text-centered">
            <div>
              <span class="heading mb-0">Final: <b>{{pv.fuel.final | kgtotons}}</b></span>
              <span class="heading mb-0">Taxi: <b>{{pv.fuel.taxi | kgtotons}}</b></span>
            </div>
          </div>

        </nav>
                  <div class="mt-2 has-text-grey is-size-7 is-capitalized" v-html="pv.fuel.tankering"></div>
      </div>


            <div class="box mt-2 pt-1 px-0">
        <span class="mb-3 has-text-weight-semibold is-size-7">Weather</span>
<b-collapse class="card" animation="slide" v-for="(msgs, wxtype) of pv.wx" :key="wxtype" :open="false">
            <div
                slot="trigger"
                slot-scope="props"
                class="card-header"
                role="button" v-if="_.size(msgs)>0">
                <p class="card-header-title">{{ wxtype }}</p>
                <a class="card-header-icon"><b-icon :icon="props.open ? 'menu-down' : 'menu-up'"></b-icon></a>
            </div>
            <div class="card-content px-1">
                <div class="content">
                    <b-field :label="data.ID" label-position="on-border" v-for="(data,kdata) in msgs" :key="kdata">
                        <textarea v-html="data.TXT" :rows="_.size(_.split(data.TXT,`&#10;`))" wrap="off" readonly style="font-size: .7rem;width:100%;line-height:1;padding:10px;font-family: monospace;"></textarea>
                    </b-field>
                </div>
            </div>
        </b-collapse>
            </div>



            <div class="box mt-2 pt-1 px-0">
        <span class="mb-3 has-text-weight-semibold is-size-7">Notams</span>
<b-collapse class="card" animation="slide" v-for="(msgs, wxtype) of pv.notams" :key="wxtype" :open="false">
            <div
                slot="trigger"
                slot-scope="props"
                class="card-header"
                role="button" v-if="_.size(msgs)>0">
                <p class="card-header-title">{{ wxtype }}</p>
                <a class="card-header-icon"><b-icon :icon="props.open ? 'menu-down' : 'menu-up'"></b-icon></a>
            </div>
            <div class="card-content px-1">
                <div class="content">
                    <b-field :label="data.ID" label-position="on-border" v-for="(data,kdata) in msgs" :key="kdata">
                        <textarea v-html="data.TXT" :rows="_.size(_.split(data.TXT,`&#10;`))" wrap="off" readonly style="font-size: .7rem;width:100%;line-height:1;padding:10px;font-family: monospace;"></textarea>
                    </b-field>
                </div>
            </div>
        </b-collapse>
            </div>



    </section>
 
</template>

<script>
import * as clipboard from "clipboard-polyfill/text";
export default {
  created() {
    let me = this;

  },
  mounted(){
    let me=this
    me.fltid={
      date:
        me.flt.date || me.$luxon.fromSQL(me.flt.hdep).toFormat("y-LL-dd"),
      al: me.flt.al,
      fnum: me.flt.fnum,
      dep: me.flt.dep,
      des: me.flt.des,
    }
    let pv=me.$store.ofps.find((ofp)=>(_.isEqual(ofp.id,me.fltid)))
    if (pv) {
      me.pv=pv.ofp
    }
    me.$apis.load_ofp_data(me.fltid,me.ofpLoaded)
  },
  data() {
    return {
      ofp: {
        params: {},
        update: false,
        resp: null,
      },
      pv: null,
      fltid:{date:null,al:null,fnum:null,dep:null,des:null},
    };
  },
  props: {
    flt: {},
  },

  methods: {
    copy(text) {
      let me = this;
      clipboard.writeText(text).then(
        function () {
          me.$buefy.toast.open({
            duration: 5000,
            message: `Clean route was copied to clipboard. Now, you can paste it in JeppFD.`,
            position: "is-bottom",
            type: "is-success",
          });
        },
        function () {
          me.$buefy.toast.open({
            duration: 5000,
            message: `An error has occured when copying route to clipboard !!!!`,
            position: "is-bottom",
            type: "is-danger",
          });
        }
      );
    },
    ofpLoaded(resp) {
      let me = this;
      me.pv = resp;
      
      me.$store.ofps=_.reject(me.$store.ofps,(flt)=>(_.isEqual(flt.id,me.fltid)))
      me.$store.ofps.push({id:me.fltid,ofp:me.pv});
      while (me.$store.ofps.length>30) {
        me.$store.ofps.shift()
      }
      me.$misc.save('store.ofps',me.$store.ofps)
      //console.log(me.$store.ofps)
    },
  },
  filters: {
    kgtotons: function (value) {
      if (!value) return "";
      value = _.divide(_.round(value, -2), 1000).toFixed(1);
      return value;
    },
  },
};
</script>
<style scoped>
p {
  line-height: 1;
}
</style>