<template>
  <div class="pl-1 pr-1 pt-1 pb-1">
    <div v-if="day"  class="has-text-right pb-3">
      <span class="is-size-7 has-text-weight-medium has-text-grey-light is-pulled-left">{{$luxon.fromSQL(day.date).toFormat('LLL-yy')}}</span>
      
      <span class="is-size-7 has-text-weight-medium has-text-primary"  @click="$router.push({ name: 'crewlinkFlts', query: { datebegin: day.date,dateend: day.date} })">{{$luxon.fromSQL(day.date).toFormat('ccc')}}</span>
      <span class="is-size-5 has-text-weight-medium has-text-primary-dark">{{$luxon.fromSQL(day.date).toFormat('dd')}}</span>
      </div>
    <template v-if="lastday">
      <div class="" v-for="(lduty, klduty) in lastday.all" :key="'last' + klduty"
      >
        <xx_dutyCal
          :duty="lduty"
          :last="true"
          v-if="
            lduty &&
            lduty.data &&
            lduty.data.hdep &&
            $luxon.fromSQL(lduty.data.hdep).day != $luxon.fromSQL(lduty.data.hdes).day
          "
        ></xx_dutyCal>
      </div>
    </template>
    <template v-if="day">
      <div class="" v-for="(duty, kduty) in day.all" :key="kduty">
        <xx_dutyCal :duty="duty"></xx_dutyCal>
      </div>
    </template>
  </div>
</template>

<script>

export default {
  props: {
    day: { default: null },
    lastday: { default: null },
  },

};
</script>

<style>
</style>