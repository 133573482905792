<template>
  <div>

    <template v-if="crewlink.roster.length>0">
      <div v-if="calcListView" class="container" style="max-width: 500px">
        <template v-for="(day, kday) in crewlink.roster">
          <div
            class="px-2 pb-4"
            v-if="$luxon.fromSQL(day.date).toFormat('d') == 1 || kday == 0"
            v-sticky="{ zIndex: 1, stickyTop: 60, disabled: false }"
            :key="kday"
            :id="'roster-' + $luxon.fromSQL(day.date).toFormat('y-LL')"
          >
            <div
              class="box columns is-mobile month"
              style="background-color: #00a3cc; padding: 0; margin: 0"
            >
              <div class="column">
                <span class="title has-text-white">
                  {{ day.date | luxon:format("LLLL") }}
                </span>
                <span class="subtitle has-text-light">
                  {{ day.date | luxon:format("y") }}
                </span>
                <div>
                  <span class="is-size-7 has-text-light">credit:</span
                  ><span
                    class="is-family-monospace has-text-success has-text-weight-bold"
                    >[{{ hours_credit(day.date) }}]</span
                  >
                  <span class="ml-4 is-size-7 has-text-light">flt:</span
                  ><span
                    class="is-family-monospace has-text-warning has-text-weight-bold"
                    >{{ hours_flight(day.date) }}</span
                  >
                </div>
              </div>
              <div class="column is-narrow has-text-white">
                <diV @click="pickMonth = true">
                  <b-icon icon="calendar-month"></b-icon>
                  </diV>
                <div
                  @click="$apis.load_crew_month({
                      datedeb: $luxon
                        .fromSQL(day.date)
                        .startOf('month')
                        .toSQLDate(),
                      datefin: $luxon
                        .fromSQL(day.date)
                        .endOf('month')
                        .toSQLDate(),
                    },rosterLoaded)"
                  style="position: absolute; bottom: 1.5em"
                >
                  <b-icon icon="reload"></b-icon>
                </div>
              </div>
              <div class="column is-narrow has-text-white">
                <diV
                  @click="goMonth($luxon.fromSQL(day.date).minus({ month: 1 }))"
                  :class="{
                    'is-invisible':
                      $luxon
                        .fromSQL(day.date)
                        .startOf('month')
                        .minus(1, 'day')
                        .diff(
                          $luxon.fromSQL(crewlink.minmax.mindate),
                          'seconds'
                        )
                        .toObject().seconds <= 0,
                  }"
                  ><b-icon icon="arrow-up-bold-box"></b-icon
                ></diV>
                <div
                  @click="goMonth($luxon.fromSQL(day.date).plus({ month: 1 }))"
                  :class="{
                    'is-invisible':
                      $luxon
                        .fromSQL(day.date)
                        .endOf('month')
                        .plus(1, { days: 1 })
                        .diff(
                          $luxon.fromSQL(crewlink.minmax.maxdate),
                          'seconds'
                        )
                        .toObject().seconds >= 0,
                  }"
                  style="position: absolute; bottom: 1.5em"
                >
                  <b-icon icon="arrow-down-bold-box"></b-icon>
                </div>
              </div>
            </div>
          </div>
          <div
            class="columns is-mobile pt-2 pb-2 px-2"
            :class="{
              'has-background-danger-light':
                now.toFormat('y-LL-dd') == day.date,
            }"
            :key="day.date"
            style="margin: 0; padding: 0"
          >
            <div class="column is-narrow pl-0 pr-1 pt-0" @click="$router.push({ name: 'crewlinkFlts', query: { datebegin: day.date,dateend: day.date} })">
              <xx_calendarIcon
                :color="now.toFormat('y-LL-dd') == day.date ? 'today' : 'day'"
                :class="{
                  'animated infinite swing':
                    now.toFormat('y-LL-dd') == day.date,
                }"
                :date="day.date"
                :id="'roster-' + day.date"
               
              />
              <div class="has-text-centered has-text-success" v-if="day.credit">
                [{{ day.credit }}]
              </div>
            </div>

            <xx_DayDuty
              :day="day"
              :lastday="$store.crewlink.roster[kday - 1]"
            ></xx_DayDuty>
          </div>
          <div
            class="mx-6 my-3"
            v-if="
              $luxon.fromSQL(day.date).toSeconds() <
                $luxon.fromSQL($store.crewlink.minmax.maxdate).toSeconds() &&
              kday + 1 < _.size($store.crewlink.roster) &&
              $luxon.fromSQL($store.crewlink.roster[kday + 1].date).month !=
                $luxon.fromSQL(day.date).month &&
              $luxon.fromSQL(day.date).day <
                $luxon.fromSQL(day.date).endOf('month').day
            "
            :key="'incomplete-' + day.date"
          >
            <b-message type="is-warning" has-icon>
              This month is partially loaded...

              <b-button
                type="is-warning"
                expanded
                @click="$apis.load_crew_month({
                    datedeb: $luxon
                      .fromSQL(day.date)
                      .startOf('month')
                      .toSQLDate(),
                    datefin: $luxon
                      .fromSQL(day.date)
                      .endOf('month')
                      .toSQLDate(),
                  },rosterLoaded)"
                >Reload this month</b-button
              >
            </b-message>
          </div>

          <div
            class="mx-2 my-6"
            v-if="
              $luxon.fromSQL(day.date).toSeconds() <
                $luxon.fromSQL($store.crewlink.minmax.maxdate).toSeconds() &&
              kday + 1 < _.size($store.crewlink.roster) &&
              $luxon.fromSQL($store.crewlink.roster[kday + 1].date).month !=
                $luxon.fromSQL(day.date).month &&
              $luxon
                .fromSQL($store.crewlink.roster[kday + 1].date)
                .toFormat('y-LL') !=
                $luxon.fromSQL(day.date).plus({ month: 1 }).toFormat('y-LL')
            "
            :key="'loadmonth-' + day.date"
          >
            <b-message type="is-info" has-icon>
              Month discontinuity...
            </b-message>
          </div>
        </template>
      </div>

      <template v-else>
        <div
          class="columns is-mobile is-centered is-vcentered"
          style="margin: 0; padding: 10px"
        >
          <div
            class="columns box month is-mobile is-centered is-vcentered"
            style="margin: 0; padding: 0; margin-bottom: 10px"
          >
            <diV
              class="column is-narrow has-text-white"
              @click="goMonth(actMonth.minus({ month: 1 }).startOf('month'))"
              :class="{
                'is-invisible':
                  actMonth
                    .startOf('month')
                    .minus(1, 'day')
                    .diff($luxon.fromSQL(crewlink.minmax.mindate), 'seconds')
                    .toObject().seconds <= 0,
              }"
            >
              <div class="column is-narrow">
                <b-icon icon="arrow-left-thick" size="is-small"></b-icon>
              </div>
            </diV>
            <div class="mx-4 has-text-centered">
              <span class="is-size-3-tablet is-size-5 has-text-white">
                {{ actMonth.toFormat("LLLL") }}
              </span>
              <span class="subtitle has-text-light">
                {{ actMonth.toFormat("y") }}
              </span>
              <div>
                <span class="is-size-7 has-text-light">credit:</span
                ><span
                  class="is-family-monospace has-text-success has-text-weight-bold"
                  >[{{ hours_credit(actMonth.toFormat("y-LL-dd")) }}]</span
                >
                <span class="ml-4 is-size-7 has-text-light">flt:</span
                ><span
                  class="is-family-monospace has-text-warning has-text-weight-bold"
                  >{{ hours_flight(actMonth.toFormat("y-LL-dd")) }}</span
                >
              </div>
            </div>

            <diV
              @click="pickMonth = true"
              class="column is-narrow has-text-white"
            >
              <b-icon icon="calendar-month" size="is-small"></b-icon>
            </diV>
            <div
              class="column is-narrow has-text-white"
              @click="$apis.load_crew_month({
                  datedeb: actMonth.startOf('month').toSQLDate(),
                  datefin: actMonth.endOf('month').toSQLDate(),
                },rosterLoaded)"
            >
              <b-icon icon="reload" size="is-small"></b-icon>
            </div>

            <div
              class="column is-narrow has-text-white"
              @click="goMonth(actMonth.plus({ month: 1 }))"
              :class="{
                'is-invisible':
                  actMonth
                    .endOf('month')
                    .plus(1, { days: 1 })
                    .diff($luxon.fromSQL(crewlink.minmax.maxdate), 'seconds')
                    .toObject().seconds >= 0,
              }"
            >
              <b-icon icon="arrow-right-thick" size="is-small"></b-icon>
            </div>
          </div>
        </div>
        <div class="vuescrollto table-container">
          <table class="mytable">
            <tr
              v-for="(week, num) in crewlink_monthTable[
                actMonth.toFormat('y-LL')
              ]"
              :key="num"
            >
              <template v-for="(dw, kdw) in weekdays">
                <td
                  v-if="!(week[dw.field].day && week[dw.field].day.date)"
                  class="is-invisible"
                  :key="kdw"
                ></td>

                <td
                  v-else
                  class="bordered"
                  :class="{
                    'has-background-white-ter flou':
                      actMonth.toFormat('y-LL') !=
                      $luxon.fromSQL(week[dw.field].day.date).toFormat('y-LL'),
                    'has-background-danger-light':
                      now.toFormat('y-LL-dd') ==
                      $luxon
                        .fromSQL(week[dw.field].day.date)
                        .toFormat('y-LL-dd'),
                    tdcenter:
                      (week[dw.field].day && week[dw.field].day.all
                        ? _.head(week[dw.field].day.all).type
                        : false) == 'wholeday',
                  }"
                  :key="kdw"
                >
                  <xx_DayDutyCal
                    :id="
                      week[dw.field].day
                        ? 'roster-' +
                          $luxon
                            .fromSQL(week[dw.field].day.date)
                            .toFormat('y-LL-dd')
                        : kdw + num
                    "
                    :day="week[dw.field].day"
                    :lastday="week[dw.field].last"
                  ></xx_DayDutyCal>
                </td>
              </template>
            </tr>
          </table>
        </div>
      </template>
    </template>

    <section class="hero is-medium is-primary is-bold" v-else>
      <div class="hero-body">
        <div class="container">
          <h1 class="title">Crewlink</h1>
          <h2 class="subtitle">No duty found...</h2>
          <b-button type="is-warning" @click="$apis.load_crew_month({},rosterLoaded)">Reload</b-button>
        </div>
      </div>
    </section>

    


    <b-modal v-model="pickMonth">
      <form action="" class="container has-text-centered">
        <b-field label="Select a date">
          <b-datepicker
            type="month"
            inline
            :min-date="new Date(crewlink.minmax.mindate)"
            :max-date="new Date(crewlink.minmax.maxdate)"
            trap-focus
            @input="
              (e) => {
                pickMonth = false;
                goMonth($luxon.fromJSDate(e).startOf('month'));
              }
            "
          >
          </b-datepicker>
        </b-field>
      </form>
    </b-modal>
    <div class="buttons p-2 stickbottom">
      <b-button
        @click="forcedView=true;listView = !listView"
        class="m-2 stickbottom"
        size="is-small"
        icon-left="format-rotate-90"
        >{{ listView ? "Month view" : "List view" }}</b-button>
    </div>
  </div>
</template>

<script>

export default {
  created(){
    let me=this;
      //if (!_.isNull(me.$store.crewlink.actMonth) && !_.find(me.crewlink.roster,['date',me.$store.crewlink.actMonth.toFormat('Y-m-d')])){ me.$store.crewlink.actMonth=null; }
      me.$store.crewlink.actMonth=null;
      if (_.isNull(me.$store.crewlink.actMonth) && _.size(me.crewlink.roster)>0){ me.$store.crewlink.actMonth=me.$luxon.fromSQL(_.last(me.crewlink.roster).date) || null; }

    me.listView=me.calcListView
  },
  mounted() {
    let me = this;
    me.$apis.load_crew_month({},me.rosterLoaded)

  },
  data() {
    return {
      
      pickMonth: false,
      listView: false,
      forcedView: false,
      weekdays: [
        { field: "Mon", label: "Mon" },
        { field: "Tue", label: "Tue" },
        { field: "Wed", label: "Wed" },
        { field: "Thu", label: "Thu" },
        { field: "Fri", label: "Fri" },
        { field: "Sat", label: "Sat" },
        { field: "Sun", label: "Sun" },
      ],
    };
  },
  methods: {
    goMonth(date) {
      let me = this;
      let goMonthDate = date.startOf("month");

      if (
        _.find(me.crewlink.roster, ["date", goMonthDate.toFormat("y-LL-dd")]) ||
        false
      ) {
        me.$store.crewlink.actMonth = date;
        setTimeout(() => {
          this.$scrollTo("#roster-" + date.toFormat("y-LL-dd"), 700, {
            container:
              window.document.getElementsByClassName("vuescrollto").length > 0
                ? ".vuescrollto"
                : "body",
          });
        }, 700);

        //partially loaded
        if (
          me.$luxon.fromSQL(
            _.findLast(
              me.crewlink.roster,
              (day) => me.$luxon.fromSQL(day.date).month == me.actMonth.month
            ).date
          ).day < me.actMonth.endOf("month").day
        ) {
          me.$buefy.dialog.confirm({
            message:
              "This month is partially loaded. Do you want to reload thewhole month?",
            onConfirm() {
              me.$apis.load_crew_month( {
                datedeb: me.actMonth.startOf("month").toSQLDate(),
                datefin: me.actMonth.endOf("month").toSQLDate(),
              },me.rosterLoaded);
            },
          });
        }
      } else {
        if (me.$helper.online) {
        me.$apis.load_crew_month({
          datedeb: goMonthDate.toFormat("y-LL-dd"),
          datefin: goMonthDate.endOf("month").toFormat("y-LL-dd"),
        },me.rosterLoaded); } else {
          this.$buefy.snackbar.open(`You're OffLine. Please try later, when you'll be OnLine.`)
        }
      }
    },
    rosterLoaded(resp) {
      let me = this;

        //update timestamp
        let now = this.$luxon.utc().toFormat("y-LL-dd HH:mm:ss");
        _.forEach(resp.roster, function (day) {
          //console.log(day.date);
          me.$store.crewlink.update[day.date] = now;
        });

        //remove new one
        me.$store.crewlink.roster = _.differenceBy(
          me.$store.crewlink.roster,
          resp.roster,
          "date"
        );

        //add new one
        me.$store.crewlink.roster = _.orderBy(
          _.union(me.$store.crewlink.roster, resp.roster),
          "date"
        );

        me.$store.crewlink.mle = resp.mle;
        me.$store.crewlink.name = resp.name;

        me.$store.crewlink.minmax = resp.minmax;
        me.$store.crewlink.actMonth = me.$luxon.fromSQL(
          ((resp.roster[0]||null) || { date: me.now.toSQLDate() }) .date
        );

        let scrollto = me.actMonth;
        if (
          scrollto.year == me.$luxon.utc().year &&
          scrollto.month == me.$luxon.utc().month
        ) {
          scrollto = this.$luxon.utc();
        }

        me.goMonth(scrollto);
      
    },
    hours_credit(date) {
      let me = this;
      return _.find(
        me.crewlink_hours,
        (credm) =>
          me.$luxon
            .fromObject({ year: credm.year, month: credm.month })
            .toFormat("y-LL") == me.$luxon.fromSQL(date).toFormat("y-LL")
      ).credit;
    },
    hours_flight(date) {
      let me = this;
      return _.find(
        me.crewlink_hours,
        (credm) =>
          me.$luxon
            .fromObject({ year: credm.year, month: credm.month })
            .toFormat("y-LL") == me.$luxon.fromSQL(date).toFormat("y-LL")
      ).flight;
    },
  },
  computed: {

    calcListView() { let me=this;if (me.forcedView)  {return me.listView} else {me.listView=(me.$helper.viewport.width < 667); return me.listView  } },
    actMonth() {
      return this.$store.crewlink.actMonth;
    },
    now() {
      return this.$helper.now;
    },
    crewlink() {
      return this.$store.crewlink;
    },
    crewlink_months() {
      let me = this;
      return _.uniqWith(
        _.map(me.crewlink.roster, (day) => ({
          year: me.$luxon.fromSQL(day.date).year,
          month: me.$luxon.fromSQL(day.date).toFormat("LL"),
        })),
        _.isEqual
      );
    },
    crewlink_hours() {
      let me = this;
      return _.map(me.crewlink_months, (yearmonth) => ({
        year: yearmonth.year,
        month: yearmonth.month,
        credit: _.reduce(
          _.filter(
            me.crewlink.roster,
            (day) =>
              me.$luxon.fromSQL(day.date).toFormat("y-LL") ==
              me.$luxon
                .utc(yearmonth.year, parseInt(yearmonth.month))
                .toFormat("y-LL")
          ),
          function (total, day) {
            let credit = day.credit ?? "0:00";
            let [hours, minutes] = credit.split(":");
            return total.plus({ hours: hours, minutes: minutes });
          },
          me.$luxonDuration.fromObject({ hours: 0, minutes: 0 })
        ).toFormat("hh:mm"),
        flight: _.reduce(
          _.filter(
            me.crewlink.roster,
            (day) =>
              me.$luxon.fromSQL(day.date).toFormat("y-LL") ==
              me.$luxon
                .utc(yearmonth.year, parseInt(yearmonth.month))
                .toFormat("y-LL")
          ),
          function (total, day) {
            let fltday = _.reduce(
              _.filter(day.all ?? [], (item) => item.type == "flt"),
              function (totday, itday) {
                let fltleg = me.$luxonInterval
                  .fromDateTimes(
                    me.$luxon.fromSQL(itday.data.hdep),
                    me.$luxon.fromSQL(itday.data.hdes)
                  )
                  .toDuration(["hours", "minutes"])
                  .toObject();
                return totday.plus(fltleg);
              },
              me.$luxonDuration.fromObject({ hours: 0, minutes: 0 })
            );
            return total.plus(fltday);
          },
          me.$luxonDuration.fromObject({ hours: 0, minutes: 0 })
        ).toFormat("hh:mm"),
      }));
    },
    crewlink_monthTable() {
      let me = this;
      let out = {};
      _.each(me.crewlink_months, function (yearmonth) {
        let month = [];
        let week = {};
        for (
          let day = me.$luxon
            .fromObject({ year: yearmonth.year, month: yearmonth.month })
            .startOf("week");
          day.startOf("day") <=
          me.$luxon
            .fromObject({ year: yearmonth.year, month: yearmonth.month })
            .endOf("month")
            .endOf("week")
            .startOf("day");
          day = day.plus({ day: 1 })
        ) {
          //console.log(day.toFormat("y-LL-dd"))
          week[day.toFormat("ccc")] = {
            day: _.find(
              me.crewlink.roster,
              (cday) => cday.date == day.toFormat("y-LL-dd")
            ),
            last: _.find(
              me.crewlink.roster,
              (cday) => cday.date == day.minus({ day: 1 }).toFormat("y-LL-dd")
            ),
          };
          if (_.size(week) == 7) {
            month.push(week);
            week = {};
          }
        }
        out[yearmonth.year + "-" + yearmonth.month] = month;
      });
      return out;
    },
  },
};
</script>
