<template>
  
    <b-navbar fixed-top shadow type="is-dark">
      <template slot="brand">
        <b-navbar-item tag="router-link" :to="{ path: '/' }">
          <img
            src="tp4.png"
            alt="Lightweight UI components for Vue.js based on Bulma"
          /><span class="is-size-4 pl-2">TAR Pilot v4</span>
        </b-navbar-item>
      </template>
      <template slot="start">
        <b-navbar-item tag="router-link" :to="{ name: 'crewlinkRoster' }" v-if="tar_crewlink && $misc.user_can('roster_crewlink')">my Roster (Crewlink)</b-navbar-item>
        <b-navbar-item tag="router-link" :to="{ name: 'rosters' }" v-if="tar_extract && $misc.user_can('rosters')"> Rosters </b-navbar-item>
        <b-navbar-item tag="router-link" :to="{ name: 'crewlinkFlts' }" v-if="tar_crewlink && $misc.user_can('crewlist_crewlink')">CrewList (Crewlink)</b-navbar-item>
        <b-navbar-item tag="router-link" :to="{ name: 'opsFlts' }"  v-if="tar_extract && $misc.user_can('info_ops')"> Flights List </b-navbar-item>
        <b-navbar-item tag="router-link" :to="{ name: 'lidoOfp' }"  v-if="tar_lido && $misc.user_can('lido_flights')"> Lido Brief OFPs </b-navbar-item>
        <b-navbar-item tag="router-link" :to="{ name: 'paie' }"  v-if="tar_paie && $misc.user_can('paie')"> Fiche de Paie </b-navbar-item>
        <b-navbar-item tag="router-link" :to="{ name: 'activite' }"  v-if="tar_paie && $misc.user_can('paie')"> Fiche Activité </b-navbar-item>
        <b-navbar-item tag="router-link" :to="{ name: 'docpn' }"  v-if="tar_docpn && $misc.user_can('docs')"> Documents </b-navbar-item>
        
        <b-navbar-item class="has-text-danger" href="/update">Manual update APP</b-navbar-item>
      </template>

      <template slot="end">
        <b-navbar-item tag="div">
          <div class="buttons">
            <a class="button is-light" @click="$apis.logout"> Log out </a>
          </div>
        </b-navbar-item>
      </template>
    </b-navbar>
  
</template>
<script>
export default {
  data() {
    return {};
  },
  mounted() {
    let me=this

  },
};
</script>
