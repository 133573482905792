<template>
<span>
    <span :class="'is-size-'+size">{{heure | luxon({ clientFormat: "HH", serverFormat: "sql" })}}</span><span :class="'is-size-'+(size+1)">{{heure | luxon({ clientFormat: "mm", serverFormat: "sql" })}}</span>
</span>
</template>
<script>
export default {
    props:{
        heure:{default:"1970-01-01 00:00:00"},
        size:{default:7}
    }
}
</script>