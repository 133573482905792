<template>


    <section>
<div>
      <nav class="level is-mobile container" style="max-width: 380px;height: 60px" v-sticky="{ zIndex: 1, stickyTop: 60, disabled: false }">
        <div class="level-item has-text-centered" v-for="(day, kday) in [-2, -1, 0, 1, 2]" :key="kday">
          <div @click="date=($luxon.fromSQL(date).plus({ day: day }).toFormat('y-LL-dd'));initfilter()">
            <xx_calendarIcon
              :color="
                $helper.now.toFormat('y-LL-dd') ==
                $luxon.fromSQL(date).plus({ day: day }).toFormat('y-LL-dd')
                  ? 'has-background-info-dark'
                  : (
                    $luxon.fromSQL(date).plus({ day: day }).toFormat('y-LL-dd')>=$luxon.fromSQL($store.crew.dates.min).toFormat('y-LL-dd') && 
                    $luxon.fromSQL(date).plus({ day: day }).toFormat('y-LL-dd')<=$luxon.fromSQL($store.crew.dates.max).toFormat('y-LL-dd')
                    )
                    
                  ? 'has-background-info'
                  : 'has-background-grey-light'
              "
              :class="{
                flou: day != 0,
                'animated infinite pulse':
                  $helper.now.toFormat('y-LL-dd') ==
                  $luxon.fromSQL(date).plus({ day: day }).toFormat('y-LL-dd'),
              }"
              :date="$luxon.fromSQL(date).plus({ day: day }).toFormat('y-LL-dd')"
              :full="day == 0" />
          </div>
        </div>
        <div class="has-text-info pr-1">
          <diV @click="pickDay = true">
            <b-icon icon="calendar-month"></b-icon>
          </diV>
          <div @click="$apis.load_crew_data()">
            <b-icon icon="reload"></b-icon>
          </div>
        </div>
      </nav>
</div>

      <div v-if="openflts.length>0">

    <b-table :height="$helper.viewport.height-320" class="is-size-7" 
    :data="openflts.filter((x)=>(filter.ac.length==0 ||  filter.ac.includes(actype(x.actype))))
    .filter((x)=>(filter.crew.length==0 ||  _.intersection(filter.crew, ((x.needed.req || []).map((y)=>(y.col)))
                        .concat((x.needed.opt || []).map((y)=>(y.col)))   ).length>0    ))" is-stripped is-narrowed :mobile-cards="false" sticky-header>
            <b-table-column label="Timetable" sortable v-slot="props">
                    <div class="nowrap">{{ $luxon.fromSQL(props.row.hdep).toFormat('HHmm') }}-{{ $luxon.fromSQL(props.row.hdes).toFormat('HHmm') }}</div>
            </b-table-column>
            <b-table-column label="City Pair" sortable v-slot="props">
                <div  @click="showFltInfo({al:props.row.al,fnum:props.row.fnum,dep:props.row.dep,des:props.row.des,hdep:props.row.hdep})" >
                    <div class="nowrap has-text-weight-bold has-text-centered">{{ props.row.dep }}-{{ props.row.des }}</div>
                    <div class="nowrap is-family-monospace has-text-centered">{{ props.row.al }}{{ props.row.fnum }}</div>
                </div>
            </b-table-column>
            <b-table-column label="AC Type" sortable v-slot="props">
                    {{ actype(props.row.actype) }}
            </b-table-column>
            <b-table-column label="Needed" sortable v-slot="props">
                <div v-if="props.row.needed" class="has-text-white normalwrap">

                <template v-for="(crew,kcrew) in props.row.needed.req || []">
                    <span class="has-background-grey-dark px-1 mx-1 nowrap">{{crew.nb}}{{crew.col}}</span>
                </template>

                
                <template v-if="(props.row.needed.opt || []).length>0">
                    <span class="has-background-grey-dark px-1 mx-1 nowrap">
                        <template  v-for="(crew,kcrew) in (props.row.needed.opt || [])">{{crew.nb}}{{crew.col}}<template v-if="kcrew<(props.row.needed.opt || []).length-1"> or </template></template>
                    </span>
                </template>

                </div>
            </b-table-column>
    </b-table>
    
<section v-if="fltsac.length>0">
<div>Aircraft:</div>  
<b-field>
<template v-for="(ac,kac) in fltsac">
<b-checkbox-button v-model="filter.ac" :native-value="ac" size="is-small" type="is-success" style="max-width:5em">{{ac}}</b-checkbox-button>
</template>
</b-field>
</section>

<section v-if="fltscrew.length>0">    
<div>Crew station:</div> 
<b-field>
<template v-for="(crew,kcrew) in fltscrew">
<b-checkbox-button v-model="filter.crew" :native-value="crew" size="is-small" type="is-dark">{{crew}}</b-checkbox-button>
</template>
</b-field>
</section>

</div>

<div class="container" v-else>
    <div class="has-text-centered is-size-3">No Open flights</div>
</div>

    <b-modal v-model="pickDay">
      <form action="" class="container has-text-centered">
        <b-field label="Select a date">
          <b-datepicker inline trap-focus @input="(e) => {
                pickDay = false;
                date=($luxon.fromJSDate(e).toFormat('y-LL-dd'));
              }">
          </b-datepicker>
        </b-field>
      </form>
    </b-modal>




    </section>



</template>
<script>
export default {
    created(){
        let me=this
        me.date=me.$luxon.fromSQL(me.duty.hdep).toFormat('y-LL-dd')
    },
    mounted(){
      let me=this
      me.$apis.load_qualif_data({},me.initfilter)
      me.$apis.load_crew_data({})

    },
    data(){
        return {
            date:null,
            filter:{
                ac:[],
                crew:[],
            },
            pickDay: null,
            req: [
                {
                    ac:'320',
                    crew:[
                        {CP:1,FO:1,PU:1,ST:4},
                        {CP:1,FO:1,SE:1,ST:4},
                    ]
                },
                {
                    ac:'736',
                    crew:[
                        {CP:1,FO:1,PU:1,ST:3},
                        {CP:1,FO:1,SE:1,ST:3},
                    ]
                },
                {
                    ac:'330',
                    crew:[
                        {CP:1,FO:1,PU:1,SE:1,ST:8},
                    ]
                },
            ]
        }
    },
    computed:{
        fltsac(){
            let me=this
            return Object.keys(_.groupBy(me.openflts,(x)=>(me.actype(x.actype)))).sort()            
        },
        fltscrew(){
            let me=this
            return me.openflts.reduce(function(r,x){
                    return _.uniq(
                        r.concat((x.needed.req || []).map((y)=>(y.col)))
                        .concat((x.needed.opt || []).map((y)=>(y.col)))
                    )
                },[]).sort()
        },
        openflts(){
            let me=this
            let out= me.$store.crew.dataraw.filter((leg)=>(leg.type=="L" && me.$luxon.fromSQL(leg.hdep).toFormat('y-LL-dd')==me.date))
                .reduce(function(r,leg){
                    const query={dep:leg.dep,des:leg.des,al:leg.al,fnum:leg.fnum,hdep:leg.hdep,hdes:leg.hdes,actype:leg.actype}
                    
                    let flt=_.find(r,query) || query
                    flt.tlcs=(flt.tlcs || [])
                    flt.tlcs.push(leg.tlc)
                    r=_.reject(r,query)
                    r.push(flt)
                    return r
                },[])
                
                .map(function(leg){
                    let crew=(leg.tlcs).reduce(function(r,tlc){
                        let qualif=(_.find((me.$store.qualif.data[tlc]).qualif,{ac:me.actype(leg.actype)}) || {college: 'JU'})
                        let college=qualif.college
                        r[college]=(r[college] || 0)+1
                        return r
                    },{})
                    leg["crew"]=crew

                    let reqcrew=_.find(me.req,{ac:me.actype(leg.actype)})

                    if (reqcrew) {
                        //check
                        let open=!reqcrew.crew.some(function(crew){
                            let cbon=true
                            _.each(crew,function(nb,col){cbon=cbon && nb<=(leg.crew[col]||0) })
                            return cbon
                        })
                        leg['open']=open
                        
                        //calcul missing
                        if (leg['open']) {
                            let missings=[]
                            let req=reqcrew.crew
                            
                            req.forEach(function(crew){
                                let missing={}
                                _.each(crew,function(nb,col){let nbmis=nb-(leg.crew[col]||0);if (nbmis>0) {missing[col]=nbmis;} })
                                missings.push(missing)
                            })

                            let min=_.min(missings.map((c)=>(_.reduce(c,function(r,x){r=r+x;return r},0))))
                            if (min) {missings=_.reject(missings,(c)=>(_.reduce(c,function(r,x){r=r+x;return r},0)>min))}
                            leg['missing']=missings


                            let res={req:[],opt:[]}
                            _.each(missings,function(combi){
                                _.each(combi,function(nb,col){

                                    if (_.every(missings,[col,nb])) {
                                        if (!_.find(res.req,{col:col,nb:nb})) {res.req.push({col:col,nb:nb})}
                                    } else {
                                        if (!_.find(res.opt,{col:col,nb:nb})) {res.opt.push({col:col,nb:nb})}
                                    }
                                })
                            })
                            leg['needed']=res

                        } else {
                            leg['missing']=[]
                        }
                    }
                    

                    return leg
                })
                .filter((leg)=>(leg.open))

                out=_.groupBy(out,(x)=>(x.tlcs.join()))
                out=Object.values(out)
                    .sort(function(a,b) { 
                        let c=me.$luxon.fromSQL(_.orderBy(a,(x=>(x.hdep)))[0].hdep)
                        let d=me.$luxon.fromSQL(_.orderBy(b,(x=>(x.hdep)))[0].hdep)
                        return c<d?-1:(c==d?0:1)

                    })
                    .map((leg)=>(_.orderBy(leg,(x)=>(x.hdep))))
                    .flat()
                                    
                return out
        },
    },
    methods:{
        initfilter(){
            let me=this
        me.filter.ac=_.intersection(me.$store.qualif.data[me.duty.tlc].qualif.map((x)=>(x.ac)),me.fltsac)
        me.filter.crew=_.intersection(_.uniq(me.$store.qualif.data[me.duty.tlc].qualif.map((x)=>(x.college))),me.fltscrew)

        }
    },
    props:{duty:{default:null}}
}
</script>