<template>
  <div>

    <div class="buttons px-4" v-if="tar_crewlink && $misc.user_can('roster_crewlink')">
      <b-button
        size="is-large"
        tag="router-link"
        icon-left="calendar-month"
        to="crew"
        type="is-light"
        expanded
      >
        my Roster (Crewlink)
      </b-button>
    </div>

    <div class="buttons px-4"  v-if="tar_extract && $misc.user_can('rosters')">
      <b-button
        size="is-large"
        tag="router-link"
        icon-left="calendar-month"
        to="rosters"
        type="is-light"
        expanded
      >
        Rosters
      </b-button>
    </div>

    <div class="buttons px-4" v-if="tar_crewlink && $misc.user_can('crewlist_crewlink')">
      <b-button
        size="is-large"
        tag="router-link"
        icon-left="calendar-multiple"
        :to="{ name: 'crewlinkFlts' }"
        type="is-light"
        expanded
      >
        CrewList (Crewlink)
      </b-button>
    </div>



    <div class="buttons px-4"  v-if="tar_extract && $misc.user_can('info_ops')">
      <b-button
        size="is-large"
        tag="router-link"
        icon-left="calendar-multiple"
        :to="{ name: 'opsFlts' }"
        type="is-light"
        expanded
      >
        Flights List
      </b-button>
    </div>

    <div class="buttons px-4" v-if="tar_lido && $misc.user_can('lido_flights')">
      <b-button
        size="is-large"
        tag="router-link"
        icon-left="fuel"
        to="ofp"
        type="is-light"
        expanded
      >
        Lido OFP
      </b-button>
    </div>

    <div class="buttons px-4" v-if="tar_paie && $misc.user_can('paie')">
      <b-button
        size="is-large"
        tag="router-link"
        icon-left="clipboard"
        to="paie"
        type="is-light"
        expanded
      >
        Fiche de Paie
      </b-button>
    </div>

    <div class="buttons px-4" v-if="tar_paie && $misc.user_can('paie')">
      <b-button
        size="is-large"
        tag="router-link"
        icon-left="clipboard"
        to="activite"
        type="is-light"
        expanded
      >
        Fiche Activité
      </b-button>
    </div>

    <div class="buttons px-4" v-if="tar_docpn && $misc.user_can('docs')">
      <b-button
        size="is-large"
        tag="router-link"
        icon-left="clipboard"
        to="docpn"
        type="is-light"
        expanded
      >
        Documents
      </b-button>
    </div>


  </div>
</template>
<script>


export default {
  mounted() {
  },
  data() {
    return {  };
  },
  methods: {

  },
  computed: {

  },
};
</script>
