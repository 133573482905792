import helper from './helper'
import store from './store'
import apis from './apis'
import _ from 'lodash'



import localForage from "localforage";

const misc= {
    init_eventListener(){
      apis.getip(function(ip){if (ip) {helper.ip=ip } })
      window.addEventListener("offline", function (event) {
            console.log("You lost connection.");
            helper.online = false;
            apis.snack({message:'You lost connection.'})
          });
          window.addEventListener("online", function (event) {
            console.log("You are now back online.");
            helper.online = true;
            apis.getip(function(ip){if (ip) {helper.ip=ip } })
            apis.snack({message:'You are now back online.'})
          });
          window.onresize = () => {
            helper.viewport.height = window.innerHeight;
            helper.viewport.width = window.innerWidth;
          };        
    },

    save(key,data) {
          localForage.setItem(key,data)
    },
    clear(key=null,call) {

          if (key) {
            if (typeof call == "function") {localForage.removeItem(key,call)} else {localForage.removeItem(key)}
            
          } else {
            if (typeof call == "function") {localForage.clear(call)} else {localForage.clear()}
          } 
    },

    load(key,data=null) {
          return localForage.getItem(key,data)
    },
    loading_finish(id){
                let i= helper.loading.findIndex((x)=>(x.url==id))
                if (i>=0) {
                    helper.loading.splice(i,1)
                } else {
                    console.log("Loading: loading item not found in array...")
                }
    },

    loadStore() {

      helper.loading.push({full:true,cancel:false,msg:'loading OFPS from storage...',url:'storage_ofps',type:'init'})  
      helper.loading.push({full:true,cancel:false,msg:'loading QUALIFICATIONS from storage...',url:'storage_qualif',type:'init'})  
      helper.loading.push({full:true,cancel:false,msg:'loading OPS DATA from storage...',url:'storage_ops',type:'init'})  
      helper.loading.push({full:true,cancel:false,msg:'loading CrewLink Flights from storage...',url:'storage_flts',type:'init'})  
      helper.loading.push({full:true,cancel:false,msg:'loading CrewLink Roster from storage...',url:'storage_crewlink',type:'init'})  
      helper.loading.push({full:true,cancel:false,msg:'loading ROSTERS from storage...',url:'storage_crew',type:'init'})  
      helper.loading.push({full:true,cancel:false,msg:'loading Docs from storage...',url:'storage_docs',type:'init'})  
      helper.loading.push({full:true,cancel:false,msg:'loading PAIE from storage...',url:'storage_paie',type:'init'})  
      helper.loading.push({full:true,cancel:false,msg:'loading ACTIVITE from storage...',url:'storage_activite',type:'init'})  
      helper.loading.push({full:true,cancel:false,msg:'loading abilities...',url:'storage_abilities',type:'init'})  
      helper.loading.push({full:true,cancel:false,msg:'loading Defaults...',url:'storage_defaults',type:'init'})  
      helper.loading.push({full:true,cancel:false,msg:'loading TOKEN from storage...',url:'storage_token',type:'init'})  

      localForage.getItem('store.token').then(function (value) {store.token=value || store.token;misc.loading_finish('storage_token')})
      localForage.getItem('store.abilities').then(function (value) {store.abilities=value || store.abilities;misc.loading_finish('storage_abilities')})
      localForage.getItem('store.default_filters').then(function (value) {store.default_filters=value || store.default_filters;misc.loading_finish('storage_defaults')})
      localForage.getItem('store.qualif').then(function (value) {store.qualif=value || store.qualif;misc.loading_finish('storage_qualif')})
      localForage.getItem('store.ops').then(function (value) {store.ops=value || store.ops;misc.loading_finish('storage_ops')})
      localForage.getItem('store.flts').then(function (value) {store.flts=value || store.flts;misc.loading_finish('storage_flts')})
      localForage.getItem('store.crewlink').then(function (value) {store.crewlink=value || store.crewlink;misc.loading_finish('storage_crewlink')})
      localForage.getItem('store.crew').then(function (value) {store.crew=value || store.crew;misc.loading_finish('storage_crew')})
      localForage.getItem('store.paie').then(function (value) {store.paie=value || store.paie;misc.loading_finish('storage_paie')})
      localForage.getItem('store.act').then(function (value) {store.act=value || store.act;misc.loading_finish('storage_activite')})
      localForage.getItem('store.docs').then(function (value) {store.docs=value || store.docs;misc.loading_finish('storage_docs')})
      localForage.getItem('store.ofps').then(function (value) {store.ofps=value || store.ofps;misc.loading_finish('storage_ofps')})
    },

    user_can(ab){
      
      if (typeof ab =="array") {
        return ab.every((x)=>misc.user_can(x))
      } else {
        //console.log('ab: ',store.abilities.includes(ab))
        return _.size(store.abilities)>0 && store.abilities.includes(ab)
      } 
    }

    
}
export default misc